import * as APP from 'config/app';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';

const Share = ({ name, media, subject, text, url }) => (
    <section className="share-block">
        <div className="links">
            <a className="link || ssk ssk-twitter" tabIndex="-1" rel="noreferrer" href={`https://twitter.com/intent/tweet/?text=${subject}&url=${APP.APP_HOSTNAME}${url}&via=${APP.TWITTER_USER.replace('@', '')}`} target="_blank">
                <Icon name="twitter" />
            </a>

            <a className="link || ssk ssk-facebook" tabIndex="-1" rel="noreferrer" href={`https://facebook.com/dialog/share?app_id=${APP.FACEBOOK_APP_ID}&display=popup&href=${APP.APP_HOSTNAME}${url}`} target="_blank">
                <Icon name="facebook-alt" />
            </a>

            <a className="link || ssk ssk-pinterest" tabIndex="-1" rel="noreferrer" href={`https://pinterest.com/pin/create/button/?url=${APP.APP_HOSTNAME}${url}&description=${subject}&media=${media}`} target="_blank">
                <Icon name="pinterest" />
            </a>

            <a className="link || ssk ssk-tumbler" tabIndex="-1" rel="noreferrer" href={`https://www.tumblr.com/widgets/share/tool?posttype=link&canonicalUrl=${APP.APP_HOSTNAME}${url}&title=${subject}&caption=&content=&shareSource=${APP.TUMBLR_USER}`} target="_blank">
                <Icon name="tumbler" />
            </a>

            <a className="link" tabIndex="-1" href={`mailto:?subject=${subject}&body=${text}%0A%0A${name}%0A%0A${APP.APP_HOSTNAME}${url}`}>
                <Icon name="email" />
            </a>
        </div>

        <span className="toggle" role="button">
            <Icon name="share" />
            <span>Share</span>
        </span>
    </section>
);

Share.propTypes = {
    name: PropTypes.string.isRequired,
    media: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
};

export default Share;
