import classNames from 'classnames';
import { clearWindowsScrollPosition } from '../../utilities/scrolling-helper';
import FullScreenDialog from './full-screen-dialog';
import Icon from './icon';
import moment from 'moment';
import PropTypes from 'prop-types';
import Select from './select';
import DayPicker, { DateUtils } from 'react-day-picker';
import { GENRE_GROUPS, PRICE_RANGE } from 'config/event';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import 'react-day-picker/lib/style.css';

const ShowFinder = ({ isShowFinderDialogActive, toggleShowFinderDialog }) => {
    const [searchParams] = useSearchParams();
    const [genre, setGenre] = useState('');
    const [price, setPrice] = useState('');
    const [activeDatePicker, setActiveDatePicker] = useState(null);
    const [formattedStartDate, setFormattedStartDate] = useState('');
    const [formattedEndDate, setFormattedEndDate] = useState('');
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');

    const navigate = useNavigate();

    const getQueryValue = (key) => {
        return !searchParams.get(key) || searchParams.get(key) === '-' ? '' : decodeURIComponent(searchParams.get(key));
    };

    const validDate = (date) => {
        return date && moment(date).isValid() ? new Date(date) : '';
    };

    const generateDateValue = (startDate, endDate, dateType = 'both') => {
        if (dateType === 'both') {
            const formattedStartDate = startDate && moment(startDate).format('DD/MM/YYYY');
            const formattedEndDate = endDate && moment(endDate).format('DD/MM/YYYY');
            setFrom((startDate));
            setTo(endDate);
            setFormattedStartDate(formattedStartDate || '');
            setFormattedEndDate(formattedEndDate || '');
        }
        if (dateType === 'start') {
            const formattedStartDate = startDate && moment(startDate).format('DD/MM/YYYY');
            setFrom(startDate);
            setFormattedStartDate(formattedStartDate || '');
        }
        if (dateType === 'end') {
            const formattedEndDate = endDate && moment(endDate).format('DD/MM/YYYY');
            setTo(endDate);
            setFormattedEndDate(formattedEndDate || '');
        }
    };

    const combinedDateClickHanlder = (day, modifiers) => {
        if (modifiers.disabled) return;
        const range = DateUtils.addDayToRange(day, { from, to });
        generateDateValue(range.from, range.to);
    };

    const dateClickHanlder = (day, dateType) => {
        if (dateType === 'start') {
            generateDateValue(day, null, 'start');
        } else {
            generateDateValue(null, day, 'end');
        }
        setActiveDatePicker(null);
    };

    const dateInputClickHandler = (dateType) => {
        if (activeDatePicker === dateType) {
            setActiveDatePicker(null);

            return;
        }
        setActiveDatePicker(dateType);
    };

    const displayDate = () => `${formattedStartDate}${formattedEndDate ? `-${formattedEndDate}` : ''}`;

    const dateRangeClickHanlder = (rangeType, hideOnSelect) => {
        const today = new Date();
        // eslint-disable-next-line no-magic-numbers
        const sevenDaysLater = moment(today).add(7, 'd').toDate();
        const endOfWeek = moment(today).endOf('isoweek').toDate();
        const endOfMonth = moment(today).endOf('month').toDate();

        switch (rangeType) {
            case 'next-seven-days':
                generateDateValue(today, sevenDaysLater);
                break;
            case 'this-week':
                generateDateValue(today, endOfWeek);
                break;
            case 'this-month':
                generateDateValue(today, endOfMonth);
                break;
            case 'any-dates':
                clearDatesHandler();
                break;
            default:
                break;
        }

        if (hideOnSelect) {
            setActiveDatePicker(null);
        }
    };

    const clearDatesHandler = () => generateDateValue(undefined, undefined);

    const formatDate = () => {
        let formattedEndDate = '',
            formattedStartDate = '-';

        if (from) {
            formattedStartDate = moment(from).format('YYYY-MM-DD');
        }
        if (to) {
            formattedEndDate = `,${moment(to).format('YYYY-MM-DD')}`;
        }

        return formattedStartDate + formattedEndDate;
    };

    const submitHanlder = (event) => {
        event.preventDefault();
        const date = formatDate();
        setActiveDatePicker(null);
        if (isShowFinderDialogActive) {
            toggleShowFinderDialog();
        }

        // Clear session Storage for remembering scrolling state on event result page
        clearWindowsScrollPosition();

        navigate({
            pathname: '/search/events',
            search: `?genre=${genre || '-'}&date=${encodeURIComponent(date)}&price=${price || '-'}`
        });
    };

    const onSelectChange = (event, name) => {
        if (name === 'genre') {
            setGenre(event.target.value);
        } else {
            setPrice(event.target.value);
        }
    };

    const dateRangeBlurHanlder = (event) => {
        const currentTarget = event.currentTarget;

        // Check the newly focused element in the next tick of the event loop
        setTimeout(() => {
            // Check if the new activeElement is a child of the original container
            if (!currentTarget.contains(document.activeElement)) {
                // You can invoke a callback or add custom logic here
                dateInputClickHandler(null);
            }
        }, 0);
    };

    useEffect(() => {
        const updatedGenre = getQueryValue('genre');
        const updatedDate = getQueryValue('date');
        const updatedPrice = getQueryValue('price');
        if (updatedDate) {
            const [startDate, endDate] = updatedDate.split(',');
            generateDateValue(validDate(startDate), validDate(endDate), 'both');
        }

        if (updatedGenre !== genre) setGenre(updatedGenre);
        if (updatedPrice !== price) setPrice(updatedPrice);
    }, [searchParams]);

    // Render
    const renderDateRangeButtonsGroup = (hideOnSelect = false) => (
        <div className="date-ranges">
            <button className="button" type="button" onClick={() => dateRangeClickHanlder('next-seven-days', hideOnSelect)}>next 7 days</button>
            <button className="button" type="button" onClick={() => dateRangeClickHanlder('this-week', hideOnSelect)}>this weekend</button>
            <button className="button" type="button" onClick={() => dateRangeClickHanlder('this-month', hideOnSelect)}>this month</button>
            <button className="button" type="button" onClick={() => dateRangeClickHanlder('any-dates', hideOnSelect)}>any dates</button>
        </div>
    );

    const renderDateInput = (inputKey, inputValue) => {
        return (
            <div className="date-input-wrapper">
                <div className="input-wrapper with-icon">
                    <input
                        className="input || date-picker-input"
                        name="date"
                        placeholder="any date"
                        autoComplete="off"
                        readOnly
                        onClick={() => dateInputClickHandler(inputKey)}
                        value={inputValue}
                    />
                    <span className="label-icon">
                        <Icon name="calender-add" />
                    </span>
                </div>
            </div>
        );
    };

    const renderDateInputGroup = () => (
        <React.Fragment>
            <div className="field-group inline">
                <span className="label">from</span>
                {renderDateInput('start', formattedStartDate)}
            </div>
            <div className="field-group inline">
                <span className="label">to</span>
                {renderDateInput('end', formattedEndDate)}
            </div>
        </React.Fragment>
    );

    const renderDateRangePicker = () => {
        const modifiers = { start: from, end: to };

        return (
            <div className="date-picker-wrapper">
                {renderDateRangeButtonsGroup()}
                <DayPicker
                    numberOfMonths={2}
                    selectedDays={[from, { from, to }]}
                    disabledDays={[{ before: new Date() }]}
                    modifiers={modifiers}
                    onDayClick={combinedDateClickHanlder}
                    keepFocus={false}
                />
                <button className="button primary inverse || clear-dates" type="button" onClick={clearDatesHandler}>clear dates</button>
            </div>
        );
    };

    const renderDateRangeInput = () => (
        <div className="field-group inline">
            <span className="label">on</span>
            <div className="date-input-wrapper" tabIndex="0" onBlur={dateRangeBlurHanlder}>
                <div className="input-wrapper with-icon">
                    <input
                        className="input || date-picker-input"
                        name="date"
                        placeholder="any date"
                        autoComplete="off"
                        readOnly
                        onClick={() => dateInputClickHandler('range')}
                        value={displayDate()}
                    />
                    <span className="label-icon">
                        <Icon name="chevron" />
                    </span>
                </div>
                {activeDatePicker && renderDateRangePicker()}
            </div>
        </div>
    );

    const renderSearchForm = (isShowFinderDialog = false) => {
        const showFinderClasses = classNames('show-finder', 'form', {
            'constrain-width large': !isShowFinderDialog,
            'show-finder-dialog ': isShowFinderDialog
        });

        return (
            <form className={showFinderClasses} action="/search/events" method="GET" onSubmit={submitHanlder}>
                <fieldset>
                    <div className="label title">I&apos;m looking for</div>
                    <Select id="event-genre" name="genre" options={GENRE_GROUPS} value={genre} onChangeHanlder={(event) => onSelectChange(event, 'genre')}/>
                    {!isShowFinderDialog && renderDateRangeInput()}
                    {isShowFinderDialog && renderDateInputGroup()}
                    <div className="field-group inline">
                        <span className="label">for</span>
                        <Select id="event-price" name="price" options={PRICE_RANGE} value={price} onChangeHanlder={(event) => onSelectChange(event, 'price')} />
                    </div>
                    {isShowFinderDialog && <button className="button secondary || clear-dates" type="button" onClick={clearDatesHandler}>Clear dates</button>}
                    <button className={`button ${isShowFinderDialog ? 'primary inverse' : 'secondary'}`} type="submit">Find</button>
                </fieldset>
            </form>
        );
    };

    const renderDatePicker = (name) => (
        <div className="date-picker-wrapper">
            {renderDateRangeButtonsGroup(true)}
            <DayPicker
                selectedDay={from}
                disabledDays={[{ before: new Date() }]}
                onDayClick={(day) => dateClickHanlder(day, name)}
            />
            <button className="button primary" type="button" onClick={() => dateInputClickHandler(name)}>Close</button>
        </div>
    );

    const renderShowFinderDialog = () => (
        <FullScreenDialog extraClass="theme-grey">
            {!activeDatePicker && (
                <button className="button secondary no-border || action-button" onClick={toggleShowFinderDialog}>
                    <Icon className="icon" name="close" />
                </button>
            )}
            {!activeDatePicker && renderSearchForm(true)}
            {activeDatePicker === 'start' && renderDatePicker('start')}
            {activeDatePicker === 'end' && renderDatePicker('end')}
        </FullScreenDialog>
    );

    const renderFindAnEventButton = () => (
        <button className="button secondary || find-event-banner" onClick={toggleShowFinderDialog}>
            Find an event
            <span className="label-icon">
                <Icon name="left-arrow" />
            </span>
        </button>
    );

    return (
        <div className="show-finder-wrapper">
            {renderFindAnEventButton()}
            {renderSearchForm()}
            {isShowFinderDialogActive && renderShowFinderDialog()}
        </div>
    );
};

ShowFinder.propTypes = {
    isShowFinderDialogActive: PropTypes.bool.isRequired,
    toggleShowFinderDialog: PropTypes.func.isRequired
};

export default ShowFinder;
