import PropTypes from 'prop-types';
import React from 'react';

const PricingTable = ({ data }) => (
    <section className="pricing-table">
        {data.map((item, index) => (
            <div className="pricing-item" key={index}>
                <div className="pricing-item-heading">
                    <h5 className="title">{item.title}</h5>
                    <p className="description">{item.description}</p>
                </div>
                <p className="price">{item.price}</p>
            </div>
        ))}
    </section>
);

PricingTable.propTypes = {
    data: PropTypes.array.isRequired
};

export default PricingTable;
