import moment from 'moment-timezone';
import { DATE_FORMAT_FULL, NZ_TIMEZONE, NZ_UTC_OFFSET } from 'config/app';

const SATURDAY = 6;
const SUNDAY = 7;

class DateHelper {
    /**
     * Get the current date, using offest.
     * - In IE10, using without offset caused errors
     *
     * @return {moment} Moment date object for current date
     */
    get today() {
        return moment().tz(NZ_TIMEZONE);
    }

    /**
     * Get date format for today.
     *
     * @return {string} today in the project date format
     */
    get todayWithFullFormat() {
        return this.today.format(DATE_FORMAT_FULL);
    }

    /**
     * Get date range for this month.
     *
     * @return {array}
     */
    get thisMonth() {
        const end = this.today.endOf('month');
        const start = this.today;

        return [start.format(DATE_FORMAT_FULL), end.format(DATE_FORMAT_FULL)].join(',');
    }

    /**
     * Get date range for this week.
     *
     * @return {array}
     */
    get thisWeek() {
        const end = this.today.endOf('isoWeek');
        const start = this.today;

        return [start.format(DATE_FORMAT_FULL), end.format(DATE_FORMAT_FULL)].join(',');
    }

    /**
     * Get date range for this weekend.
     *
     * @return {array}
     */
    get thisWeekend() {
        let start = this.today.day(SATURDAY);
        const end = this.today.day(SUNDAY);
        const today = this.today;

        // If on 'Sunday', set start day to Sunday
        if (today.diff(start) > 0) {
            start = today;
        }

        return [start.format(DATE_FORMAT_FULL), end.format(DATE_FORMAT_FULL)].join(',');
    }

    /**
     * Get date range for next month.
     *
     * @return {array}
     */
    get nextMonth() {
        const end = this.today.add(1, 'month').endOf('month');
        const start = this.today.add(1, 'month').startOf('month');

        return [start.format(DATE_FORMAT_FULL), end.format(DATE_FORMAT_FULL)].join(',');
    }

    /**
     * Get date range for next week.
     *
     * @return {array}
     */
    get nextWeek() {
        const end = this.today.add(1, 'w').day(SUNDAY);
        const start = this.today.add(1, 'w').day(1);

        return [start.format(DATE_FORMAT_FULL), end.format(DATE_FORMAT_FULL)].join(',');
    }

    /**
     * Get date range for next 7 days.
     *
     * @return {array}
     */
    get nextSevenDays() {
        const end = this.today.add(1, 'w');
        const start = this.today;

        return [start.format(DATE_FORMAT_FULL), end.format(DATE_FORMAT_FULL)].join(',');
    }

    getDateWithNZTimezone(date, format) {
        const ts = moment.tz(date, NZ_TIMEZONE);

        return format ? ts.format(format) : ts;
    }
}

export default new DateHelper;

