/* eslint-disable camelcase */
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';

const PagebreakQuote = ({ cite, file, hide_quotation_marks, quote, theme }) => {
    const style = {
        backgroundImage: `url(${file})`
    };

    if (quote) {
        return (
            <section className="content-block || pagebreak-quote-block || constrain-width">
                <div className={`inner theme-${theme}`} style={style}>
                    <div className="content-wrapper">
                        <blockquote>
                            {hide_quotation_marks !== '1' && <Icon className="quote-marks open" name="quote-marks" />}
                            {quote}
                            {hide_quotation_marks !== '1' && <Icon className="quote-marks close" name="quote-marks" />}
                        </blockquote>
                        {cite && <cite>{cite}</cite>}
                    </div>
                </div>
            </section>
        );
    }

    return null;
};

PagebreakQuote.propTypes = {
    cite: PropTypes.string.isRequired,
    file: PropTypes.string.isRequired,
    hide_quotation_marks: PropTypes.string, // '0', '1', or undefined
    quote: PropTypes.string.isRequired,
    theme: PropTypes.string.isRequired
};

PagebreakQuote.defaultProps = {
    theme: 'blue'
};

export default PagebreakQuote;
