import Base from './base';
// TODO: replace this with rfa-js-common's getEntityLink
import getEntityLink from 'app/utilities/get-entity-link';

export default class External extends Base {
    constructor(item) {
        super();

        this.item = item;
        this.props = item.data;
        this.type = item.type;
    }

    /**
     * Get Name
     *
     * @return {string}
     */
    get name() {
        return this.props.name || '';
    }

    /**
     * Get Url
     *
     * @return {string}
     */
    get url() {
        const link = getEntityLink({
            item: this.item,
            promoterBuName: 'Auckland Live'
        });

        return link.external ? link.href : link.to;
    }

    /**
     * Get Tile data object.
     * TODO: Currently returns only the bare minimum required to render this
     * external entity as a content set or cross-promo tile.
     *
     * @return {object}
     */
    get tile() {
        return {
            name: this.name,
            thumbnail: { // TODO: Use promoted entity's thumbnail
                landscape: 'https://picsum.photos/1200/600/?random',
                square: 'https://picsum.photos/600/600/?random'
            },
            type: 'page', // TODO: Use promoted entity's type
            url: this.url
        };
    }
}

Object.defineProperties(External.prototype, {
    'description': { enumerable: true },
    'name': { enumerable: true },
    'slug': { enumerable: true },
    'tile': { enumerable: true },
    'url':  { enumerable: true }
});
