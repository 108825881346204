/* eslint-disable require-jsdoc */
import PropTypes from 'prop-types';
import React from 'react';

const PRE = 2;

function Data({ data, title }) {
    return (
        <div className="placeholder-component">
            <code>{title}</code>
            {data && <pre>{JSON.stringify(data, null, PRE)}</pre>}
        </div>
    );
}

Data.propTypes = {
    data: PropTypes.object,
    title: PropTypes.string.isRequired
};

export default Data;
