import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import { getLocal } from 'app/utilities/local-storage';
import { mergeMap  } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import {
    filterNotifications,
    hideNotification,
    NOTIFICATIONS_KEY,
    updateNotifications
} from 'app/utilities/notifications';

export const INITIAL_STATE = {
    notifications: [],
    isActive: false
};


// Actions
export const FETCH_NOTIFICATIONS = 'rfa-aucklandlive-website/notifications/FETCH_NOTIFICATIONS';
export const FETCH_NOTIFICATIONS_SUCCESS = 'rfa-aucklandlive-website/notifications/FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAIL = 'rfa-aucklandlive-website/notifications/FETCH_NOTIFICATIONS_FAIL';
export const TOGGLE_NOTIFICATIONS = 'rfa-aucklandlive-website/notifications/TOGGLE_NOTIFICATIONS';
export const UPDATE_NOTIFICATION = 'rfa-aucklandlive-website/notifications/UPDATE_NOTIFICATION';
export const UPDATE_NOTIFICATION_SUCCESS = 'rfa-aucklandlive-website/notifications/UPDATE_NOTIFICATION_SUCCESS';
export const SHOW_NOTIFICATION_BANNER = 'rfa-aucklandlive-website/notifications/SHOW_NOTIFICATION_BANNER';
export const HIDE_NOTIFICATION_BANNER = 'rfa-aucklandlive-website/notifications/HIDE_NOTIFICATION_BANNER';
export const SHOW_ALL_NOTIFICATIONS = 'rfa-aucklandlive-website/notifications/SHOW_ALL_NOTIFICATIONS';


// Actions Creator
export const fetchNotificationsAction = () => ({
    type: FETCH_NOTIFICATIONS
});


export const fetchNotificationsFailAction = (notifications) => ({
    type: FETCH_NOTIFICATIONS_FAIL,
    payload: {
        notifications
    }
});

export const fetchNotificationsSuccessAction = (notifications) => ({
    type: FETCH_NOTIFICATIONS_SUCCESS,
    payload: {
        notifications
    }
});

export const toggleNotificationsAction = () => ({
    type: TOGGLE_NOTIFICATIONS
});

export const updateNotificationAction = (notificationId) => ({
    type: UPDATE_NOTIFICATION,
    notificationId
});

export const updateNotificationSuccessAction = (notifications) => ({
    type: UPDATE_NOTIFICATION_SUCCESS,
    payload: {
        notifications
    }
});

export const showNotificationBannerAction = () => ({
    type: SHOW_NOTIFICATION_BANNER
});

export const hideNotificationBannerAction = () => ({
    type: HIDE_NOTIFICATION_BANNER
});

export const showAllNotificationsAction = () => ({
    type: SHOW_ALL_NOTIFICATIONS
});


// Reducers
export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_NOTIFICATIONS:
        case UPDATE_NOTIFICATION:
        case TOGGLE_NOTIFICATIONS:
            return state;
        case FETCH_NOTIFICATIONS_FAIL:
        case FETCH_NOTIFICATIONS_SUCCESS:
        case UPDATE_NOTIFICATION_SUCCESS:
            return {
                ...state,
                notifications: payload.notifications
            };
        case SHOW_NOTIFICATION_BANNER:
            return { ...state, isActive: true };
        case SHOW_ALL_NOTIFICATIONS:
            return showAllNotifications(state);
        case HIDE_NOTIFICATION_BANNER:
            return { ...state, isActive: false };
        default:
            return state;
    }
};

// Show all notifications reducer
export const showAllNotifications = (state) => {
    const { notifications: currentNotifications } = state;
    const updatedNotifications = currentNotifications.map((item) => ({ ...item, isVisible: true }));

    return {
        ...state,
        notifications: updatedNotifications,
        isActive: true
    };
};


// Epic creator
/**
 * Fetch Notifications Epic
 * @param  {func} endpoint                        - Notifications endpoint
 * @param  {func} fetchNotificationsSuccessAction - Success action creator
 * @param  {func} fetchNotificationsFailAction    - Fail action creator
 * @param  {func} showNotificationBannerAction          - Show notification action creator
 * @return {func}
 */
export const createNotificationsEpic = (
    endpoint,
    fetchNotificationsSuccessAction,
    fetchNotificationsFailAction,
    showNotificationBannerAction,
) => {
    return (action$) => action$.pipe(
        ofType(FETCH_NOTIFICATIONS),
        mergeMap(() => {
            return (
                fetch(endpoint)
                    .then((response) => {
                        const filteredNotifications = filterNotifications(response.data);
                        const hasUnseenNotifications = updateNotifications(filteredNotifications);
                        const updatedNotifications = getLocal(NOTIFICATIONS_KEY);

                        if (hasUnseenNotifications) {
                            return [fetchNotificationsSuccessAction(updatedNotifications), showNotificationBannerAction()];
                        }

                        return [fetchNotificationsSuccessAction(updatedNotifications)];
                    })
                    .catch(() => [fetchNotificationsFailAction([])])
            );
        })
    );
};

/**
 * Update Notifications Epic
 * @param  {func} updateNotificationSuccessAction        - Update Notifications Success Action
 * @param  {func} hideNotificationBannerAction           - Hide Notifications Action
 * @return {func}
 */
export const createUpdateNotificationsEpic = (
    updateNotificationSuccessAction,
    hideNotificationBannerAction
) => {
    return (action$, state$) => action$.pipe(
        ofType(UPDATE_NOTIFICATION),
        mergeMap(({ notificationId }) => {
            const { notifications: currentNotifications } = state$.value.notifications;

            // Update redux store notifications
            const updatedNotifications = currentNotifications.map((item) => {
                if (!notificationId || item.id === notificationId) {
                    item.isVisible = false;
                }

                return item;
            });

            // Update local storage notifications
            hideNotification(notificationId);

            if (!updatedNotifications.some((item) => item.isVisible === true)) {
                return [updateNotificationSuccessAction(updatedNotifications), hideNotificationBannerAction()];
            }

            return [updateNotificationSuccessAction(updatedNotifications)];
        })
    );
};

/**
 * Toggle Notifications Epic
 * @param  {func} updateNotificationAction        - Update Notifications Action
 * @param  {func} showNotificationBannerAction          - Show Notifications Action
 * @param  {func} hideNotificationBannerAction          - Hide Notifications Action
 * @return {func}
 */
export const createToggleNotificationsEpic = (
    updateNotificationAction,
    showNotificationBannerAction,
    hideNotificationBannerAction
) => {
    return (action$, state$) => action$.pipe(
        ofType(TOGGLE_NOTIFICATIONS),
        mergeMap(() => {
            const { isActive, notifications: currentNotifications } = state$.value.notifications;

            if (isActive) {
                if (currentNotifications.some((item) => item.isVisible === false)) {
                    return [showNotificationBannerAction()];
                }

                return [updateNotificationAction(), hideNotificationBannerAction()];
            }

            return [showAllNotificationsAction(), showNotificationBannerAction()];
        })
    );
};

// Epic
const notificationsEpic = createNotificationsEpic(
    ENDPOINTS.NOTIFICATIONS,
    fetchNotificationsSuccessAction,
    fetchNotificationsFailAction,
    showNotificationBannerAction
);

const updateNotificationsEpic = createUpdateNotificationsEpic(
    updateNotificationSuccessAction,
    hideNotificationBannerAction
);

const toggleNotificationsEpic = createToggleNotificationsEpic(
    updateNotificationAction,
    showAllNotificationsAction,
    showNotificationBannerAction,
    hideNotificationBannerAction
);


export const epics = combineEpics(
    notificationsEpic,
    updateNotificationsEpic,
    toggleNotificationsEpic
);
