/* eslint-disable require-jsdoc */
import ContentBlocks from 'app/components/content-blocks/index';
import ContentBlocksModel from 'app/models/content-blocks';
import NewsletterStrip from 'app/components/partials/newsletter-strip.container.js';
import NorificationPageSpecific from 'app/components/partials/notification-page-specific.container';
import PropTypes from 'prop-types';
import React from 'react';

function Page(props) {
    const {
        blocks,
        name: title,
        main_images: images,
        slug
    } = props.data;

    // Use the first image only as the background
    const image = images && images.length ? images[0] : null;
    const formatedContentBlocksData = new ContentBlocksModel(blocks).blocks;

    return (
        <div className="content-page">
            <section className="page-banner">
                {image && <img src={image} alt={title} />}
            </section>
            <NorificationPageSpecific type="page" slug={slug} />
            <ContentBlocks data={formatedContentBlocksData} pageTitle={title} />
            <NewsletterStrip pageTitle={title} />
        </div>
    );
}

Page.propTypes = {
    data: PropTypes.object.isRequired
};

export default Page;
