import classNames from 'classnames';
import { dataLayerPush } from 'app/utilities/gtm';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

const NewsletterSignup = ({ isLarge, pageTitle }) => {
    const blockSectionClassName = classNames('content-block', 'constrain-width', {
        'large': isLarge
    });

    const submitHanlder = (event) => {
        event.preventDefault();

        /* ↓ Tracking Newsletter content block signup click events */
        dataLayerPush({
            event: 'conversionTracking',
            category: 'Interaction',
            action: 'Homepage subscribe CTA banner',
            label: `${pageTitle} - aucklandlive`
        });
        /* ↑ Tracking Newsletter content block signup click events */

        event.target.submit();
    };

    return (
        <section className={blockSectionClassName}>
            <div className="newsletter-signup-block">
                <div className="details">
                    <p><strong>Don't miss out!<br /></strong></p>
                    <span>Be the first to know about performing arts, epic events and exclusive offers in Auckland.</span>
                </div>
                <div className="signup-form">
                    <form action="/newsletter/preferences" method="GET" className="form || newsletter-signup-form" onSubmit={submitHanlder}>
                        <fieldset>
                            <div className="field-group inline">
                                <input name="signup" id="email" type="email" autoComplete="email" autoCorrect="off" autoCapitalize="off" placeholder="Enter email address" required/>
                                <button type="submit" className="button primary inverse">Signup</button>
                            </div>
                        </fieldset>
                    </form>

                    <p className="privacy-policy">We manage subscriber name and email details in accordance with our <Link to="/privacy-policy">Privacy Policy.</Link>
                    </p>
                </div>
            </div>
        </section>
    );
};

NewsletterSignup.propTypes = {
    data: PropTypes.object.isRequired,
    isLarge: PropTypes.bool.isRequired,
    pageTitle: PropTypes.string.isRequired
};


export default NewsletterSignup;
