import AUCKLAND_ARTS_FESTIVAL_LOGO from 'static/images/residents/auckland-arts-festival.png';
import AUCKLAND_PHILHARMONIA_ORCHESTRA_LOGO from 'static/images/residents/auckland-philharmonia-orchestra.png';
import AUCKLAND_WRITERS_FESTIVAL_LOGO from 'static/images/residents/auckland-writers-festival.png';
import CHAMBER_MUSIC_NEW_ZEALAND_LOGO from 'static/images/residents/chamber-music-new-zealand.png';
import COMEDY_FESTIVAL_NO_YEAR_LOGO from 'static/images/residents/comedy-festival-no-year.png';
import FILM_FESTIVAL_LOGO from 'static/images/residents/film-festival-new.svg';
import NEW_ZEALAND_OPERA from 'static/images/residents/new-zealand-opera.png';
import NEW_ZEALAND_SYMPHONY_ORCHESTRA_LOGO from 'static/images/residents/new-zealand-symphony-orchestra.png';
import QBE_LOGO from 'static/images/residents/qbe.png';
import ROYAL_NEW_ZEALAND_BALLET from 'static/images/residents/royal-new-zealand-ballet.png';

export const PARTNERS = [
    {
        name: 'View our list of partners',
        url: '/partnerships',
        alt: 'QBE',
        logo: QBE_LOGO,
        description: 'Principal partner'
    }
];

export const SPONSORS = [
    {
        name: 'Auckland Arts Festival',
        url: '/event/auckland-arts-festival',
        alt: 'Auckland Arts Festival',
        logo: AUCKLAND_ARTS_FESTIVAL_LOGO,
        isExtraWide: true
    },
    {
        name: 'Auckland Philharmonia Orchestra',
        url: '/event/auckland-philharmonia',
        alt: 'Auckland Philharmonia Orchestra',
        logo: AUCKLAND_PHILHARMONIA_ORCHESTRA_LOGO
    },
    {
        name: 'Auckland Writers Festival',
        url: '/event/auckland-writers-festival',
        alt: 'Auckland Writers Festival',
        logo: AUCKLAND_WRITERS_FESTIVAL_LOGO
    },
    {
        name: 'Chamber Music New Zealand',
        url: '/event/chamber-music-new-zealand',
        alt: 'Chamber Music New Zealand',
        logo: CHAMBER_MUSIC_NEW_ZEALAND_LOGO
    },
    {
        name: 'New Zealand International Comedy Festival',
        url: '/event/comedy-festival',
        alt: 'New Zealand International Comedy Festival',
        logo: COMEDY_FESTIVAL_NO_YEAR_LOGO
    },
    {
        name: 'New Zealand International Film Festival',
        url: '/event/nz-international-film-festival',
        alt: 'New Zealand International Film Festival',
        logo: FILM_FESTIVAL_LOGO
    },
    {
        name: 'New Zealand Opera',
        url: '/event/nz-opera',
        alt: 'New Zealand Opera',
        logo: NEW_ZEALAND_OPERA
    },
    {
        name: 'New Zealand Symphony Orchestra',
        url: '/event/new-zealand-symphony-orchestra',
        alt: 'New Zealand Symphony Orchestra',
        logo: NEW_ZEALAND_SYMPHONY_ORCHESTRA_LOGO
    },
    {
        name: 'Royal New Zealand Ballet',
        url: '/event/royal-new-zealand-ballet',
        alt: 'Royal New Zealand Ballet',
        logo: ROYAL_NEW_ZEALAND_BALLET
    }
];
