/* eslint-disable require-jsdoc */
import { RESPONSE, TIMEOUT } from 'config/api';
import 'isomorphic-fetch';

// Parse JSON without throwing error
function safeParseJson(text) {
    try {
        return JSON.parse(text);
    } catch (e) {
        // Never return empty object as that can cause pre-route into an infinite loop
        return {
            errors: 'Could not parse response in JSON',
        };
    }
}

function Api(url, token = null, options = { method: 'GET' }) {
    return new Promise((resolve, reject) => {
        authoriseApiRequest(url, token, options)
            .then((response) => {
                if ((response.status >= RESPONSE.OK) && (response.status < RESPONSE.BAD_REQUEST)) {
                    // Using this instead of resolve(response.json()) because of
                    // https://github.com/github/fetch/issues/268
                    response.text().then((text) => {
                        resolve(safeParseJson(text));
                    });
                } else if (response.status >= RESPONSE.BAD_REQUEST)
                    reject({ status: response.status });
            })
            .catch((error) => {
                reject({ status: RESPONSE.INTERNAL_SERVER_ERROR, error });
            });
    });
}

function authoriseApiRequest(url, token, options) {
    let headers = {};

    if (token) {
        headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            Authorization: `${token.token_type} ${token.access_token}`
        };
    }

    return fetch(url, {
        method: options.method,
        headers,
        timeout: TIMEOUT,
        ...options.method !== 'GET' && { body: options.body }
    });
}

export default Api;
