/* eslint-disable camelcase */
import getVideoId from 'get-video-id';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const Video = ({ credit, title, video_url: videoUrl }) => {
    const [iframeUrl, setIframeUrl] = useState('');

    const initIframe = () => {
        const { id, service } = getVideoId(videoUrl);
        if (id && service) {
            let iframeUrl;
            if (service === 'youtube') {
                iframeUrl = `https://www.youtube.com/embed/${id}?rel=0&controls=0&showinfo=0`;
            } else if (service === 'vimeo') {
                iframeUrl = `https://player.vimeo.com/video/${id}`;
            }

            setIframeUrl(iframeUrl);
        }
    };

    useEffect(() => {
        initIframe();
    });

    return (
        <section className="content-block || video-block">
            <div className="constrain-width medium || inner">
                <div className="meta">
                    <h4 className="title">{title}</h4>
                    <p className="credit">{credit}</p>
                </div>
                <iframe
                    title={title}
                    className="player"
                    src={iframeUrl}
                    allowFullScreen
                    frameBorder="0"
                ></iframe>
            </div>
        </section>
    );
};

Video.propTypes = {
    credit: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    video_url: PropTypes.string.isRequired
};

export default Video;
