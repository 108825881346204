/* eslint-disable no-magic-numbers */
import classNames from 'classnames';
import Icon from './icon';
import PropTypes from 'prop-types';
import RestoreScrollLocation from 'app/components/partials/restore-scroll-location';
import Slider from 'react-slick';
import Tile from './tile/index';
import React, { useEffect, useRef, useState } from 'react';

const Grid = ({ data, hasLine, heading, isLarge, isSlickSlider, limit, multiRows, isAutoScroll, isDialog = false, scrollRef }) => {
    const [gridItems, setGridItems] = useState([]);
    const [activeSlide, setActiveSlide] = useState(0);
    const [itemsCountPerSlide, setItemsCountPerSlide] = useState(4);
    const sliderRef = useRef();

    const setupGridItems = () => {
        const formattedGridItems = data.map((item) => ({ ...item, isHighlighted: false }));

        if (limit) formattedGridItems.splice(limit);

        setGridItems(formattedGridItems);
    };

    const windowSizeHandler = () => {
        const { innerWidth: width } = window;

        if (width >= 1000) {
            setItemsCountPerSlide(4);
        } else if (width >= 800) {
            setItemsCountPerSlide(3);
        } else if (width >= 350) {
            setItemsCountPerSlide(2);
        } else {
            setItemsCountPerSlide(1);
        }
    };

    const arrowClickHandler = (type = 'prev') => {
        if (type === 'prev') {
            sliderRef.current.slickPrev();
        } else {
            sliderRef.current.slickNext();
        }
    };

    const renderPaging = () => {
        if (!gridItems || gridItems.length === 0) {
            return;
        }

        const sliderRows = multiRows ? 2 : 1;
        const lastItemIndexOnCurrentSlide = (activeSlide + 1) * itemsCountPerSlide * sliderRows;
        const actualLastItemIndex = lastItemIndexOnCurrentSlide >= gridItems.length ? gridItems.length : lastItemIndexOnCurrentSlide;
        const pagingDetails = `${(activeSlide * itemsCountPerSlide * sliderRows + 1)} - ${actualLastItemIndex} of ${gridItems.length}`;

        return (
            <div className="paging">
                <button
                    className="slick-arrow slick-arrow__prev"
                    onClick={() => arrowClickHandler('prev')}
                    disabled={activeSlide === 0 || gridItems.length <= itemsCountPerSlide * sliderRows}
                >
                    <Icon name="chevron" />
                </button>
                <p className="details">
                    {pagingDetails}
                </p>
                <button className="slick-arrow slick-arrow__next" onClick={() => arrowClickHandler('next')} disabled={lastItemIndexOnCurrentSlide >= gridItems.length}>
                    <Icon name="chevron" />
                </button>
            </div>
        );
    };

    useEffect(() => {
        windowSizeHandler();
        window.addEventListener('resize', windowSizeHandler);

        return () => window.removeEventListener('resize', windowSizeHandler);
    }, []);

    useEffect(() => {
        setupGridItems();
    }, [data]);

    const constrainClass = classNames('constrain-width', {
        'large': isLarge
    });

    const basicSettings = {
        arrows: false,
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesPerRow: itemsCountPerSlide,
        beforeChange: (current, next) => setActiveSlide(next)
    };

    const settings = multiRows ?
        {
            ... basicSettings,
            rows: 2,
        } : {
            ... basicSettings,
            rows: 1,
        };

    return (
        <div className="grid">
            <div className={constrainClass}>
                {heading && <h4>{heading}</h4>}
                {hasLine && <hr/>}

                {/* Two types of tiles: default / slider */}
                {isSlickSlider ? (
                    <div className="tiles-slider">
                        <div className="tiles-slider-container">
                            <Slider {...settings} ref={sliderRef}>
                                {gridItems.map((item, index) => (
                                    <div key={index}>
                                        <Tile {...item} isSliderTile={true} />
                                    </div>
                                ))}
                            </Slider>
                            {renderPaging()}
                        </div>
                    </div>
                ) : (
                    <div className="tiles">
                        {gridItems.map((item, index) => <Tile key={index} {...item}/>)}
                    </div>
                )}
                {isAutoScroll && <RestoreScrollLocation flag={gridItems.length > 0} isDialog={isDialog} scrollRef={scrollRef} />}
            </div>
        </div>
    );
};

Grid.defaultProps = {
    hasLine: true,
    isLarge: false,
    isSlickSlider: false,
    multiRows: false,
    isAutoScroll: false,
    isDialog: false
};

Grid.propTypes = {
    data: PropTypes.array.isRequired,
    hasLine: PropTypes.bool,
    heading: PropTypes.string,
    isLarge: PropTypes.bool,
    isSlickSlider: PropTypes.bool,
    limit: PropTypes.number,
    multiRows: PropTypes.bool,
    isAutoScroll: PropTypes.bool,
    isDialog: PropTypes.bool,
    scrollRef:PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.object })
    ]),
};

export default Grid;
