import classNames from 'classnames';
import PropTypes from 'prop-types';
import Share from 'app/components/partials/share';
import React, { useEffect, useRef, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

const VisibleTabs = ({ extraClass, hashChange, hasShare, isNew, renderTabsPanel, selectedTab, shareData, tabsList, tabChangeHandler }) => {
    const [selectedTabIndex, setSelectedTabIndex] = useState(selectedTab);
    const [tabsPositionY, setTabsPositionY] = useState(0);
    const [isTabsFixed, setIsTabsFixed] = useState(false);
    const tabsRef = useRef();

    const tabsClass = classNames('tabs', extraClass, {
        'is-new': isNew
    });

    const setTabState = (tabIndex = 0, hash = '') => {
        setSelectedTabIndex(tabIndex);
        if (hashChange && hash) {
            window.history.replaceState(null, null, `#${hash}`);
            tabsRef.current.scrollIntoView();
        }
    };

    const handleSelect = (index) => {
        setTabState(index, tabsList[index].value);
    };

    const hashChangeHanlder = () => {
        if (!hashChange) {
            return;
        }
        const urlHash = window.location.hash.split('#')[1];
        if (!urlHash) {
            // Set to default tab when no hash in the url
            return setSelectedTabIndex(0);
        }
        const targetTabIndex = tabsList.findIndex((tab) => tab.value === urlHash);
        const targetTab = tabsList.find((tab) => tab.value === urlHash);

        if (urlHash && targetTabIndex >= 0 && targetTab) {
            if (targetTabIndex === selectedTabIndex) {
                return;
            }

            return setTabState(targetTabIndex, targetTab.value);
        }
        setTabState();
    };

    const windowScrollHandler = () => {
        if (window.pageYOffset >= tabsPositionY) {
            setIsTabsFixed(true);
        } else {
            setIsTabsFixed(false);
        }
    };

    useEffect(() => {
        if (tabsPositionY && hashChange) {
            window.addEventListener('scroll', windowScrollHandler);
        }

        return () => {
            window.removeEventListener('scroll', windowScrollHandler);
        };
    }, [tabsPositionY]);

    useEffect(() => {
        if (tabsRef && tabsRef.current) {
            setTabsPositionY(tabsRef.current.getBoundingClientRect().top + window.scrollY);
        }
    }, [tabsRef]);

    useEffect(() => {
        if (tabChangeHandler) {
            tabChangeHandler(tabsList[selectedTabIndex || 0].value);
        }
    }, [selectedTabIndex]);

    useEffect(() => {
        hashChangeHanlder();
        if (hashChange) {
            window.addEventListener('hashchange', hashChangeHanlder);
        }

        return () => {
            window.removeEventListener('hashchange', hashChangeHanlder);
        };
    }, []);

    const tabsListClass = classNames('tabs-tab-list', {
        'is-fixed': hashChange && isTabsFixed
    });

    return (
        <div className="tabs-wrapper" ref={tabsRef}>
            <Tabs className={tabsClass} selectedIndex={selectedTabIndex} onSelect={handleSelect}>
                <TabList className={tabsListClass}>
                    {tabsList.map((tab, index) => <Tab className="tabs-tab" key={index} selectedClassName="is-active">{tab.title}</Tab>)}
                    {hasShare && (
                        <Share {...shareData} />
                    )}
                </TabList>

                {tabsList.map((tab, index) => (
                    <TabPanel key={index} className="tabs-tab-panel">
                        {renderTabsPanel(tab)}
                    </TabPanel>
                ))}
            </Tabs>
        </div>
    );
};


VisibleTabs.propTypes = {
    extraClass: PropTypes.string,
    hashChange: PropTypes.bool,
    hasShare: PropTypes.bool,
    isNew: PropTypes.bool,
    renderTabsPanel: PropTypes.func.isRequired,
    selectedTab: PropTypes.number,
    shareData: PropTypes.object,
    tabsList: PropTypes.array.isRequired,
    tabChangeHandler: PropTypes.func
};

VisibleTabs.defaultProps = {
    extraClass: '',
    hashChange: false,
    hasShare: false,
    isNew: false,
    selectedTab: 0
};

export default VisibleTabs;
