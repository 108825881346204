import React from 'react';

const Logo = () => {
    return (
        <React.Fragment>
            <div className="image">
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 205.5 196.81">
                    <title>
                        Logo - Colour
                    </title>
                    <g fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#E93725" strokeWidth="5" strokeMiterlimit="10">
                        <path id="red" className="st0" d="M151.76,138.9l-1.46-1.44c-0.97-0.97-2.41-2.41-4.23-4.33c-2.24-2.36-4.41-4.78-6.52-7.26
                            c-2.87-3.36-5.58-6.85-8.13-10.46c-3.19-4.51-6.03-9.25-8.5-14.19c-2.67-5.42-5.08-11.62-6.4-18.6c-0.69-3.61-1.02-7.28-0.98-10.96
                            c0.05-3.92,0.59-7.82,1.59-11.6c1.04-3.88,2.63-7.73,4.76-11.36c2.18-3.69,4.83-7.08,7.88-10.09c6.19-6.13,14.06-10.93,22.87-13.83
                            c4.49-1.49,9.15-2.4,13.87-2.71c4.79-0.28,9.78,0.09,14.61,1.65c2.46,0.78,4.8,1.91,6.94,3.36c2.16,1.47,4.12,3.36,5.65,5.58
                            c1.54,2.21,2.65,4.73,3.29,7.34c0.65,2.62,0.86,5.31,0.77,7.97c-0.21,5.33-1.56,10.51-3.4,15.45c-1.86,4.94-4.24,9.68-6.92,14.27
                            c-5.37,9.17-11.86,17.76-18.85,26.02c-6.99,8.28-14.53,16.22-22.38,23.93c-1.97,1.93-3.95,3.84-5.96,5.74l-1.53,1.42l-1.59,1.4
                            c-5.34,4.57-11.26,8.43-17.6,11.47c-10.2,4.88-21.29,7.59-32.59,7.96c-11.26,0.39-22.63-1.63-33-6.05
                            c-10.39-4.42-19.62-11.17-26.97-19.73c-3.62-4.25-6.76-8.89-9.36-13.84c-5.13-9.78-8.24-20.5-9.13-31.51
                            c-0.43-5.42-0.32-10.87,0.41-16.2c0.7-5.3,2.05-10.5,4.01-15.48c1.94-4.9,4.58-9.5,7.84-13.64c2.02-2.54,4.28-4.87,6.76-6.97
                            c0.48-0.42,0.99-0.81,1.49-1.21c0.5-0.41,1.03-0.77,1.54-1.15c0.14-0.1,0.23-0.18,0.41-0.29l0.38-0.26c2.56-1.66,5.47-2.67,8.5-2.97
                            c2.56-0.24,5.15-0.11,7.68,0.38c4.96,0.93,9.5,2.78,13.75,4.9c4.15,2.12,8.15,4.51,11.99,7.16c7.54,5.16,14.31,10.78,20.58,16.4
                            c5.98,5.39,11.77,11,17.34,16.82c5.03,5.24,9.92,10.6,14.68,16.08c8.96,10.32,16.31,19.73,22.06,27.73
                            c2.88,4.01,5.35,7.66,7.38,10.95c1.02,1.65,1.93,3.2,2.72,4.68c0.79,1.49,1.47,2.87,1.98,4.31c0.13,0.36,0.24,0.73,0.34,1.13
                            c0.1,0.4,0.18,0.83,0.2,1.38c0,0.27,0,0.59-0.09,0.97c-0.1,0.49-0.31,0.95-0.63,1.34c-0.37,0.45-0.87,0.79-1.43,0.96
                            c-0.38,0.11-0.77,0.16-1.16,0.13c-0.6-0.04-0.98-0.16-1.31-0.27c-0.29-0.1-0.57-0.22-0.84-0.35c-0.41-0.2-0.81-0.41-1.19-0.65
                            c-0.66-0.4-1.1-0.72-1.41-0.93l-0.58-0.41L151.76,138.9L151.76,138.9z"/>
                    </g>
                    <g fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#FFD550" strokeWidth="5" strokeMiterlimit="10">
                        <path id="Yellow" className="st1" d="M110,150.41l-0.55-1.69c-0.35-1.12-0.88-2.78-1.48-4.98c-1.73-6.32-3.08-12.74-4.03-19.22
                            c-1.27-8.35-2.14-18.57-2.47-30.26c-0.17-6.2-0.23-12.39-0.18-18.59c0.02-6.53,0.12-13.39,0.07-20.51
                            c-0.07-7.12-0.22-14.54-1.21-22.04c-0.52-3.74-1.25-7.51-2.53-11.09c-1.27-3.56-3.14-6.95-5.95-9.38c-2.78-2.46-6.48-3.76-10.4-4.08
                            c-3.93-0.34-8.03,0.18-12.1,1.06c-6.24,1.42-12.3,3.52-18.08,6.25c-1.94,0.95-3.87,1.96-5.76,3.05c-7.69,4.46-14.5,10.3-20.08,17.23
                            c-5.72,7.14-9.9,15.39-12.31,24.22c-2.45,8.99-2.93,18.4-1.4,27.59c1.37,8.07,4.31,15.8,8.65,22.75l0.95,1.48l0.48,0.69l0.51,0.67
                            c1.38,1.76,3.06,3.22,5.01,4.12c0.97,0.44,2,0.73,3.06,0.89c1.06,0.15,2.15,0.15,3.24,0.05c2.18-0.22,4.35-0.9,6.44-1.78
                            c2.1-0.92,4.13-2,6.07-3.23c3.92-2.45,7.61-5.32,11.18-8.32c3.56-3,7.01-6.15,10.4-9.34c13.51-12.82,26.1-26.29,39.83-37.83
                            c3.4-2.88,6.94-5.6,10.6-8.14c3.65-2.51,7.45-4.79,11.47-6.61c2.01-0.91,4.08-1.69,6.22-2.28c1.08-0.29,2.13-0.54,3.27-0.73
                            c1.05-0.18,2.21-0.33,3.36-0.35c2.37-0.08,4.73,0.43,6.86,1.46c2.16,1.06,3.95,2.73,5.28,4.57c1.35,1.85,2.3,3.87,3.03,5.9
                            c0.72,2.03,1.22,4.09,1.59,6.13c0.73,4.09,0.96,8.13,0.96,12.07c-0.02,7.89-0.92,15.42-2.12,22.55c-1.19,6.82-2.74,13.56-4.64,20.22
                            c-1.69,6.01-3.67,11.93-5.94,17.75c-1.98,5.15-4.28,10.16-6.9,15.02c-2.47,4.5-5.08,8.52-8.1,11.79c-1.52,1.63-3.15,3.08-5.05,4.17
                            c-0.95,0.54-1.96,0.98-3.05,1.24c-1.08,0.27-2.23,0.34-3.32,0.18c-1.04-0.15-2.04-0.48-2.96-0.99c-0.79-0.44-1.52-0.98-2.18-1.6
                            c-1.04-1-1.94-2.14-2.69-3.37c-1.29-2.12-1.97-3.81-2.42-4.94c-0.22-0.55-0.4-1.04-0.49-1.29l-0.16-0.44L110,150.41L110,150.41z"/>
                    </g>
                    <g fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#00B270" strokeWidth="5" strokeMiterlimit="10">
                        <path id="green" className="st2" d="M155.84,104.6l-1.71,5.95c-1.63,5.6-3.35,11.17-5.14,16.72c-2.35,7.17-5.29,15.85-9.29,25.61
                            c-2.13,5.21-4.5,10.31-7.09,15.31c-1.38,2.64-2.9,5.32-4.63,7.98c-1.76,2.76-3.74,5.39-5.91,7.84c-2.28,2.5-4.92,4.95-8.33,6.69
                            c-1.77,0.91-3.68,1.48-5.66,1.7c-2.05,0.2-4.12-0.03-6.08-0.68c-1.89-0.65-3.67-1.58-5.28-2.77c-1.55-1.14-2.99-2.43-4.3-3.85
                            c-2.61-2.79-4.78-5.88-6.78-9.1c-3.97-6.46-7.19-13.44-10.18-20.65c-2.99-7.22-5.7-14.69-8.32-22.33
                            c-5.2-15.28-9.96-31.22-15.13-47.27c-2.59-8.02-5.28-16.08-8.37-23.98c-1.52-3.95-3.22-7.83-5.1-11.62
                            c-0.93-1.87-1.97-3.69-3.1-5.45c-1.14-1.72-2.38-3.37-3.84-4.66c-1.45-1.29-3.14-2.13-4.77-1.85c-1.64,0.26-3.21,1.54-4.49,3.06
                            c-1.29,1.53-2.39,3.31-3.37,5.14c-0.98,1.86-1.86,3.77-2.65,5.71c-0.8,1.96-1.54,3.94-2.23,5.94c-0.35,0.99-0.68,1.99-1.01,3.01
                            c-0.32,0.99-0.61,1.99-0.87,3c-1.02,4.05-1.61,8.19-1.76,12.36c-0.33,8.33,0.89,16.65,3.23,24.49c2.35,7.84,5.83,15.24,10.34,21.78
                            c4.5,6.53,9.98,12.2,16.16,16.62c6.03,4.32,12.79,7.52,19.96,9.44c3.47,0.94,6.99,1.54,10.46,1.9l1.3,0.13l1.29,0.09
                            c0.85,0.07,1.73,0.1,2.6,0.13c1.71,0.08,3.39,0.03,5.06-0.11c6.68-0.57,12.95-2.71,18.53-5.52c5.6-2.83,10.59-6.29,15.07-9.89
                            c4.24-3.45,8.28-7.14,12.09-11.06c7.22-7.38,12.92-14.39,17.6-20.2c2.35-2.91,4.41-5.53,6.4-7.79c0.5-0.56,1-1.1,1.55-1.63
                            c0.28-0.27,0.56-0.53,0.9-0.79c0.17-0.13,0.36-0.27,0.59-0.4c0.24-0.14,0.51-0.29,1-0.4c0.76-0.18,1.56,0.03,2.14,0.56
                            c0.25,0.23,0.45,0.51,0.59,0.81c0.11,0.23,0.17,0.43,0.2,0.59c0.14,0.65,0.11,1.04,0.1,1.41c-0.04,0.73-0.13,1.3-0.23,1.85
                            c-0.4,2.13-0.82,3.62-1.09,4.65L155.84,104.6L155.84,104.6z"/>
                    </g>
                </svg>
            </div>
            <div className="text">
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 213 22">
                    <g id="logo" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g fill="currentColor">
                            <path d="M0.0477101727,16.3271231 L7.5018906,0.262997647 C7.57350672,0.119640784 7.73993282,0.000142745098 7.90696929,0.000142745098 L8.14460461,0.000142745098 C8.31164107,0.000142745098 8.47847409,0.119640784 8.5496833,0.262997647 L16.0034568,16.3271231 C16.1468925,16.6372878 15.9562553,16.9478604 15.598785,16.9478604 L12.9552956,16.9478604 C12.527023,16.9478604 12.3359789,16.8040957 12.1219443,16.3511859 L11.2645854,14.4655231 L4.78678503,14.4655231 L3.92962956,16.3748408 C3.81040499,16.6613506 3.54855854,16.9478604 3.07227063,16.9478604 L0.452788868,16.9478604 C0.095318618,16.9478604 -0.0951151631,16.6372878 0.0477101727,16.3271231 L0.0477101727,16.3271231 Z M9.83551823,11.2190918 L8.02578695,7.28034667 L8.00177927,7.28034667 L6.21564875,11.2190918 L9.83551823,11.2190918 Z" id="Fill-1"></path>
                            <path d="M19.337818,0.692578824 C19.337818,0.453786667 19.552463,0.238853333 19.7900983,0.238853333 L22.7198488,0.238853333 C22.9816952,0.238853333 23.172129,0.453786667 23.172129,0.692578824 L23.172129,10.3596847 C23.172129,12.030618 24.4105589,13.3669161 26.1014726,13.3669161 C27.8157835,13.3669161 29.0784246,12.030618 29.0784246,10.3596847 L29.0784246,0.692578824 C29.0784246,0.453786667 29.2688583,0.238853333 29.5307048,0.238853333 L32.4600484,0.238853333 C32.6980906,0.238853333 32.9123286,0.453786667 32.9123286,0.692578824 L32.9123286,10.5505553 C32.9123286,14.1787278 29.8641674,17.186571 26.1014726,17.186571 C22.3623785,17.186571 19.337818,14.1787278 19.337818,10.5505553 L19.337818,0.692578824 Z" id="Fill-3"></path>
                            <path d="M46.5581704,8.15686276e-05 C48.9398134,8.15686276e-05 50.7019363,0.740316863 52.3214372,2.22037961 C52.5356752,2.4112502 52.5356752,2.69735216 52.345038,2.88842667 L50.4876983,4.82180706 C50.3208653,4.98902275 50.0590188,4.98902275 49.8921858,4.82180706 C49.0110226,4.03426196 47.8438019,3.60500706 46.677395,3.60500706 C43.9862971,3.60500706 42.0097328,5.84834824 42.0097328,8.52155608 C42.0097328,11.1713129 44.0098979,13.3669365 46.7009958,13.3669365 C47.8202012,13.3669365 49.0346234,12.9617443 49.8921858,12.2211012 C50.0590188,12.0783561 50.3684737,12.0783561 50.511299,12.2451639 L52.3690457,14.2268737 C52.5356752,14.3936816 52.5120745,14.7038463 52.345038,14.871062 C50.725944,16.4461522 48.6777635,17.1859796 46.5581704,17.1859796 C41.7950879,17.1859796 37.960777,13.3909992 37.960777,8.61719529 C37.960777,3.84318745 41.7950879,8.15686276e-05 46.5581704,8.15686276e-05" id="Fill-6"></path>
                            <path d="M57.417433,0.81177098 C57.417433,0.501606275 57.6556787,0.238751373 57.9889378,0.238751373 L60.775456,0.238751373 C61.0849109,0.238751373 61.3469608,0.501606275 61.3469608,0.81177098 L61.3469608,7.08949647 L66.6339397,0.477543529 C66.7293601,0.358453333 66.9433946,0.238751373 67.0864234,0.238751373 L70.0871797,0.238751373 C70.5396633,0.238751373 70.7777056,0.764053333 70.5160626,1.12234353 L64.9432296,8.11603765 L71.0159512,16.0406337 C71.2777977,16.3989239 71.0159512,16.9242259 70.5632641,16.9242259 L67.2768572,16.9242259 C67.1104311,16.9242259 66.8957862,16.8281788 66.8485846,16.7568063 L61.3469608,9.26187294 L61.3469608,16.3748612 C61.3469608,16.6850259 61.0849109,16.9478808 60.775456,16.9478808 L57.9889378,16.9478808 C57.6556787,16.9478808 57.417433,16.6850259 57.417433,16.3748612 L57.417433,0.81177098 Z" id="Fill-8"></path>
                            <path d="M75.6406234,0.692578824 C75.6406234,0.453786667 75.8310572,0.238853333 76.0929036,0.238853333 L78.9270303,0.238853333 C79.1650726,0.238853333 79.3793106,0.453786667 79.3793106,0.692578824 L79.3793106,13.4389004 L85.0473605,13.4389004 C85.3096138,13.4389004 85.5000476,13.6538337 85.5000476,13.8926259 L85.5000476,16.4944612 C85.5000476,16.7330494 85.3096138,16.9477788 85.0473605,16.9477788 L76.0929036,16.9477788 C75.8310572,16.9477788 75.6406234,16.7330494 75.6406234,16.4944612 L75.6406234,0.692578824 Z" id="Fill-10"></path>
                            <path d="M89.0389624,16.3271231 L96.4931428,0.262997647 C96.5647589,0.119640784 96.731185,0.000142745098 96.8982215,0.000142745098 L97.1358568,0.000142745098 C97.3028933,0.000142745098 97.4697263,0.119640784 97.5409355,0.262997647 L104.995116,16.3271231 C105.138145,16.6372878 104.947507,16.9478604 104.590444,16.9478604 L101.946548,16.9478604 C101.518275,16.9478604 101.327841,16.8040957 101.113197,16.3511859 L100.255838,14.4655231 L93.7778338,14.4655231 L92.9208818,16.3748408 C92.8016572,16.6613506 92.5398107,16.9478604 92.0635228,16.9478604 L89.4440411,16.9478604 C89.0865708,16.9478604 88.896137,16.6372878 89.0389624,16.3271231 L89.0389624,16.3271231 Z M98.8267704,11.2190918 L97.0170392,7.28034667 L96.9930315,7.28034667 L95.206901,11.2190918 L98.8267704,11.2190918 Z" id="Fill-12"></path>
                            <path d="M109.336294,0.429825882 C109.336294,0.191033725 109.550532,0.000163137255 109.788574,0.000163137255 L110.38429,0.000163137255 L119.886244,9.1660298 L119.910252,9.1660298 L119.910252,0.692680784 C119.910252,0.453888627 120.100686,0.238751373 120.362532,0.238751373 L123.220666,0.238751373 C123.458302,0.238751373 123.672947,0.453888627 123.672947,0.692680784 L123.672947,16.7568063 C123.672947,16.9951906 123.458302,17.1860612 123.220666,17.1860612 L122.839595,17.1860612 C122.767776,17.1860612 122.577342,17.1146886 122.529734,17.066971 L113.122997,7.59093961 L113.098989,7.59093961 L113.098989,16.4939514 C113.098989,16.7331514 112.908352,16.9478808 112.646709,16.9478808 L109.812582,16.9478808 C109.57454,16.9478808 109.359895,16.7331514 109.359895,16.4939514 L109.336294,0.429825882 Z" id="Fill-14"></path>
                            <path d="M130.081369,0.692578824 C130.081369,0.453786667 130.271803,0.238853333 130.510048,0.238853333 L136.320517,0.238853333 C140.917173,0.238853333 144.679868,3.98693176 144.679868,8.5698651 C144.679868,13.2001082 140.917173,16.9477788 136.320517,16.9477788 L130.510048,16.9477788 C130.271803,16.9477788 130.081369,16.7330494 130.081369,16.4944612 L130.081369,0.692578824 Z M136.082474,13.4152455 C138.773572,13.4152455 140.726536,11.2903827 140.726536,8.5698651 C140.726536,5.87259451 138.773572,3.74773176 136.082474,3.74773176 L133.796455,3.74773176 L133.796455,13.4152455 L136.082474,13.4152455 Z" id="Fill-16"></path>
                            <path d="M159.372567,0.453766275 C159.372567,0.214974118 159.563,4.07843136e-05 159.825254,4.07843136e-05 L162.65938,4.07843136e-05 C162.897423,4.07843136e-05 163.111661,0.214974118 163.111661,0.453766275 L163.111661,13.2000878 L168.779711,13.2000878 C169.041354,13.2000878 169.231991,13.4150212 169.231991,13.6538133 L169.231991,16.2556486 C169.231991,16.4942369 169.041354,16.7089663 168.779711,16.7089663 L159.825254,16.7089663 C159.563,16.7089663 159.372567,16.4942369 159.372567,16.2556486 L159.372567,0.453766275 Z" id="Fill-18"></path>
                            <path d="M173.726309,0.453766275 C173.726309,0.214974118 173.940344,4.07843136e-05 174.17859,4.07843136e-05 L177.036724,4.07843136e-05 C177.274359,4.07843136e-05 177.489004,0.214974118 177.489004,0.453766275 L177.489004,16.2556486 C177.489004,16.4942369 177.274359,16.7089663 177.036724,16.7089663 L174.17859,16.7089663 C173.940344,16.7089663 173.726309,16.4942369 173.726309,16.2556486 L173.726309,0.453766275 Z" id="Fill-20"></path>
                            <path d="M181.345512,0.620798431 C181.202687,0.310633725 181.393121,6.11764706e-05 181.750591,6.11764706e-05 L184.91757,6.11764706e-05 C185.108207,6.11764706e-05 185.27504,0.143418039 185.322852,0.262916078 L189.251973,8.97505725 L189.395205,8.97505725 L193.324529,0.262916078 C193.372138,0.143418039 193.538564,6.11764706e-05 193.729201,6.11764706e-05 L196.896587,6.11764706e-05 C197.254057,6.11764706e-05 197.444491,0.310633725 197.301259,0.620798431 L189.823478,16.6849239 C189.752268,16.8282808 189.609443,16.947371 189.418806,16.947371 L189.180764,16.947371 C188.990126,16.947371 188.847301,16.8282808 188.775685,16.6849239 L181.345512,0.620798431 Z" id="Fill-22"></path>
                            <path d="M201.180676,0.453766275 C201.180676,0.214974118 201.371109,4.07843136e-05 201.633363,4.07843136e-05 L211.49238,4.07843136e-05 C211.754633,4.07843136e-05 211.945067,0.214974118 211.945067,0.453766275 L211.945067,3.05560157 C211.945067,3.29439373 211.754633,3.50891922 211.49238,3.50891922 L204.896169,3.50891922 L204.896169,6.46863686 L210.325566,6.46863686 C210.563812,6.46863686 210.778253,6.6835702 210.778253,6.92236235 L210.778253,9.52419765 C210.778253,9.78664471 210.563812,9.97751529 210.325566,9.97751529 L204.896169,9.97751529 L204.896169,13.2000878 L211.49238,13.2000878 C211.754633,13.2000878 211.945067,13.4150212 211.945067,13.6538133 L211.945067,16.2556486 C211.945067,16.4942369 211.754633,16.7089663 211.49238,16.7089663 L201.633363,16.7089663 C201.371109,16.7089663 201.180676,16.4942369 201.180676,16.2556486 L201.180676,0.453766275 Z" id="Fill-24"></path>
                        </g>
                    </g>
                </svg>
                <span className="credit-line">Creating memories that last a lifetime</span>
            </div>
        </React.Fragment>
    );
};

export default Logo;
