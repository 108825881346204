import ACLogo from 'static/images/logo/ac-whanau-tile-right.svg';
import classNames from 'classnames';
import { dataLayerPush } from 'app/utilities/gtm';
import Icon from './icon';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

const NewsletterStrip = ({ closeNewsletterStrip, displayAcLogo, isOpen, pageTitle }) => {
    const [isScrollDown, setIsScrollDown] = useState(false);

    const navigate = useNavigate();

    const signupBtnClickHandler = () => {
        /* ↓ Tracking Newsletter Signup strip click events */
        dataLayerPush({
            event: 'conversionTracking',
            category: 'Interaction',
            action: 'Sign up strip',
            label: pageTitle
        });
        /* ↑ Tracking Newsletter Signup strip click events */

        navigate('/newsletter/preferences');
    };

    const closeBtnClickHandler = () => closeNewsletterStrip();

    useEffect(() => {
        const threshold = 0;
        let lastScrollY = window.pageYOffset,
            ticking = false;

        const updateScrollStatus = () => {
            const scrollY = window.pageYOffset;

            if (Math.abs(scrollY - lastScrollY) < threshold) {
                ticking = false;

                return;
            }
            setIsScrollDown(scrollY > lastScrollY);
            lastScrollY = scrollY > 0 ? scrollY : 0;
            ticking = false;
        };

        const onScrollHandler = () => {
            if (!ticking) {
                window.requestAnimationFrame(updateScrollStatus);
                ticking = true;
            }
        };

        window.addEventListener('scroll', onScrollHandler);

        return () => {
            window.removeEventListener('scroll', onScrollHandler);
        };
    }, [isScrollDown]);

    const stripClass = classNames('newletter-strip', {
        'is-hiding': !isScrollDown || !isOpen
    });

    const logoContainerClass = classNames('logo-container', { 'is-active': displayAcLogo });

    return (
        <div className={stripClass}>
            <div className="inner || constrain-width large">
                <div className="content">
                    <p><strong>Be the first to know about live events in Auckland!</strong></p>
                    <button className="button secondary || sign-up" onClick={signupBtnClickHandler}>Sign up</button>
                </div>

                <div className="strip-toggle">
                    <div className={logoContainerClass}>
                        <img className="logo" src={ACLogo} alt="Part of the Auckland Council Whānau" />
                    </div>
                    <button className="button secondary no-border || close" onClick={closeBtnClickHandler}>
                        <Icon name="close" />
                    </button>
                </div>
            </div>
        </div>
    );
};

NewsletterStrip.defaultProps = {
    displayAcLogo: false
};

NewsletterStrip.propTypes = {
    closeNewsletterStrip: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    pageTitle: PropTypes.string.isRequired,
    displayAcLogo: PropTypes.bool
};

export default NewsletterStrip;
