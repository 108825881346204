import ContentBlocks from 'app/components/content-blocks/index';
import ContentBlocksModel from 'app/models/content-blocks';
import FeaturedShows from 'app/components/partials/featured-shows';
import HeroCarousel from 'app/components/content-blocks/hero-carousel';
import NewsletterStrip from 'app/components/partials/newsletter-strip.container.js';
import PropTypes from 'prop-types';
import WhatsOn from 'app/components/partials/whats-on';
import React, { useEffect, useRef, useState } from 'react';

const Home = ({ data }) => {
    const [isAcLogoActive, setIsAcLogoActive] = useState(false);

    const heroCarouselRef = useRef(null);

    const { above_grid_blocks: aboveGridBlocks, blocks, grid_title: gridTitle } = data;
    const aboveGridContentBlocks = new ContentBlocksModel(aboveGridBlocks).blocks;
    const contentBlocks = new ContentBlocksModel(blocks).blocks;

    const carouselBlock = aboveGridContentBlocks.find((block) => block.type === 'carousel');
    const aboveGridBlocksNoCarousel = aboveGridContentBlocks.find((block) => block.type !== 'carousel');

    const checkHeroCarouselInView = () => {
        const heroCarouselOffset = heroCarouselRef.current.getBoundingClientRect();
        if (heroCarouselOffset.top < 0 && heroCarouselOffset.bottom < 0) return setIsAcLogoActive(false);

        setIsAcLogoActive(true);
    };

    useEffect(() => {
        checkHeroCarouselInView();
        window.addEventListener('scroll', checkHeroCarouselInView);

        return () => window.removeEventListener('scroll', checkHeroCarouselInView);
    }, []);

    return (
        <div className="content-page || home-page">
            {carouselBlock && <HeroCarousel data={carouselBlock.data} pageTitle="Home" ref={heroCarouselRef} />}
            <WhatsOn />
            {aboveGridBlocksNoCarousel && <ContentBlocks data={aboveGridBlocksNoCarousel} isLarge={true} pageTitle="Home" />}
            <FeaturedShows title={gridTitle} />
            <ContentBlocks data={contentBlocks} isLarge={true} pageTitle="Home" />
            <NewsletterStrip pageTitle="Home" displayAcLogo={isAcLogoActive} />
        </div>
    );
};

Home.propTypes = {
    data: PropTypes.object.isRequired
};


export default Home;
