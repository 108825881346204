import classNames from 'classnames';
import Icon from './icon';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

const Performance = ({ performance, isFree }) => {
    const getPrice = () => {
        if (performance.isFree) {
            return 'Free';
        }

        return performance.priceRange;
    };

    const getWeekDay = () => {
        return moment(performance.date.full).format('ddd');
    };

    const renderbutton = () => {
        if (!performance.bookingLink) {
            return false;
        }

        const bookingLinkLabel = isFree ? 'Book Now' : performance.bookingText;

        if (performance.isSoldOut) {
            return (
                <div className="buy">
                    <button className="button primary inverse" disabled>{performance.bookingDisabledText}</button>
                </div>
            );
        }

        if (performance.isSellingFast) {
            return (
                <div className="buy">
                    <a className="button primary inverse || with-label" href={performance.bookingLink} target="_blank" rel="noreferrer">
                        <small className="label">
                            <Icon name="selling-fast" />
                            <span>Selling fast</span>
                        </small>
                        <span>{bookingLinkLabel}</span>
                    </a>
                </div>);
        }

        return (
            <div className="buy">
                <a className="button primary inverse" href={performance.bookingLink} target="_blank" rel="noreferrer">{bookingLinkLabel}</a>
            </div>
        );
    };

    const containerClass = classNames('performance-info', {
        'not-available': performance.isSoldOut
    });

    return (
        <div className={containerClass}>
            <div className="date">
                <time dateTime={performance.date.full}>
                    <span className="month">{getWeekDay()}</span>
                    <span>{performance.date.day}</span>
                    <span className="month">{performance.date.month}</span>
                </time>
            </div>

            <div className="details">
                <time className="time" dateTime={performance.date.full}>
                    <span>{performance.date.time}</span>
                </time>
                <span className="price">{getPrice()}</span>
            </div>

            <div className="info">
                <strong className="heading">{performance.venue}</strong>
            </div>

            {renderbutton()}
        </div>
    );
};

Performance.propTypes = {
    isFree: PropTypes.bool,
    performance: PropTypes.object.isRequired
};

Performance.defaultProps = {
    isFree: false
};

export default Performance;
