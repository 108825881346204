export const INITIAL_STATE = {
    isOpen: true
};

// Actions
export const CLOSE_NEWSLETTER_STRIP = 'app/status/CLOSE_NEWSLETTER_STRIP';

// Action Creators
export const closeNewsletterStripAction = () => ({ type: CLOSE_NEWSLETTER_STRIP });

// Reducers
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLOSE_NEWSLETTER_STRIP:
            return {
                ...state,
                isOpen: false
            };
        default:
            return state;
    }
};
