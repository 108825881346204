import Grid from 'app/components/partials/grid';
import PropTypes from 'prop-types';
import React from 'react';

const ContentSet = ({ isLarge, data, title }) => (
    <section className="content-block || content-set-block">
        <Grid
            data={data}
            hasLine={Boolean(title)}
            heading={title}
            isLarge={isLarge}
        />
    </section>
);

ContentSet.propTypes = {
    isLarge: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    title: PropTypes.string
};


export default ContentSet;
