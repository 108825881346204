export const GENRE_GROUPS = [
    {
        value: '',
        label: 'any event'
    },
    {
        name: 'Genres',
        isOptGroup: true,
        groupItems: [
            { value: 'cabaret', label: 'Cabaret' },
            { value: 'circus', label: 'Circus & Magic' },
            { value: 'classical-music', label: 'Classical music' },
            { value: 'comedy', label: 'Comedy' },
            { value: 'dance', label: 'Dance' },
            { value: 'dance-party', label: 'Dance Party' },
            { value: 'film', label: 'Film' },
            { value: 'food-drink', label: 'Food & Drink' },
            { value: 'kapa-haka', label: 'Kapa Haka' },
            { value: 'lifestyle', label: 'Lifestyle' },
            { value: 'music', label: 'Music' },
            { value: 'musical', label: 'Musical' },
            { value: 'opera', label: 'Opera' },
            { value: 'special-events', label: 'Special Events' },
            { value: 'talks-ideas', label: 'Talks & ideas' },
            { value: 'theatre', label: 'Theatre' },
            { value: 'visual-arts', label: 'Visual arts' }
        ]
    },
    {
        name: 'Event types',
        isOptGroup: true,
        groupItems: [
            { value: 'accessibility', label: 'Accessibility' },
            { value: 'cultural', label: 'Cultural' },
            { value: 'digital', label: 'Digital/Hybrid/Virtual' },
            { value: 'family', label: 'Family & Kids' },
            { value: 'education', label: 'Education' },
            { value: 'festivals', label: 'Festivals' },
            { value: 'fringe', label: 'Fringe' },
        ]
    }
];

export const GENRES = GENRE_GROUPS.reduce((genres, genreGroup) => {
    if (genreGroup.isOptGroup) {
        genres.push(...genreGroup.groupItems);
    }

    return genres;
}, []);

export const PRICE_RANGE = [
    { value: '', label: 'any price' },
    { value: '0', label: 'Free' },
    { value: '<50', label: 'Under $50' },
    { value: '50-100', label: '$50 - $100' },
    { value: '>100', label: '$100 +' }
];
