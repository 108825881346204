/* eslint-disable no-undef */
import { clientEnv } from 'config/env';
import Icon from 'app/components/partials/icon';
import { optionGroupsConfig } from 'config/newsletter';
import Recaptcha from 'react-google-recaptcha';
import { Link, useSearchParams } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';

const NewsletterPreferences = () => {
    const [firstName, setFirstName] = useState('');
    const [familyName, setFamilyName] = useState('');
    const [email, setEmail] = useState('');
    const [optionGroups, setOptionGroups] = useState(optionGroupsConfig.map(({ options }) => options.map(() => false))); // Nothing is selected by default);

    const [searchParams] = useSearchParams();

    const recaptchaRef = useRef(null);
    const newsletterFormRef = useRef(null);

    const handleSubmit = (event) => {
        event.preventDefault();

        // reCAPTCHA;
        recaptchaRef.current.execute();
    };

    const onVerifySuccessfully = () => {
        newsletterFormRef.current.submit();
    };

    const setOptionSelected = (optionGroupIndex, optionIndex, optionSelected) => {
        setOptionGroups(optionGroups.map((prevOptionGroup, prevOptionGroupIndex) => {
            if (prevOptionGroupIndex === optionGroupIndex) {
                return prevOptionGroup.map((prevOptionSelected, prevOptionIndex) => {
                    if (prevOptionIndex === optionIndex) {
                        return optionSelected;
                    }

                    return prevOptionSelected;
                });
            }

            return prevOptionGroup;
        }));
    };

    useEffect(() => {
        setEmail(searchParams.get('signup') || '');
    }, [searchParams]);

    const nothingIsSelected = optionGroups.every((optionGroup) => optionGroup.every((optionSelected) => !optionSelected));

    return (
        <main className="page" role="main">
            <section className="content-section || constrain-width tiny">
                <h1 className="bordered">Auckland Live Newsletters</h1>
                <p>Be the first to know about the latest events, shows and offers from Auckland Live.</p>
                <form
                    ref={newsletterFormRef}
                    method="post"
                    action="https://www.pages08.net/archtics-internationalcanada/AucklandLive-SignupPage/PreferenceCenter"
                    onSubmit={handleSubmit}
                >
                    <div className="subsection">
                        <label className="input-form-row">
                            <span className="input-label">
                                Email*:
                            </span>
                            <input
                                className="input"
                                name="Email"
                                onChange={(event) => setEmail(event.target.value)}
                                placeholder="Email"
                                required
                                type="email"
                                value={email}
                            />
                        </label>

                        <label className="input-form-row">
                            <span className="input-label">
                                First name*:
                            </span>
                            <input
                                className="input"
                                name="Name_First"
                                onChange={(event) => setFirstName(event.target.value)}
                                placeholder="First name"
                                required
                                type="text"
                                value={firstName}
                            />
                        </label>

                        <label className="input-form-row">
                            <span className="input-label">
                                Family name*:
                            </span>
                            <input
                                className="input"
                                name="Name_Last"
                                onChange={(event) => setFamilyName(event.target.value)}
                                placeholder="Family name"
                                required
                                type="text"
                                value={familyName}
                            />
                        </label>
                    </div>

                    <div className="subsection">
                        <h2>Your interests</h2>

                        <p>
                            Select your subscription preferences below to receive information and exclusive offers about events you’re interested in.
                        </p>

                        {nothingIsSelected && (
                            <label className="faux-required-input-form-row">
                                Subscription preferences:
                                <input
                                    className="faux-required-input"
                                    required
                                    type="text"
                                    defaultValue=""
                                />
                            </label>
                        )}

                        {optionGroupsConfig.map(({ heading, options }, optionGroupIndex) => (
                            <div
                                key={optionGroupIndex}
                                className="subsection"
                            >
                                {heading && <h4>{heading}</h4>}
                                {options.map(({ value, label, description }, optionIndex) => {
                                    const isChecked = optionGroups[optionGroupIndex][optionIndex];
                                    const setChecked = (checked) => setOptionSelected(optionGroupIndex, optionIndex, checked); // eslint-disable-line func-style

                                    return (
                                        <label
                                            key={optionIndex}
                                            className="checkbox-form-row"
                                        >
                                            <input
                                                className="checkbox"
                                                checked={isChecked}
                                                onChange={(event) => setChecked(event.target.checked)}
                                                name="SUBSCRIPTION_GROUP"
                                                type="checkbox"
                                                value={value}
                                            />
                                            <Icon
                                                className="checkbox-icon"
                                                type="icon"
                                                name={isChecked ? 'checkbox-checked' : 'checkbox-unchecked'}
                                            />
                                            <span className="checkbox-label">
                                                <span className="checkbox-label-value">{label}</span>
                                                {description && (
                                                    <span className="checkbox-label-description">
                                                        {description}
                                                    </span>
                                                )}
                                            </span>
                                        </label>
                                    );
                                })}
                            </div>
                        ))}
                    </div>

                    <input type="hidden" name="Source_Name" id="control_COLUMN46" value="Web Form" />
                    <input type="hidden" name="client_id" id="control_COLUMN65" value="1326703" />
                    <input type="hidden" name="formSourceName" value="StandardForm" />
                    <input type="hidden" name="sp_exp" value="yes" />

                    <p><em>We manage subscriber name and email details in accordance with our <br />
                        <Link to="/privacy-policy">Privacy Policy</Link>
                    </em></p>

                    <Recaptcha
                        ref={recaptchaRef}
                        sitekey={clientEnv.RECAPTCHA_KEY}
                        size="invisible"
                        onChange={onVerifySuccessfully}
                    />

                    <button className="button primary inverse" type="submit">Submit</button>
                </form>
            </section>
        </main>
    );
};

export default NewsletterPreferences;
