import PropTypes from 'prop-types';
import React from 'react';

const TextContent = ({ aside, children, citation, content, quote }) => (
    <section className="content-block || text-content-block || constrain-width">
        <div className="content-primary || rich-editor-content" dangerouslySetInnerHTML={{ __html: content }}></div>
        {aside && (
            <aside className="content-aside">
                <div className="callout || rich-editor-content" dangerouslySetInnerHTML={{ __html: aside }}></div>
            </aside>
        )}
        {quote && (
            <aside className="content-aside">
                <blockquote className='rich-editor-content' dangerouslySetInnerHTML={{ __html: quote }}></blockquote>
                {citation && <cite>{citation}</cite>}
            </aside>
        )}
        {children}
    </section>
);

TextContent.propTypes = {
    aside: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    citation: PropTypes.string,
    content: PropTypes.string.isRequired,
    quote: PropTypes.string
};

export default TextContent;
