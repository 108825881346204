/* eslint-disable require-jsdoc */
import batchMiddleware from './middleware/batch';
import canUseDom from './utilities/dom';
import { createEpicMiddleware } from 'redux-observable';
import { createReduxHistoryContext } from 'redux-first-history';
import { isProd } from 'config/env';
import logger from './middleware/logger';
import preRouteMiddleware from './middleware/pre-route';
import { applyMiddleware, compose, legacy_createStore as createStore } from 'redux';
import createRootReducer, { epics } from './ducks';

let composeEnhancers, store;

if (canUseDom()) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
    composeEnhancers = compose;
}

export function configureStore(hydration, history) {
    const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
        history
    });

    const middleware = [];
    // Creating Bundled Epic
    const epicMiddleware = createEpicMiddleware();

    if (!isProd && canUseDom()) middleware.push(logger);
    middleware.push(batchMiddleware);
    middleware.push(epicMiddleware);
    middleware.push(preRouteMiddleware);
    middleware.push(routerMiddleware);

    const enhancer = composeEnhancers(applyMiddleware(...middleware));

    store = createStore(
        createRootReducer(routerReducer), // root reducer with router state
        hydration,
        enhancer
    );

    epicMiddleware.run(epics);

    return {
        reduxStore: store,
        reduxHistory: createReduxHistory(store)
    };
}

export default store;
