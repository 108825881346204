import PropTypes from 'prop-types';
import React from 'react';

class StacklaWidget extends React.Component {
    render() {
        const { hash, id, subtitle, title } = this.props;

        // All of these properties are needed for the embedded JS to work correctly
        const STACKLA_WIDGET_RESERVED_PROPERTIES = {
            className:      'stackla-widget', // This class name is used by the client side script to render the widget
            'data-alias':   'regionalfacilitiesauckland.stackla.com',
            'data-ct':      '',
            'data-hash':    hash,
            'data-id':      id,
            'data-title':   title,
            'data-ttl':     '30'
        };

        return (
            <section className="stackla-widget-block || content-block || section constrain-width">
                <h4 className="title || heading-1">{title}</h4>
                {subtitle && <p>{subtitle}</p>}
                <hr />
                <div {...STACKLA_WIDGET_RESERVED_PROPERTIES} />
            </section>
        );
    }
}

const WIDGET_TYPES = ['masonry'];

StacklaWidget.defaultProps = {
    type: WIDGET_TYPES[0]
};

StacklaWidget.propTypes = {
    hash: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    // TODO: Include type for future variants/layout potentially
    type: PropTypes.oneOf(WIDGET_TYPES).isRequired
};

export default StacklaWidget;
