import PropTypes from 'prop-types';
import React from 'react';
import VIRTUAL_TOURS_IMAGE from 'static/images/show/background-virtual-tours.jpg';

const VenueBlocks = ({ venues }) => {
    const renderVirtualTourBlock = (venue) => (
        <a className="block-virtual-tour" href={venue.tourLink} style={{ backgroundImage: `url(${VIRTUAL_TOURS_IMAGE})` }}>
            <button className="button secondary inverse || link-label">Take a Virtual Tour</button>
        </a>
    );

    const renderVenueBlock = (venue, key) => (
        <div className="block" key={key}>
            <a className="block-info" href={venue.url}>
                <h5 className="pre-heading">On at</h5>
                <h4 className="title">{venue.name}</h4>
                <p className="address">{venue.location.raw}</p>
            </a>
            <a className="block-direction" href={venue.directionsUrl} rel="noreferrer" style={{ backgroundImage: `url(${venue.map})` }} target="_blank">
                <button className="button secondary inverse || link-label">Get Directions</button>
            </a>

            {venue.tourLink && renderVirtualTourBlock(venue)}
        </div>
    );

    return (
        <section className="content-section || constrain-width">
            <div className="venue-blocks">
                {venues.map((venue, index) => renderVenueBlock(venue, index))}
            </div>
        </section>
    );
};

VenueBlocks.propTypes = {
    venues: PropTypes.array.isRequired
};

export default VenueBlocks;
