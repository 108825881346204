import Icon from './icon';
import PropTypes from 'prop-types';
import React from 'react';

const NotificationBanner = ({ closeNotification, notification, closable }) => {
    const { id, type, title, description, url, bgColor, textColor } = notification;

    const getNotificationColour = (type) => {
        switch (type) {
            case 'danger': return { backgroundColor: '#000000', color: 'white' };
            case 'cancellation': return { backgroundColor: '#E81800', color: 'white' };
            case 'discount': return { backgroundColor: '#45841F', color: 'white' };
            case 'maintenance': return { backgroundColor: '#757575', color: 'white' };
            case 'venue-change': return { backgroundColor: '#EF6C00', color: 'white' };
            default: return { backgroundColor: '#000000', color: 'white' };
        }
    };

    return (
        <div className={`notification`} key={id} style={{backgroundColor: bgColor || getNotificationColour(type).backgroundColor, color: textColor || getNotificationColour(type).color}}>
            <div className="inner || constrain-width large">
                <p className="title"><strong>{title}</strong></p>
                {url && description && <a className="description" href={url}>{description}</a>}
                {!url && description && <p className="description">{description}</p>}

                {closable && (
                    <button className="button || notification-toggle-button" onClick={() => { closeNotification(id); }}>
                        <Icon className="icon" name="close" />
                    </button>
                )}
            </div>
        </div>
    );
};

NotificationBanner.defaultProps = {
    closable: true
};

NotificationBanner.propTypes = {
    closeNotification: PropTypes.func,
    notification: PropTypes.object.isRequired,
    closable: PropTypes.bool,
};

export default NotificationBanner;
