import Page from './page';

export default class Content extends Page {
    constructor(props) {
        super(props);
    }

    /**
     * Get Above Grid Content Blocks
     *
     * @return {array} Array of content blocks, or empty array if not set
     */
    get aboveGridBlocks() {
        return this.props.above_grid_blocks || [];
    }

    /**
     * Get Content Blocks
     *
     * @return {array} Array of content blocks, or empty array if not set
     */
    get blocks() {
        return this.props.blocks || [];
    }

    /**
     * Get Hero
     *
     * @return {object} Object with desktop and mobile properties
     */
    get hero() {
        if (this.props.main_images) {
            return {
                desktop: this.props.main_images[0] || undefined,
                mobile: this.props.main_images[1] || undefined
            };
        }

        return {
            desktop: undefined,
            mobile: undefined
        };
    }

    /**
     * Get Thumbnail
     *
     * @return {object} Object with landscape (null) and square properties
     */
    get thumbnail() {
        return {
            landscape: this.props.thumbnail,
            square: this.props.thumbnail
        };
    }

    /**
     * Build up the URL
     * - Override parent method.
     *
     * @return {string} Slug URL
     */
    get url() {
        if (this.parent && this.slug) {
            return `/${this.parent}/${this.slug}`;
        }

        if (this.slug) {
            return `/${this.slug}`;
        }

        return undefined;
    }

    /**
     * Get Tile data object
     *
     * @return {object}
     */
    get tile() {
        return {
            name: this.name,
            thumbnail: this.thumbnail,
            type: 'page',
            url: this.url
        };
    }
}

Object.defineProperties(Content.prototype, {
    'aboveGridBlocks': { enumerable: true },
    'blocks':          { enumerable: true },
    'hero':            { enumerable: true },
    'thumbnail':       { enumerable: true },
    'tile':            { enumerable: true },
    'url':             { enumerable: true }
});
