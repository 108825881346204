import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const NewTileInner = ({ date, isHero, name, thumbnail, description, price, genre, type, venue }) => {
    const renderShowContent = () => (
        <div className="show-content">
            {date && <p className="date">{date}</p>}
            <h5 className="title">{name}</h5>
            {price && <p className="price">{price}</p>}
            {venue && <p className="location">{venue}</p>}
            {genre && <p className="genre">{genre}</p>}
        </div>
    );

    const renderPageContent = () => (
        <div className="page-content">
            <h4 className="title">{name}</h4>
            {description && <p className="description">{description}</p>}
        </div>
    );

    const renderVenueContent = () => (
        <div className="venue-content">
            <h6 className="title">{name}</h6>
            {description && <p className="description">{description}</p>}
        </div>
    );

    const renderNewsContent = () => (
        <div className="news-content">
            {/* TODO: Implement date with publish time feature */}
            {/* <p className="date">{date}</p> */}
            {isHero ? <h3 className="title">{name}</h3> : <h5 className="title">{name}</h5>}
            <p className="description">{description}</p>
            {isHero && <button className="button secondary">Learn more</button>}
        </div>
    );

    const thumbnailClass = classNames('thumbnail-wrapper', {
        'is-news': type === 'news'
    });

    return (
        <React.Fragment>
            <div className={thumbnailClass}>
                {type === 'news' && !isHero && <button className="button secondary">Learn more</button>}
                <img className="thumbnail desktop" src={thumbnail.landscape} alt={name} />
                <img className="thumbnail mobile" src={thumbnail.square} alt={name} />
            </div>
            <div className="content-wrapper">
                {type === 'show' && renderShowContent()}
                {type === 'page' && renderPageContent()}
                {type === 'venue' && renderVenueContent()}
                {type === 'news' && renderNewsContent()}
            </div>
        </React.Fragment>
    );
};

NewTileInner.propTypes = {
    date: PropTypes.string,
    description: PropTypes.string,
    isHero: PropTypes.bool,
    name:  PropTypes.string.isRequired,
    thumbnail:  PropTypes.object.isRequired,
    price: PropTypes.string,
    genre: PropTypes.string,
    type: PropTypes.string,
    venue: PropTypes.string
};

NewTileInner.defaultProps = {
    isHero: false
};

export default NewTileInner;
