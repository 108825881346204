import FeaturedShows from 'app/components/partials/featured-shows';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import React from 'react';

function NotFound() { // eslint-disable-line require-jsdoc
    return (
        <main className="page" role="main">
            <Helmet>
                <title>404 Page not found</title>
            </Helmet>
            <div className="content-page || error-page">
                <section className="content-section centered">
                    <div className="page-header || constrain-width small">
                        <h1 className="bordered">Whoops!</h1>
                        <p>Sorry, we have recently re-organised the site and the page you were looking for appears to have been moved, deleted or does not exist. You could <Link to="/">go straight to the homepage</Link> or try searching the site using the search button.</p>
                    </div>
                </section>
                <FeaturedShows hasLine={true} isLarge={false} />
            </div>
        </main>
    );
}

export default NotFound;
