import WomenRefugeLogo from 'static/images/logo/women-refuge-logo.png';
import React, { useEffect } from 'react';

const WomenRefuge = () => {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = 'https://staticcdn.co.nz/embed/embed.js';
        document.body.appendChild(script);

        window.onload = function() {
            // eslint-disable-next-line no-undef
            const frameName = new ds07o6pcmkorn({
                openElementId: '#shielded-logo',
                modalID: 'modal',
            });
            frameName.init();
        };

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <React.Fragment>
            <img alt="shielded" id="shielded-logo" src={WomenRefugeLogo} height="40" width="40" style={{ cursor: 'pointer', margin: '0px auto', display: 'inherit' }} />
        </React.Fragment>
    );
};

export default WomenRefuge;
