import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';
import Slider from 'react-slick';

const Review = ({ data }) => {
    const renderReviewItem = (item, index) => (
        <div className="item" key={index}>
            <div className="inner">
                <div className="stars">
                    {Array.from({ length: Number(item.stars) }, (_, k) => (
                        <Icon name="star-rating" />
                    ))}
                </div>
                <blockquote>{item.quote}</blockquote>
                {item.source && !item.source_url && <p>{item.source}</p>}
                {item.source && item.source_url && <a href={item.source_url}>{item.source}</a>}
            </div>
        </div>
    );

    const renderDots = (dots) => (
        <ul style={{ display: 'flex' }}> {dots} </ul>
    );

    const renderPaging = () => (
        <div className="dot"></div>
    );

    const settings = {
        arrows: false,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        appendDots: (dots) => renderDots(dots),
        customPaging: () => renderPaging()
    };

    return (
        <section className="content-block || review-carousel || constrain-width large">
            <Slider {...settings}>
                {data.map((item, index) => renderReviewItem(item, index))}
            </Slider>
        </section>
    );
};

Review.propTypes = {
    data: PropTypes.array.isRequired
};

export default Review;
