export default class Base {
    /**
     * Output of object (model)
     *
     * @return {object} Properties of the object (model)
     */
    toJSON() {
        let out = {};

        for (let property in this) {
            if (property !== 'toJSON') {
                out[property] = this[property];
            }
        }

        return out;
    }
}
