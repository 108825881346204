/* eslint-disable require-jsdoc */
import { connect } from 'react-redux';
import ContentPage from './content-page';
import selectPage, { pageIncludedSelector } from 'app/selectors/page';

function mapStateToProps(state) {
    return {
        page: selectPage(state, state.router.location.pathname),
        pageIncluded: pageIncludedSelector(state, state.router.location.pathname)
    };
}

function mapDispatchToProps() {
    return {};
}

const VisibleContentPage = connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { pure: true }
)(ContentPage);

export default VisibleContentPage;
