import classNames from 'classnames';
import { dataLayerPush } from 'app/utilities/gtm';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const AccordionItem = ({ defaultActive, item }) => {
    const [active, setActive] = useState(defaultActive);

    const toggleActive = () => {
        if (!active) {
            /* ↓ Tracking FAQ question expansion events */
            dataLayerPush({
                event: 'conversionTracking',
                category: 'Interaction',
                action: 'FAQ Click',
                label: item.title
            });
            /* ↑ Tracking FAQ question expansion events */
        }

        setActive(!active);
    };

    const itemClass = classNames('item', {
        'is-active': active
    });

    return (
        <div className={itemClass} onClick={toggleActive}>
            <div className="heading">
                <h4 className="title-text">{item.title}</h4>
                <Icon name="chevron" />
            </div>
            {item.information && <p className="information || rich-editor-content" dangerouslySetInnerHTML={{ __html:item.information }}></p>}
            {typeof item.content === 'string' && <div className="content || rich-editor-content" dangerouslySetInnerHTML={{ __html:item.content }}></div>}
            {typeof item.content === 'object' && (
                <div className="content">
                    {item.content}
                </div>
            )}
            <hr />
        </div>
    );
};

AccordionItem.defaultProps = {
    defaultActive: false,
};

AccordionItem.propTypes = {
    defaultActive: PropTypes.bool,
    item: PropTypes.object.isRequired
};

export default AccordionItem;
