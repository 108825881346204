/* eslint-disable require-jsdoc */
import { closeNewsletterStripAction } from 'app/ducks/newsletter-strip';
import { connect } from 'react-redux';
import NewsletterStrip from './newsletter-strip';
import { selectIsOpen } from 'app/selectors/newsletter-strip';

function mapStateToProps(state) {
    return {
        isOpen: selectIsOpen(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        closeNewsletterStrip: () => dispatch(closeNewsletterStripAction())
    };
}

const VisibleNewsletterStrip = connect(
    mapStateToProps,
    mapDispatchToProps
)(NewsletterStrip);

export default VisibleNewsletterStrip;
