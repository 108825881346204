/* eslint-disable require-jsdoc */
import { connect } from 'react-redux';
import {
    fetchVenuesAction
} from 'app/ducks/venues';
import Header from './header';
import selectStatus from 'app/selectors/status';
import { selectIsActive, selectNotifications } from 'app/selectors/notifications';
import { toggleNotificationsAction, updateNotificationAction } from 'app/ducks/notifications';

function mapStateToProps(state) {
    return {
        notifications: selectNotifications(state),
        notificationBannerIsActive: selectIsActive(state),
        status: selectStatus(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchVenues: () => dispatch(fetchVenuesAction()),
        toggleNotifications: () => dispatch(toggleNotificationsAction()),
        updateNotification: () => dispatch(updateNotificationAction())
    };
}

const VisibleHeader = connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);

export default VisibleHeader;
