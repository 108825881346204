import AccordionItem from './accordion-item';
import PricingTable from '../pricing-table';
import PropTypes from 'prop-types';
import React from 'react';

const Accordion = ({ defaultActiveIndex, items, type }) => {
    const generatePriceTable = (sections) => <PricingTable data={sections} />;

    const renderPricingBlockItem = (block) => {
        const { data: blockData } = block;

        const formattedBlockData = blockData.map((item) => ({
            title: item.title,
            information: item.content,
            content: generatePriceTable(item.sections)
        }));

        return formattedBlockData.map((item, index) => <AccordionItem key={index} item={item} defaultActive={index === defaultActiveIndex} />);
    };

    return (
        <div className="accordion">
            {type === 'default' && items.map((item, index) => <AccordionItem key={index} item={item} defaultActive={index === defaultActiveIndex} />)}
            {type === 'pricing-block' && items.map((block) => renderPricingBlockItem(block))}
        </div>
    );
};

Accordion.defaultProps = {
    defaultActiveIndex: null,
    type: 'default'
};

Accordion.propTypes = {
    defaultActiveIndex: PropTypes.number,
    items: PropTypes.array.isRequired,
    type: PropTypes.string
};

export default Accordion;
