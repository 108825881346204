import Content from  './content';
import ContentSetGridTile from './content-set-grid-tile';
import Event from  './event';
import External from './external';
import News from './news';
import Show from  './show';
import Venue from  './venue';

export default class ContentBlocks {
    constructor(data = []) {
        this.blockData = data;
    }
    /**
     * Process content-block data.
     *
     * @param  {array} blocks
     * @param  {object} models
     * @return {array}
     */
    processBlocks(blocks = [], models) {
        try {
            return blocks.map((block) => {
                if (block.type === 'contentset' || block.type === 'crosspromo') {
                    let cta = {};
                    // TODO: Review why this is needed here
                    const items = Array.from(block.data.items);
                    const rows = block.data.rows;

                    if (block.cta) {
                        cta = block.cta;
                    }

                    return new ContentSetGridTile({
                        cta: cta,
                        type: 'contentset',
                        rows: rows,
                        title: block.data.title,
                        data: items.map((entity) => {
                            // The entity is external if the bu property is present.
                            if (entity.bu && entity.bu.name !== 'Auckland Live') {
                                return new External(entity).tile;
                            }

                            entity.attributes = entity.data;

                            // set the correct Numeric id, not the elasticsearch id
                            entity.id = entity.data.id;

                            if (models.has(entity.type)) {
                                return new (models.get(entity.type))(entity).tile;
                            }
                        })
                    });
                }

                return block;
            });
        } catch (exception) {
            // TODO: Add Sentry logging here
            return [];
        }
    }

    get blocks() {
        return this.processBlocks(this.blockData || [], new Map([
            ['event', Event],
            ['show', Show],
            ['page', Content],
            ['venue', Venue],
            ['news', News]
        ]));
    }
}
