import ContentPage from './components/pages/content-page.container';
import ErrorPage from './components/pages/error/error-page';
import Footer from './components/partials/footer';
import Header from './components/partials/header.container';
import NewsletterPreferencesPage from './components/pages/newsletter-preferences';
import NewsletterThankYouPage from './components/pages/newsletter-thank-you';
import NotFoundPage from './components/pages/error/not-found-page';
import React from 'react';
import ScrollToTop from './components/partials/scroll-to-top';
import ShowSearchResultsPage from './components/pages/show-search-results';
import StyleGuidePage from './components/pages/style-guide';
import throttleUpdateWhileRouting from './utilities/throttle-update-while-routing';
import VenuesPage from './components/pages/venues';
import { Route, Routes } from 'react-router-dom';

const Router = () => (
    <div className="main">
        <ScrollToTop />
        <Header />
        <Routes>
            {/* Custom */}
            <Route path="/venues" element={<VenuesPage />} />
            <Route path="/search/events" element={<ShowSearchResultsPage />} />
            <Route path="/newsletter/preferences" element={<NewsletterPreferencesPage />} />
            <Route path="/newsletter/thank-you" element={<NewsletterThankYouPage />} />
            <Route path="/style-guide" element={<StyleGuidePage />} />
            <Route path="/error-500" element={<ErrorPage />} />
            {/* API Based Routes */}
            <Route path="/" element={<ContentPage />} />
            <Route path="/:url" element={<ContentPage />} />
            <Route path="/:url/*" element={<ContentPage />} />
            {/* Catch */}
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <Footer />
    </div>
);

export default throttleUpdateWhileRouting(Router);

