import { CARPARKS } from 'config/parking-availability';
import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

const ParkingAvailability = () => {
    const [carparksData, setCarparksData] = useState(CARPARKS.map((carpark) => ({ ...carpark, availableSpaces: null })));
    const [updatedTime, setUpdatedTime] = useState(null);

    const initFetch = async() => {
        try {
            const { data } = await fetch(ENDPOINTS.PARKING_AVAILABILITY);
            const updatedCarparksData = carparksData.map((carpark) => {
                const targetCarpark = data.find((item) => item.id === carpark.id);
                if (targetCarpark) {
                    return {
                        ...carpark,
                        availableSpaces: targetCarpark.attributes.categories[0].vacancies
                    };
                }

                return carpark;
            });
            setCarparksData(updatedCarparksData);
            setUpdatedTime(moment(data[0].attributes.updated_on).format('LT'));
        } finally {}
    };

    useEffect(() => {
        initFetch();
    }, []);

    return (
        <section className="content-block || parking-availability-block || constrain-width">
            <h4 className="title">Auckland Transport City Carpark Availability</h4>
            <div className="parking-availability">
                <div className="inner">
                    {carparksData.map(({ name, url, availableSpaces }, carparkIndex) => (
                        <div key={carparkIndex} className="carpark">
                            <div className="carpark-inner">
                                <a href={url} className="carpark-tile" target="_blank" rel="noopener noreferrer nofollow">
                                    <div className="carpark-name">{name}</div>
                                    <div className="carpark-label">Available spaces</div>
                                    <div className="carpark-available-spaces">{availableSpaces || '...'}</div>
                                    <div className="carpark-note">
                                        Click <span className="carpark-note-highlight">here</span> for more info on {name} car park
                                    </div>
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="note">
                    Last updated at <span>{updatedTime || '...'}</span>. Please note: During peak times availability can change quickly, please allow plenty of time to find a carpark.<br />
                    For all public transport and carparking information, please visit <a href="https://at.govt.nz/" target="_blank" rel="noopener noreferrer nofollow">Auckland Transport</a>.
                </div>
            </div>
        </section>
    );
};

export default ParkingAvailability;
