/* eslint-disable camelcase */
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import { formatInternalLink, formatLink } from 'app/utilities/link-helper';

const Treble = ({ isLarge, thumbnail_group: thumbnailGroup, title }) => {
    const trebleClass = classNames('content-block || treble-block || constrain-width', {
        'large': isLarge
    });

    const renderItemContent = (item) => (
        <React.Fragment>
            <h4>{item.title}</h4>
            {item.description && <p className="description">{item.description}</p>}
            <button className="button || cta">{item.cta_text}</button>
        </React.Fragment>
    );

    return (
        <section className={trebleClass}>
            <h4>{title}</h4>
            <div className="item-container">
                {thumbnailGroup.map((item, index) => {
                    const internalLink = formatInternalLink(item.url);

                    if (!internalLink) {
                        return (
                            <a className="item" href={formatLink(item.url)} key={index} style={{ backgroundImage: `url(${item.image})` }}>
                                {renderItemContent(item)}
                            </a>
                        );
                    }

                    return (
                        <Link className="item" to={internalLink} key={index} style={{ backgroundImage: `url(${item.image})` }}>
                            {renderItemContent(item)}
                        </Link>
                    );
                })}
            </div>
        </section>
    );
};

Treble.propTypes = {
    isLarge: PropTypes.bool.isRequired,
    thumbnail_group: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired
};

export default Treble;
