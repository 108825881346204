export const GENERAL_ENQUIRIES = {
    title: 'Contact us',
    tel: '09 309 2677',
    telTitle: 'Contact us via Phone',
    mail: 'online@aucklandlive.co.nz',
    mailTitle: 'Contact us via Email'
};

export const POSTAL_ADDRESS = {
    title: 'Postal address',
    address: 'Auckland Live<br>PO BOX 5749<br>Victoria Street West<br>Auckland 1142'
};

export const TICKETS_ENQUIRIES = {
    title: 'Book tickets',
    tel: '0800 111 999',
    telTitle: 'Contact us via Freephone',
};

export const SOCIAL_LINKS = [
    {
        title: 'Facebook link',
        url: 'https://facebook.com/AklLive',
        iconName: 'facebook'
    },
    {
        title: 'Instagram link',
        url: 'https://instagram.com/aucklandlive',
        iconName: 'instagram'
    },
    {
        title: 'Twitter link',
        url: 'https://twitter.com/aucklandlive',
        iconName: 'twitter'
    },
    {
        title: 'Youtube link',
        url: 'http://youtube.com/user/aucklandlive',
        iconName: 'youtube'
    }
];

