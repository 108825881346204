import Icon from './icon';
import PropTypes from 'prop-types';
import React from 'react';

const Select = ({ children, id, label, name, onChangeHanlder, onClickHanlder, options, withIcon, value }) => {
    const selectClickHanlder = (event) => {
        if (onClickHanlder) {
            event.preventDefault();
            onClickHanlder();
        }
    };

    const selectChangeHanlder = (event) => {
        if (onChangeHanlder) {
            onChangeHanlder(event);
        }
    };

    return (
        <div className="select-wrapper">
            {label && <label htmlFor={id}>{label}</label>}
            <select
                id={id}
                name={name}
                onMouseDown={selectClickHanlder}
                onChange={(event) => selectChangeHanlder(event)}
                onBlur={(event) => selectChangeHanlder(event)}
                value={value}
            >
                {options.map((item, index) => {
                    if (item.isOptGroup) {
                        return (
                            <optgroup key={index} label={item.name}>
                                {item.groupItems.map((item) => (
                                    <option className="option" disabled={item.disabled} key={item.value} value={item.value}>{item.label}</option>
                                ))}
                            </optgroup>
                        );
                    }

                    return <option className="option" disabled={item.disabled} key={index} value={item.value}>{item.label}</option>;
                })}
            </select>
            {withIcon && (
                <span className="label-icon">
                    <Icon name="chevron" />
                </span>
            )}
            {children}
        </div>
    );
};

Select.defaultProps = {
    withIcon: true
};

Select.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChangeHanlder: PropTypes.func,
    onClickHanlder: PropTypes.func,
    options: PropTypes.array.isRequired,
    value: PropTypes.string,
    withIcon: PropTypes.bool
};

export default Select;
