import Error from './error/error-page';
import { formatPageData } from 'app/utilities/format-data';
import getImageToShare from 'app/utilities/get-image-to-share';
import { getOpenGraphSlug } from 'app/utilities/url';
import Helmet from 'react-helmet';
import { isAppProd } from 'config/env';
import NotFound from './error/not-found-page';
import PropTypes from 'prop-types';
import React from 'react';
import { RESPONSE } from 'config/api';
import Template from 'app/components/templates/index';
import templateType from 'app/utilities/template-type';
import { APP_AUTHOR, APP_HOSTNAME, APP_NAME, FACEBOOK_APP_ID, FACEBOOK_URL, TWITTER_USER } from 'config/app';

const ContentPage = ({ page, pageIncluded }) => {
    // Render not error/not found page
    if (!page || page.error && page.error.status === RESPONSE.INTERNAL_SERVER_ERROR) return <Error />;
    if (page.error && page.error.status === RESPONSE.NOT_FOUND || !page.attributes) return <NotFound />;

    const { attributes, type } = page;

    const {
        is_published: isPublished,
        name: title,
        parent,
        seo_description: seoDescription,
        seo_keywords: seoKeywords,
        seo_title: seoTitle,
        slug
    } = attributes;

    const titleToUse = seoTitle || title;
    const titleToShow = titleToUse.toLowerCase() === 'home' ? APP_NAME : `${titleToUse} | ${APP_NAME}`;
    const openGraphSlug = getOpenGraphSlug(slug, parent, type);
    const image = getImageToShare(type, attributes);

    const templateData = formatPageData(page, pageIncluded || []);
    const templateTypeToUse = templateType(slug, type);

    const isNoIndex = !isAppProd || !isPublished;

    return (
        <main className="page" role="main">
            <Helmet>
                {isNoIndex && <meta name="robots" content="noindex, nofollow" />}

                {/* SEO */}
                <meta name="description" content={seoDescription} />
                <meta name="keywords" content={seoKeywords} />
                <meta name="author" content={APP_AUTHOR} />

                {/* Schema.org for Google+ */}
                <meta itemProp="name" content={titleToShow} />
                <meta itemProp="description" content={seoDescription} />
                <meta itemProp="image" content={image} />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content={TWITTER_USER} />
                <meta name="twitter:title" content={titleToShow} />
                <meta name="twitter:description" content={seoDescription} />
                <meta name="twitter:creator" content={TWITTER_USER} />
                <meta name="twitter:image:src" content={image} />

                {/* Open Graph */}
                <meta property="fb:app_id" content={FACEBOOK_APP_ID} />
                <meta property="og:locale" content="en_NZ" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={titleToShow} />
                <meta property="og:description" content={seoDescription} />
                <meta property="og:url" content={`${APP_HOSTNAME}/${openGraphSlug}`} />
                <meta property="og:site_name" content={APP_NAME} />
                <meta property="og:image" content={image} />
                <meta property="article:publisher" content={FACEBOOK_URL} />

                <title>{titleToShow}</title>
            </Helmet>
            <Template
                type={templateTypeToUse}
                data={templateData}
            />
        </main>
    );
};

ContentPage.propTypes = {
    page: PropTypes.object.isRequired,
    pageIncluded: PropTypes.array
};

export default ContentPage;
