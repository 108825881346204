export const NOTIFICATIONS = [
    {
        title: 'Roads to Go Media Stadium are extremly slippery!',
        type: 'danger',
        description: 'For more information - aucklandlive.co.nz/slippery-road',
        url: 'https://www.aucklandlive.co.nz/slippery-road',
        id: 0,
        isVisible: true,
        handleToggle: () => {} // eslint-disable-line no-empty-function
    },
    {
        title: 'Attention! 21.12.2017 Peter and The Wolf has been cancelled.',
        type: 'cancellation',
        description: 'For more information - aucklandlive.co.nz/peter-and-the-wolf',
        url: 'https://www.aucklandlive.co.nz/peter-and-the-wolf',
        id: 1,
        isVisible: true,
        handleToggle: () => {} // eslint-disable-line no-empty-function
    },
    {
        title: 'Attention! 21.12.2017 Peter and The Wolf venue has been changed to Town Hall!',
        type: 'venue-change',
        description: 'For more information - aucklandlive.co.nz/peter-and-the-wolf',
        url: 'https://www.aucklandlive.co.nz/peter-and-the-wolf',
        id: 2,
        isVisible: true,
        handleToggle: () => {} // eslint-disable-line no-empty-function
    },
    {
        title: 'Aotea centre is under scheduled maintenance work for 11.11.2017.',
        type: 'maintenance',
        description: 'For more information - aucklandlive.co.nz/aotea-centre',
        url: 'https://wwww.aucklandlive.co.nz/aotea-centre',
        id: 3,
        isVisible: true,
        handleToggle: () => {} // eslint-disable-line no-empty-function
    },
    {
        title: 'Stunner Sunday! All tickets 25% off from regular price. Limited tickets!',
        type: 'discount',
        description: 'For more information - aucklandlive.co.nz/news/20-percent-off',
        url: 'https://www.aucklandlive.co.nz/news/20-percent-off',
        id: 4,
        isVisible: true,
        handleToggle: () => {} // eslint-disable-line no-empty-function
    }
];

export const TEXT = {
    content: '<h1>Main Text Content Heading</h1><p>Override the digital divide with additional clickthroughs from DevOps.</p><p>User generated content in real-time will have multiple touchpoints for offshoring.</p><blockquote><p>Sit amet, cu habeo accusamus sea, usu verear aliquam scaevola ea. Explicari definitionem vim ut. An has officiis convenire, ea eius ponderum suavitate.</p><em>Author&rsquo;s Name &ndash; Auckland Art Gallery</em></blockquote><p>Podcasting operational change management inside of workflows to establish a framework.<p/><ul><li>Lorem Khaled Ipsum is a major key to success. They never said winning was easy. Some people can&rsquo;t handle success.</li><li>A major key, never panic. Don&rsquo;t panic, when it gets crazy and rough, don&rsquo;t panic, stay calm.</li><li>In life you have to take the trash out, if you have trash in your life, take it out, throw it away, get rid of it, major key</li><li>The weather is amazing, walk with me through the pathway of more success. Take this journey with me, Lion! Learning is cool, but knowing is better, and I know the key to success. Eliptical talk.</li></ul></p><a class="button primary" href="/#">Button Text</a>'
};

export const TEXT_ASIDE = {
    content: '<h1>Main Text Content Heading</h1><p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus.</p>',
    aside: '<p><strong>Lorem ipsum dolor sit amet</strong>, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. <em>Donec quam felis</em>.</p>'
};

export const TEXT_QUOTE = {
    content: '<h1>Main Text Content Heading</h1><p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus.</p>',
    quote: '<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.</p>',
    citation: 'Lorem ipsum dolor'
};

export const STACKLA_WIDGET = {
    hash: '5aaf0e436d4fa',
    id: '38829',
    subtitle: 'Tag your photos and posts with #BugLab to display your photos here.',
    title: '#Dotsarehot',
    type: 'masonry'
};

export const REVIEWS = [
    {
        stars: '5',
        quote: '“If you only see one performance this year, this is the one to see”',
        source: '- The New York Times',
        source_url: 'https://nytimes.com' // eslint-disable-line camelcase
    },
    {
        stars: '2',
        quote: '“A flawed yet fun and fast-paced space adventure, Solo should satisfy newcomers to the saga as well as longtime fans who check their expectations at the theater door.”',
        source: '- Rotten Tomatoes',
        source_url: '' // eslint-disable-line camelcase
    }
];
export const IMAGE_GALLERY = [
    {
        file: 'https://picsum.photos/500/?random',
        creditline: '<p>Image Gallery Slide 1 Credit Line</p>\r\n'
    },
    {
        file: 'https://picsum.photos/500/600/?random',
        creditline: '<p>Image Gallery Slide 2 Credit Line</p>\r\n'
    },
    {
        file: 'https://picsum.photos/400/500/?random',
        creditline: '<p>Image Gallery Slide 3 Credit Line</p>\r\n'
    },
    {
        file: 'https://picsum.photos/300/?random',
        creditline: '<p>Image Gallery Slide 4 Credit Line</p>\r\n'
    }
];
export const VIDEO = {
    data: {
        title: 'Video',
        credit: 'Credit',
        video_url: 'https://www.youtube.com/watch?v=RJLm9dqB8Nk', // eslint-disable-line camelcase
        theme_colour: 'red' // eslint-disable-line camelcase
    },
    type: 'video'
};
export const FILE_DOWNLOAD = {
    display_name: 'Lorem ipsum', // eslint-disable-line camelcase
    extension: 'jpeg',
    file: 'https://picsum.photos/300/?random',
    filename: 'Lorem ipsum',
    filesize: '101601'
};
export const FAQ = [
    {
        title: 'Lorem ipsum',
        content: '<p><strong>Lorem ipsum dolor sit amet</strong>, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis.</p>'
    }
];
export const PAGEBREAK_QUOTE = {
    cite: 'The New York Times Heralded',
    quote: 'If you see only one live performance this year…  NDT is the one to see'
};
export const CONTENT_SET = {
    title: '',
    data : [
        {
            date : '31 JAN - 12 DEC 2020',
            location: 'Auckland Town Hall',
            name: 'Lorem ipsum',
            price: '22',
            thumbnail: {
                landscape: 'https://picsum.photos/300/?random'
            },
            url: '/'
        },
        {
            date : '31 JAN - 12 DEC 2020',
            location: 'Auckland Town Hall',
            name: 'Lorem ipsum',
            price: '22',
            thumbnail: {
                landscape: 'https://picsum.photos/301/?random'
            },
            url: '/'
        },
        {
            date : '31 JAN - 12 DEC 2020',
            location: 'Auckland Town Hall',
            name: 'Lorem ipsum',
            price: '22',
            thumbnail: {
                landscape: 'https://picsum.photos/302/?random'
            },
            url: '/'
        },
        {
            date : '31 JAN - 12 DEC 2020',
            location: 'Auckland Town Hall',
            name: 'Lorem ipsum',
            price: '22',
            thumbnail: {
                landscape: 'https://picsum.photos/303/?random'
            },
            url: '/'
        },
        {
            date : '31 JAN - 12 DEC 2020',
            location: 'Auckland Town Hall',
            name: 'Lorem ipsum',
            price: '22',
            thumbnail: {
                landscape: 'https://picsum.photos/304/?random'
            },
            url: '/'
        }
    ]
};

export const VIRTUAL_TOUR = {
    scene: '',
    id: 'WyergVczDD-JxgHx4qGvw',
    type: 'virtualtour'
};
