import classNames from 'classnames';
import Notification from './notification';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

const NotificationBanner = ({ fetchNotifications, isActive, notifications, updateNotification }) => {
    const notificationBannerRef = useRef(null);

    const calculatePagePaddingTop = () => {
        const mainContentEle = document.querySelector('.main');
        mainContentEle.style.paddingTop = `${notificationBannerRef.current.clientHeight}px`;
    };

    useEffect(() => {
        fetchNotifications();
    }, []);

    useEffect(() => {
        calculatePagePaddingTop();
    }, [notifications, isActive]);

    const handleCloseNotification = (id) => {
        updateNotification(id);
    };

    const notificationBannerClass = classNames('notification-banner', {
        'is-active': isActive
    });

    return (
        <div className={notificationBannerClass} ref={notificationBannerRef}>
            {notifications.map((item, index) => {
                const { isVisible, pagesVisibleOn } = item;

                if (isVisible && pagesVisibleOn.length === 0) {
                    return (
                        <Notification key={index} notification={item} closeNotification={handleCloseNotification} />
                    );
                }
            })}
        </div>
    );
};

NotificationBanner.propTypes = {
    fetchNotifications: PropTypes.func.isRequired,
    isActive: PropTypes.bool.isRequired,
    notifications: PropTypes.array.isRequired,
    updateNotification: PropTypes.func.isRequired
};

export default NotificationBanner;
