import Accordion from 'app/components/partials/accordion/index';
import PropTypes from 'prop-types';
import React from 'react';

const Faq = ({ data }) => (
    <section className="content-block || faq-block || constrain-width">
        {data.length && data[0].heading !== '' && <h4 className="block-title">{data[0].heading}</h4>}
        <Accordion items={data} />
    </section>
);

Faq.propTypes = {
    data: PropTypes.array.isRequired,
};

export default Faq;
