import LoaderImage from 'static/images/loader.svg';
import React from 'react';

const Loader = () => (
    <div className="loader">
        <img src={LoaderImage} alt="Loading" />
    </div>
);

export default Loader;
