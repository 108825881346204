export default (type, attributes) => {
    let image = '',
        mobileImage = '';

    const { hero_image: heroImage, landscape_thumbnail: landscapeThumbnail, main_images: mainImages, thumbnail } = attributes;
    if (heroImage && heroImage.images && heroImage.images.mobile) {
        mobileImage = heroImage.images.mobile[0];
    }

    switch (type) {
        case 'pages':
            image = thumbnail || mainImages[0] || '';
            break;
        case 'events':
        case 'shows':
        case 'venues':
        case 'news':
            image = mobileImage || landscapeThumbnail || '';
            break;
        default:
            break;
    }

    return image;
};
