import { connect } from 'react-redux';
import { formatVenuesData } from 'app/utilities/format-data';
import Grid from 'app/components/partials/grid';
import NewsletterStrip from 'app/components/partials/newsletter-strip.container.js';
import PropTypes from 'prop-types';
import { selectVenues } from 'app/selectors/venues';
import React, { useEffect, useState } from 'react';

const Venues = ({ venues }) => {
    const [formattedVenues, setFormattedVenues] = useState([]);

    useEffect(() => {
        if (venues) {
            setFormattedVenues(formatVenuesData(venues).venues);
        }
    }, [venues]);

    return (
        <main className="page" role="main">
            <section className="content-section centered || page-header">
                <div className="constrain-width small">
                    <h1 className="bordered">Our Venues</h1>
                    <p className="description">Whether it&apos;s the ornate heritage of The Civic, the world-class acoustics of the Auckland Town Hall, the dazzling Kiri Te Kanawa Theatre auditorium, the vastness of Go Media Stadium, or the outdoor big screen in Aotea Square - Auckland Live has it all.</p>
                </div>
            </section>

            <Grid data={formattedVenues}/>

            <NewsletterStrip pageTitle="venues" />
        </main>
    );
};

const mapStateToProps = (state) => {
    return {
        venues: selectVenues(state)
    };
};

Venues.propTypes = {
    venues: PropTypes.array.isRequired
};

export default connect(mapStateToProps, {})(Venues);

