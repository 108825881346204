import DateHelper from 'app/utilities/date-helper';
import { DATE_FORMAT_MIN, DATE_FORMAT_MIN_WITH_YEAR } from 'config/app';

/**
 * Generates formatted date ranges for shows and events to be placed in the page title
 * @param  {string}  startDate     - start date
 * @param  {string}  endDate       - end date
 * @param  {boolean} showPastEvent - whether to show "Past Event"
 * @return {string}                - formatted date range
 */
export default function formatTitleDateRange(startDate, endDate, showPastEvent = true) {
    if (startDate) {
        const startDateMoment = DateHelper.getDateWithNZTimezone(startDate);
        const nowMoment = DateHelper.today;

        if (endDate) {
            const endDateMoment = DateHelper.getDateWithNZTimezone(endDate);

            // Return "Past Event" if the end date is in the past
            if (showPastEvent && endDateMoment.diff(nowMoment, 'days') < 0) {
                return 'Past Event';
            }

            // Return the formatted date (not the range) if the start and end dates are the same
            if (endDateMoment.isSame(startDateMoment, 'day')) {
                return endDateMoment.format(DATE_FORMAT_MIN_WITH_YEAR);
            }

            // Do not repeat the year if it is the same for the start and end date
            if (endDateMoment.isSame(startDateMoment, 'year')) {
                return `${startDateMoment.format(DATE_FORMAT_MIN)} - ${endDateMoment.format(DATE_FORMAT_MIN_WITH_YEAR)}`;
            }

            // Return the full date range
            return `${startDateMoment.format(DATE_FORMAT_MIN_WITH_YEAR)} - ${endDateMoment.format(DATE_FORMAT_MIN_WITH_YEAR)}`;
        }

        // Return "Past Event" if the start date is in the past and there is no end date
        if (showPastEvent && startDateMoment.diff(nowMoment, 'days') < 0) {
            return 'Past Event';
        }

        // Return the formatted start date
        return startDateMoment.format(DATE_FORMAT_MIN_WITH_YEAR);
    }

    return undefined;
}
