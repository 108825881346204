// We only hydrate custom URLs on initial page load
// There is no need for actions to update it
export const INITIAL_STATE = {};

// Actions
export const CUSTOM_URLS_INIT = 'custom-urls/INIT';

// Action Creators
export const customUrlsInitAction = (customUrls) => ({
    type: CUSTOM_URLS_INIT,
    payload: customUrls
});

// Reducers
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CUSTOM_URLS_INIT:
            return action.payload;
        default:
            return state;
    }
};
