import DateHelper from 'app/utilities/date-helper';
import moment from 'moment';

/**
 * Creates a day object
 *
 * @export
 * @param {object} date
 * @returns {object}
 */
export function createDay(date) {
    return {
        date,
        performances: []
    };
}

/**
 * Creates a week object
 *
 * @export
 * @param {object} leftDate
 * @returns {object}
 */
export function createWeek(leftDate) {
    return {
        days: [createDay(leftDate)]
    };
}

/**
 * Builds the array of weeks
 *
 * @export
 * @param {array} performances
 * @returns {array}
 */
export function buildWeeks(performances) {
    const month = DateHelper.getDateWithNZTimezone(performances[0].date.full);
    const topLeftDate = month.clone().startOf('month').startOf('isoWeek');
    const bottomRightDatePlusOneDay = month.clone().endOf('month').endOf('isoWeek').add(1, 'day');

    const weeks = [];

    let date = topLeftDate.clone().add(1, 'day'),
        week = createWeek(topLeftDate);

    while (date.isBefore(bottomRightDatePlusOneDay, 'day')) {
        if (date.isSame(week.days[0].date, 'isoWeek')) {
            week.days.push(createDay(date));
        } else {
            // Starting a new week
            weeks.push(week);
            week = createWeek(date);
        }
        date = date.clone().add(1, 'day');
    }
    weeks.push(week);

    performances.forEach((performance) => {
        const performanceDate = DateHelper.getDateWithNZTimezone(performance.date.full);
        weeks.some((week) => {
            return week.days.some((day) => {
                if (day.date.isSame(performanceDate, 'day')) {
                    day.performances.push(performance);

                    return true;
                }
            });
        });
    });

    return weeks;
}
