import { combineEpics } from 'redux-observable';
import { combineReducers } from 'redux';
import customUrls from './custom-urls';
import newsletterStrip from './newsletter-strip';
import status from './status';
import notifications, { epics as notificationsEpic } from './notifications';
import pages, { epics as pageEpics } from './page';
import venues, { epics as venuesEpic } from './venues';

export const epics = combineEpics(
    notificationsEpic,
    pageEpics,
    venuesEpic
);

const createRootReducer = (routerReducer) => combineReducers({
    router: routerReducer,
    notifications,
    pages,
    venues,
    status,
    newsletterStrip,
    customUrls,
});

export default createRootReducer;
