import React from 'react';

const NewsletterThankYou = () => (
    <main className="page" role="main">
        <section className="content-section centered || page-header || constrain-width tiny">
            <h1 className="bordered">Thank you</h1>
            <p className="subsection">
                Thank you for signing up to receive emails from Auckland Live, where you&apos;ll be kept in the loop with show and event news, happenings at our venues, ticket offers and more. You can also connect with us and stay in touch on <a href="https://www.facebook.com/AklLive/">Facebook</a>, <a href="https://www.instagram.com/aucklandlive/">Instagram</a>, <a href="https://twitter.com/AucklandLive">Twitter</a>, and <a href="https://www.youtube.com/channel/UCKOpu4C5N0yDcY8T0oxMBeQ">YouTube</a>.
            </p>
        </section>
    </main>
);

export default NewsletterThankYou;

