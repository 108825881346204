import canUseDom from './dom';

export const formatLink = (link) => {
    let processedLink = link;
    // Add logic for URLs without protocol
    if (link.startsWith('www.') || link.indexOf('://') === -1) {
        processedLink = `https://${link}`;
    }

    return processedLink;
};

export const formatInternalLink = (link) => {
    if (typeof link !== 'string') return false;

    // Return internal link
    if (link.startsWith('/') || link.startsWith('#')) {
        return link;
    }

    // If DOM is not available, we can't determine if it's internal
    if (!canUseDom()) return false;

    try {
        const processedLink = formatLink(link);
        const url = new URL(processedLink, window.location.origin);

        // Check if it's internal
        const isInternal = url.host === window.location.host;

        if (isInternal) {
            const path = url.pathname + url.search + url.hash;

            // Ensure path starts with '/'
            return path.startsWith('/') ? path : `/${path}`;
        }

        return false;
    } catch (e) {
        return false;
    }
};
