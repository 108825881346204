import ContentBlocks from 'app/components/content-blocks';
import NewsletterStrip from 'app/components/partials/newsletter-strip.container.js';
import NorificationPageSpecific from 'app/components/partials/notification-page-specific.container';
import PropTypes from 'prop-types';
import React from 'react';

const Event = ({ data }) => {
    const { blocks, event } = data;
    const { date, description, mainImage, name: title, price, venues } = event;

    const renderPrice = () => price && (
        <p className="content-detail">
            <span className="price">{price}</span>
        </p>
    );

    const renderVenues = () => venues && (
        <p className="content-detail">
            <span className="location">{venues}</span>
        </p>
    );

    return (
        <div className="content-page">
            <section className="page-banner">
                {mainImage && <img src={mainImage} alt={title} />}
            </section>
            <NorificationPageSpecific type="event" slug={event.slug} />
            <section className="content-section || constrain-width medium || centered">
                <h1 className="condensed">{title}</h1>
                <h4 className="subheading bordered">{date}</h4>
                {renderPrice()}
                {renderVenues()}
                <div className="description || rich-editor-content" dangerouslySetInnerHTML={{ __html: description }}></div>
            </section>
            <ContentBlocks data={blocks} pageTitle={title} />
            <NewsletterStrip pageTitle={title} />
        </div>
    );
};

Event.propTypes = {
    data: PropTypes.object.isRequired
};

export default Event;
