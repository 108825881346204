/* eslint-disable require-jsdoc */
import { connect } from 'react-redux';
import NotificationPageSpecific from './notification-page-specific';
import {  selectNotifications } from 'app/selectors/notifications';

function mapStateToProps(state) {
    return {
        notifications: selectNotifications(state),
    };
}

const VisibleNotificationPageSpecific = connect(
    mapStateToProps
)(NotificationPageSpecific);

export default VisibleNotificationPageSpecific;
