import Notification from './notification';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const NotificationPageSpecific = ({ notifications, slug, type }) => {
    const [notificationsToRender, setNotificationsToRender] = useState([]);

    const filterNotifications = () => {
        const filteredNotifications = notifications.filter((item) => {
            if (item.pagesVisibleOn && item.pagesVisibleOn.length !== 0) {
                const targetPagesByTypeAndSlug = item.pagesVisibleOn.filter((page) => page.type === type && page.slug === slug);

                return targetPagesByTypeAndSlug && targetPagesByTypeAndSlug.length !== 0;
            }

            return false;
        });

        setNotificationsToRender(filteredNotifications);
    };

    useEffect(() => {
        if (notifications && notifications.length !== 0) {
            filterNotifications();
        }
    }, [slug]);

    return (
        <React.Fragment>
            {notificationsToRender.map((item, index) => <Notification key={index} notification={item} closable={false} />)}
        </React.Fragment>
    );
};

NotificationPageSpecific.propTypes = {
    notifications: PropTypes.array.isRequired,
    slug: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
};

export default NotificationPageSpecific;
