import CustomDropdown from 'app/components/partials/custom-dropdown';
import { DATE_FORMAT_FULL } from 'config/app';
import DateHelper from 'app/utilities/date-helper';
import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import { formatShowData } from 'app/utilities/format-data';
import Grid from './grid';
import Loader from './loader';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { WEEK_DAYS } from 'config/week-days';
import React, { useEffect, useState } from 'react';

const INIT_EVENTS = {
    sunday: [],
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: []
};

const WhatsOn = () => {
    const [events, setEvents] = useState(INIT_EVENTS);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedDay, setSelectedDay] = useState('');
    const [weekdays, setWeekdays] = useState([]);

    const navigate = useNavigate();

    const weekdaySelectorChangeHandler = (value) => {
        setSelectedDay(weekdays.find((day) => day.value === value));
    };

    const initFetchEvents = async() => {
        const today = DateHelper.today.format('YYYY-MM-DD HH:mm:ss');
        try {
            const { data: responseData } = await fetch(`${ENDPOINTS.PERFORMANCES_COUNT}?date=${encodeURIComponent(today)}`);
            const weekDays = responseData.map(({ attributes }, index) => {
                const date = DateHelper.getDateWithNZTimezone(attributes.performance_date);
                const key = WEEK_DAYS[date.day()];
                let day = ['today', 'tomorrow'][index];

                if (index > 1) {
                    day = key.charAt(0).toUpperCase() + key.slice(1);
                }

                return {
                    label: day,
                    value: date.format(DATE_FORMAT_FULL),
                    key,
                    id: index,
                    disabled: attributes.total_performances === 0
                };
            });
            const selectedDay = weekDays.find((weekDay) => !weekDay.disabled);
            setWeekdays(weekDays);
            setSelectedDay(selectedDay);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchSelectedDayEvents = async() => {
        setIsLoading(true);
        try {
            const response = await fetch(`${ENDPOINTS.SHOW_SEARCH}?date=${encodeURIComponent(selectedDay.value)}&is_published=true`);
            const { performances } = formatShowData(response, selectedDay.value);
            performances.map((performance) => {
                const today = DateHelper.today;
                const tomorrow = moment(today).add(1, 'days');
                const date = DateHelper.getDateWithNZTimezone(performance.starts_at);
                if (today.isSame(date, 'day')) {
                    performance.date = `Today at ${date.format('HH:mm')}`;
                } else if (tomorrow.isSame(date, 'days')) {
                    performance.date = `Tomorrow at ${date.format('HH:mm')}`;
                } else {
                    const sentenceCaseDate = WEEK_DAYS[date.day()].charAt(0).toUpperCase() + WEEK_DAYS[date.day()].slice(1);
                    performance.date = `${sentenceCaseDate} at ${date.format('HH:mm')}`;
                }

                return performance;
            });

            setEvents({
                ...events,
                [selectedDay.key]: performances
            });
        } finally {
            setIsLoading(false);
        }
    };

    const viewAllBtnClickHandler = () => {
        navigate({
            pathname: '/search/events',
            search: `?date=${selectedDay ? encodeURIComponent(selectedDay.value) : ''}`
        });
    };

    const renderViewAllBtn = () => {
        let eventsCount = null;

        if (selectedDay && events[selectedDay.key] && events[selectedDay.key].length !== 0) {
            eventsCount = events[selectedDay.key].length;
        }

        return (
            <button className="button primary view-all-btn" onClick={viewAllBtnClickHandler}>
                View all{eventsCount && eventsCount !== 0 ? ` (${eventsCount})` : ''}
            </button>
        );
    };

    useEffect(() => {
        initFetchEvents();
    }, []);

    useEffect(() => {
        if (selectedDay && events[selectedDay.key].length === 0) {
            fetchSelectedDayEvents();
        }
    }, [selectedDay]);

    return (
        <section className="content-section || whats-on">
            <div className="heading || constrain-width large">
                <h4 className="title">What&apos;s happening {selectedDay && (
                    <CustomDropdown
                        options={weekdays}
                        value={selectedDay.value}
                        setValue={weekdaySelectorChangeHandler}
                    />
                )}</h4>
                {renderViewAllBtn()}
            </div>
            {isLoading && <Loader />}
            {!isLoading && selectedDay && <Grid data={events[selectedDay.key]} hasLine={true} isLarge={true} isSlickSlider={true} />}
        </section>
    );
};


export default WhatsOn;
