import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactPlayer from 'react-player';

const Hero = (props) => {
    const generateActionButton = (link, label, external = false) => {
        const buttonClass = classNames('button', 'inverse', {
            'light': props.theme !== 'yellow',
            'dark': props.theme === 'yellow'
        });

        if (external) {
            return (<a className={buttonClass} rel="noreferrer" href={link} target="_blank">{label}</a>);
        }

        return (<a className={buttonClass} href={link}>{label}</a>);
    };


    const renderVideoOrImage = () => {
        const config = {
            youtube: {
                playerVars: {
                    autoplay: 1,
                    controls: 0,
                    loop: 1,
                    showinfo: 0,
                    mute: 1,
                }
            }
        };

        return (
            <div className="hero-image-wrapper" style={{ backgroundImage: `url(${props.image.desktop})` }}>
                <ReactPlayer className="video-player" url={props.video} width="100%" height="100%" playing={true} controls={false} loop={true} config={config} />
            </div>
        );
    };

    const renderActionButtons = () => {
        if (props.isPast) {
            return false;
        }

        if (props.directionsUrl) {
            return generateActionButton(props.directionsUrl, 'Get Directions', true);
        } else if (props.hasButtonTickets && !props.isFree) {
            return generateActionButton('#tickets-times', 'Buy Tickets');
        }
    };

    const renderDate = () => props.date && (
        <p className="date">
            {props.isPast && <span className="past-label">{'Past Event'}</span>}{props.date}
        </p>
    );

    const renderPrice = () => props.price && (
        <p className="price">{props.isPast ? 'No longer available' : props.price}</p>
    );

    return (
        <section className={`content-block || hero theme-${props.theme || 'default'}`}>
            <div className="inner || constrain-width large no-padding" style={{ color: props.textColor, backgroundColor: props.textBg }}>
                <div className="hero-information">
                    {props.date && renderDate()}
                    {props.name && <h1 className="heading">{props.name}</h1>}
                    {props.price && renderPrice()}
                    {props.location && <p className="location">{props.location}</p>}
                    {props.phone && <p className="phone">{props.phone}</p>}
                    {props.genre && <h5 className="genre">{props.genre}</h5>}
                    {renderActionButtons()}
                </div>
                <div className="hero-image">
                    {renderVideoOrImage()}
                </div>
            </div>
        </section>
    );
};

Hero.propTypes = {
    hasButtonTickets: PropTypes.bool,
    hasLink: PropTypes.bool,
    directionsUrl: PropTypes.string,
    date: PropTypes.string,
    genre: PropTypes.string,
    image: PropTypes.object.isRequired,
    images: PropTypes.array,
    isFree: PropTypes.bool,
    isPast: PropTypes.bool,
    location: PropTypes.string,
    name: PropTypes.string.isRequired,
    phone: PropTypes.string,
    price: PropTypes.string,
    subtitle: PropTypes.string,
    ctaText: PropTypes.string,
    textBg: PropTypes.string,
    textColor: PropTypes.string,
    theme: PropTypes.string,
    url: PropTypes.string,
    venuesExist: PropTypes.bool,
    video: PropTypes.string,
};

Hero.defaultProps = {
    hasLink: false,
    isFree: false,
};

export default Hero;
