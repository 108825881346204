import PropTypes from 'prop-types';
import React from 'react';

const VirtualTour = ({ id, scene }) => (
    <section className="content-block">
        <div className="constrain-width || inner || virtual-tour">
            <iframe
                title="Virtual tour"
                className="player"
                src={`https://guidedtours.property3d.co.nz/tour/${id}${scene && `?scene=${scene}`}`}
                allowFullScreen="true"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                oallowfullscreen="true"
                msallowfullscreen="true"
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
            ></iframe>
        </div>
    </section>
);

VirtualTour.propTypes = {
    id: PropTypes.string.isRequired,
    scene: PropTypes.string
};

export default VirtualTour;
