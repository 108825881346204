// TODO: remove this file when rfa-js-common's implementation is used

/**
 * Generates the link for content set and cross-promo items.
 * @param {Object} args - Arguments object.
 * @param {Object} args.item - Content set item or cross-promo item.
 * @param {string} args.promoterBuName - Name of the promoter BU.
 * @returns {Object} Link object that contains external flag and href/to.
 */
export default function({ item, promoterBuName }) {
    const { type, data, bu } = item;

    // Content set items do not have the bu property.
    const promoteeBuName = bu ? bu.name : promoterBuName;

    let absoluteUrl = '';
    switch (promoteeBuName) {
        case 'Auckland Art Gallery':
            switch (type) {
                case 'contentpage':
                    absoluteUrl = data.uri;
                    break;
                case 'article':
                    absoluteUrl = `/article/${data.slug}`;
                    break;
                case 'event':
                    absoluteUrl = `/whats-on/event/${data.slug}`;
                    break;
                case 'exhibition':
                    absoluteUrl = `/whats-on/exhibition/${data.slug}`;
                    break;
                default:
            }
            break;
        case 'Auckland Live':
            switch (type) {
                case 'page':
                    if (data.slug === 'home') {
                        absoluteUrl = '';
                    } else {
                        absoluteUrl = `/${data.slug}`;
                    }
                    break;
                case 'show':
                    absoluteUrl = `/show/${data.slug}`;
                    break;
                case 'event':
                    absoluteUrl = `/event/${data.slug}`;
                    break;
                case 'venue':
                    absoluteUrl = `/venue/${data.slug}`;
                    break;
                default:
            }
            break;
        case 'Auckland Zoo':
            switch (type) {
                case 'page':
                    if (data.slug === 'home') {
                        absoluteUrl = '';
                    } else if (data.parent) {
                        absoluteUrl = `/${data.parent}/${data.slug}`;
                    } else {
                        absoluteUrl = `/${data.slug}`;
                    }
                    break;
                case 'animal':
                    absoluteUrl = `/animals/${data.slug}`;
                    break;
                case 'news':
                    absoluteUrl = `/news/${data.slug}`;
                    break;
                default:
            }
            break;
        case 'Auckland Conventions':
            switch (type) {
                case 'page':
                    if (data.slug === 'home') {
                        absoluteUrl = '';
                    } else if (data.parent) {
                        absoluteUrl = `/${data.parent}/${data.slug}`;
                    } else {
                        absoluteUrl = `/${data.slug}`;
                    }
                    break;
                case 'room':
                    absoluteUrl = `/rooms/${data.slug}`;
                    break;
                case 'venue':
                    absoluteUrl = `/venues/${data.slug}`;
                    break;
                default:
            }
            break;
        case 'Auckland Stadiums':
            switch (type) {
                case 'page':
                    if (data.slug === 'home') {
                        absoluteUrl = '';
                    } else if (data.parent) {
                        absoluteUrl = `/${data.parent}/${data.slug}`;
                    } else {
                        absoluteUrl = `/${data.slug}`;
                    }
                    break;
                default:
            }
            break;
        case 'RFA Corporate':
            switch (type) {
                case 'page':
                    if (data.slug === 'home') {
                        absoluteUrl = '';
                    } else if (data.parent) {
                        absoluteUrl = `/${data.parent}/${data.slug}`;
                    } else {
                        absoluteUrl = `/${data.slug}`;
                    }
                    break;
                default:
            }
            break;
        default:
    }

    if (bu) {
        return {
            external: true,
            href: `${bu.url}${absoluteUrl}`
        };
    }

    return {
        external: false,
        to: absoluteUrl
    };
}
