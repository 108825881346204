import Base from './base';

export default class Page extends Base {
    constructor(data = {}, included = []) {
        super();

        this.id = data.id;
        this.type = data.type;
        this.included = included;
        this.props = data.attributes || {};
    }

    /**
     * Get Description
     *
     * @return {string} Description of the entity
     */
    get description() {
        return this.props.description || '';
    }

    /**
     * Get Name
     *
     * @return {string}
     */
    get name() {
        return this.props.name;
    }

    /**
     * Get the SEO properties for this object
     *
     * @return {object}
     */
    get seo() {
        return {
            description: this.props.seo_description || '',
            keywords: this.props.seo_keywords || ''
        };
    }

    /**
     * Get Slug
     *
     * @return {string}
     */
    get slug() {
        return this.props.slug;
    }

    /**
     * Get Parent Type
     *
     * @return {string}
     */
    get parent() {
        return this.props.parent;
    }

    /**
     * Take a good guess at the url for this Model
     *
     * @return {string}
     */
    get url() {
        if (this.slug) {
            switch (this.type) {
                case 'shows':
                    return `/show/${this.slug}`;
                case 'venues':
                    return `/venue/${this.slug}`;
                case 'events':
                    return `/event/${this.slug}`;
                case 'news':
                    return `/news/${this.slug}`;
                default:
                    return `/${this.constructor.name.toLowerCase()}/${this.slug}`;
            }
        }

        return undefined;
    }

    /**
     * Get an array of instantiated related models
     *
     * @param  {string} type       model 'type' to get
     * @param  {Model} modelClass The model class to instantiate
     *
     * @return {array}
     */
    getRelationship(type, modelClass) {
        const relations = [];

        if (this.included) {
            this.included.forEach((item) => {
                if (item.type === type) {
                    relations.push(new modelClass(item).toJSON());
                }
            });
        }

        return relations;
    }
}

Object.defineProperties(Page.prototype, {
    'description': { enumerable: true },
    'name': { enumerable: true },
    'seo': { enumerable: true },
    'slug': { enumerable: true },
    'url': { enumerable: true }
});
