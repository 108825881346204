import Icon from 'app/components/partials/icon';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import Slider from 'react-slick';
import truncate from 'app/utilities/truncate';
import { formatInternalLink, formatLink }  from 'app/utilities/link-helper';
import React, { forwardRef, useEffect, useRef, useState } from 'react';

const CTA_TEXT_LIMIT = 30;
const TIMER = 5000;
const LAST_ITEM_INDEX = 6;

const HeroCarousel = forwardRef(({ data, pageTitle }, ref) => {
    const [activeItem, setActiveItem] = useState(0);
    const sliderRef = useRef(null);

    const arrowClickHandler = (type) => {
        if (type === 'prev') {
            sliderRef.current.slickPrev();
        } else {
            sliderRef.current.slickNext();
        }
    };

    const renderMediaInner = (imageSrc, imageMobileSrc, fileAlt, video) => {
        const config = {
            youtube: {
                playerVars: {
                    autoplay: 1,
                    controls: 0,
                    loop: 1,
                    showinfo: 0,
                    mute: 1,
                }
            }
        };

        return (
            <React.Fragment>
                {video && <ReactPlayer className="video-player" url={video} width="100%" height="150%" playing={true} controls={false} loop={true} config={config} />}
                <img className="image" src={imageSrc} alt={fileAlt || pageTitle} />
                <img className="image mobile" src={imageMobileSrc} alt={fileAlt || pageTitle} />
            </React.Fragment>
        );
    };

    const renderHero = (data, index) => {
        const { 'cta-text': ctaText, file: imageSrc, 'file_alt': fileAlt, 'file_mobile': imageMobileSrc, link, subtitle, 'text-color': textColor, title, video } = data;

        const formattedInternalLink = formatInternalLink(link);

        return (
            <div className="hero" key={index}>
                <div className="hero-wrapper || constrain-width large no-padding">
                    {formattedInternalLink ? (
                        <Link className="hero-media-container" to={formattedInternalLink}>
                            {renderMediaInner(imageSrc, imageMobileSrc, fileAlt, video)}
                        </Link>
                    ) : (
                        <a className="hero-media-container" href={formatLink(link)}>
                            {renderMediaInner(imageSrc, imageMobileSrc, fileAlt, video)}
                        </a>
                    )}
                    <div className="hero-banner" style={{ color: textColor }}>
                        <div className="banner-info">
                            <h3 className="title">{title}</h3>
                            <p className="sub-title">{subtitle}</p>
                        </div>
                        {formattedInternalLink ? <Link className="button" to={formattedInternalLink}>{truncate(ctaText, CTA_TEXT_LIMIT)}</Link> : <a className="button" href={formatLink(link)}>{truncate(ctaText, CTA_TEXT_LIMIT)}</a>}
                    </div>
                </div>
            </div>
        );
    };

    const renderDots = () => (
        <React.Fragment>
            {data.map((ele, index) => {
                const isEleActive = index === activeItem;

                if (index > LAST_ITEM_INDEX) {
                    return;
                }

                return (
                    <div className="dot-container" key={index}>
                        <button className={`dot${isEleActive ? ' is-active' : ''}`} onClick={() => sliderRef.current.slickGoTo(index)}>
                            {isEleActive ? (
                                <svg viewBox="0 0 200 200">
                                    <circle className="circle-timer" cx="100" cy="100" r="80" transform="rotate(-90 100 100)" />
                                </svg>
                            ) : <Icon name="circle" />}
                        </button>
                        <div className="tooltip">
                            <p>{ele.title}</p>
                        </div>
                    </div>
                );
            })}
        </React.Fragment>
    );

    const setTimerCb = () => sliderRef.current.slickNext();

    useEffect(() => {
        const timerFunc = setTimeout(setTimerCb, TIMER);

        return () => {
            clearTimeout(timerFunc);
        };
    }, [activeItem]);

    const settings = {
        arrows: false,
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: true,
        beforeChange: (current, next) => setActiveItem(next),
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    vertical: false
                }
            }
        ]
    };

    return (
        <div className="content-block || hero-carousel" ref={ref}>
            <div className="hero-carousel-container">
                <div className="hero-carousel-background">
                    <img key={activeItem} className="image" src={data[activeItem].file} alt={pageTitle}/>;
                    <div className="overlay" style={{ backgroundColor: data[activeItem]['text-bg'], opacity: 0.9 }}></div>
                </div>
                <Slider {...settings} ref={sliderRef}>
                    {data.map((hero, index) => {
                        if (index > LAST_ITEM_INDEX) {
                            return;
                        }

                        return renderHero(hero, index);
                    })}
                </Slider>
                <div className="paging-container">
                    <div className="paging" style={{ color: data[activeItem]['text-color'] || '#ffffff' }}>
                        <button className="slick-arrow slick-arrow__prev" onClick={() => arrowClickHandler('prev')}><Icon name="chevron" /></button>
                        {renderDots()}
                        <button className="slick-arrow slick-arrow__next" onClick={() => arrowClickHandler('next')}><Icon name="chevron" /></button>
                    </div>
                </div>
            </div>
        </div>
    );
});

HeroCarousel.displayName = 'HeroCarousel';

HeroCarousel.propTypes = {
    data: PropTypes.array.isRequired,
    pageTitle: PropTypes.string.isRequired
};

export default HeroCarousel;
