export const WINDOWSCROLLNAME = 'scrollPosition';
export const DIALOGSCROLLNAME = 'dialogPosition';

/**
 * remove the scrolling locaiton stored in session Storage
 * @returns {void}
*/
export function clearWindowsScrollPosition() {
    sessionStorage.removeItem(WINDOWSCROLLNAME);
}

/**
 * remove the scrolling locaiton stored in session Storage
 * @returns {void}
*/
export function clearDialogScrollPosition() {
    sessionStorage.removeItem(DIALOGSCROLLNAME);
}
