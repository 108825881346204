import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import { formatFeaturedGridData } from 'app/utilities/format-data';
import Grid from './grid';
import Loader from './loader';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const FeaturedShows = ({ hasLine, isLarge, isSlickSlider, limit, title }) => {
    const [gridData, setGridData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const initFetchFeaturedShows = async() => {
        try {
            const featuredShows = await fetch(ENDPOINTS.WHATS_ON);
            const formattedData = formatFeaturedGridData(featuredShows);
            setGridData(formattedData);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        initFetchFeaturedShows();
    }, []);

    return (
        <section className="content-section || featured-shows">
            <div className="heading || constrain-width large">
                <h4 className="title">{title}</h4>
            </div>
            {isLoading && <Loader />}
            {!isLoading && <Grid data={gridData} hasLine={Boolean(title) || hasLine} isLarge={isLarge} limit={limit} isSlickSlider={isSlickSlider} multiRows={true} />}
        </section>
    );
};

FeaturedShows.defaultProps = {
    hasLine: false,
    isLarge: true,
    isSlickSlider: true,
};

FeaturedShows.propTypes = {
    hasLine: PropTypes.bool,
    isLarge: PropTypes.bool,
    isSlickSlider: PropTypes.bool,
    limit: PropTypes.number,
    title: PropTypes.string,
};

export default FeaturedShows;
