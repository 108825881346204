import AoteaCentreImage from 'static/images/nav/aotea-centre.jpg';
import AoteaSquareImage from 'static/images/nav/aotea-square.jpg';
import BruceMasonCentreImage from 'static/images/nav/bruce-mason-centre.jpg';
import DateHelper from 'app/utilities/date-helper';
import { formatFeaturedShowData } from 'app/utilities/format-data';
import Shed10Image from 'static/images/nav/shed-10.jpg';
import TheCivicImage from 'static/images/nav/the-civic.jpg';
import TownHallImage from 'static/images/nav/town-hall.jpg';
import WhoWeAreImage from 'static/images/nav/who-we-are.png';

export const generateNavigation = (show = null) => {
    const eventSearchPath = '/search/events';

    return [
        {
            name: 'Events',
            maoriName: 'Ngā kaupapa',
            url: false,
            current: /^\/(event|show)s?/gi,
            featured: {
                title: 'Featured show',
                type: 'show',
                data: show ? [formatFeaturedShowData(show)] : null
            },
            children: [
                {
                    name: 'What\'s on today',
                    url: eventSearchPath,
                    search: `?date=${encodeURIComponent(DateHelper.todayWithFullFormat)}`
                },
                {
                    name: 'Next 7 days',
                    url: eventSearchPath,
                    search: `?date=${encodeURIComponent(DateHelper.nextSevenDays)}`
                },
                {
                    name: 'This month',
                    url: eventSearchPath,
                    search: `?date=${encodeURIComponent(DateHelper.thisMonth)}`
                },
                {
                    name: 'Free events',
                    url: '/free-events'
                },
                {
                    name: 'Family events',
                    url: '/family-events'
                },
                {
                    name: 'All events',
                    url: eventSearchPath
                }
            ]
        },
        {
            name: 'Visitor info',
            maoriName: 'Mōhiohio manuhiri',
            url: false,
            current: /^\/visitor-info/gi,
            children: [
                {
                    name: 'Plan your visit',
                    url: '/visitor-info/know-before-you-go',
                    icon: 'info',
                    description: 'Everything you need to know before attending a show or visiting our venues.'
                },
                {
                    name: 'Information for families',
                    url: '/visitor-info/information-for-parents',
                    icon: 'parent',
                    description: 'Family-friendly shows, venue amenities and general guidelines for bringing children to events.'
                },
                {
                    name: 'Eating and drinking',
                    url: '/visitor-info/eating-and-drinking',
                    icon: 'drink',
                    description: 'Where to go to relax, enjoy a bite to eat or refreshing drink in and around our venues.'
                },
                {
                    name: 'Tickets and Seats',
                    url: '/visitor-info/tickets-and-seating',
                    icon: 'tickets',
                    description: 'How to book tickets and secure the best available seats for shows and events.'
                },
                {
                    name: 'Accessibility',
                    url: '/visitor-info/accessibility',
                    icon: 'accessibility',
                    description: 'Information and support for patrons with access needs attending shows in our venues.'
                },
                {
                    name: 'Parking and transport',
                    url: '/visitor-info/parking-and-transport',
                    icon: 'parking',
                    description: 'Information on car parking availability and public transport to our venues.'
                }
            ]
        },
        {
            name: 'Our venues',
            subName: 'Other venues',
            maoriName: 'Ngā wāhi',
            url: false,
            current: /^\/venue/gi,
            featured: {
                type: 'venue',
                data: [
                    {
                        name: 'Aotea Centre | Aotea – Te Pokapū',
                        url: '/venue/aotea-centre',
                        thumbnail: {
                            landscape: AoteaCentreImage,
                            square: AoteaCentreImage
                        }
                    },
                    {
                        name: 'The Civic | Te Civic',
                        url: '/venue/the-civic',
                        thumbnail: {
                            landscape: TheCivicImage,
                            square: TheCivicImage
                        }
                    },
                    {
                        name: 'Auckland Town Hall | Hōro ā-Tāone o Tāmaki Makaurau',
                        url: '/venue/auckland-town-hall',
                        thumbnail: {
                            landscape: TownHallImage,
                            square: TownHallImage
                        }
                    },
                    {
                        name: 'Bruce Mason Centre',
                        url: '/venue/bruce-mason-centre',
                        thumbnail: {
                            landscape: BruceMasonCentreImage,
                            square: BruceMasonCentreImage
                        }
                    },
                    {
                        name: 'Aotea Square | Aotea – Te Papa Tū Wātea',
                        url: '/venue/aotea-square',
                        thumbnail: {
                            landscape: AoteaSquareImage,
                            square: AoteaSquareImage
                        }
                    },
                    {
                        name: 'Shed 10',
                        url: '/venue/shed-10-queens-wharf',
                        thumbnail: {
                            landscape: Shed10Image,
                            square: Shed10Image
                        }
                    }
                ]
            },
            children: [
                {
                    name: 'The Cloud',
                    url: '/venue/the-cloud-queens-wharf'
                },
                {
                    name: 'Viaduct Events Centre',
                    url: '/venue/viaduct-events-centre'
                },
                {
                    name: 'Go Media Stadium',
                    url: '/venue/go-media-stadium'
                },
                {
                    name: 'Western Springs Stadium',
                    url: '/venue/western-springs-stadium'
                },
                {
                    name: 'divider',
                    url: ''
                },
                {
                    name: 'Hiring our venues',
                    url: '/venue-hire'
                },
                {
                    name: 'Take a virtual tour',
                    url: 'https://guidedtours.property3d.co.nz/tour/b1kCDqeAyf-1eyCDqlCkG/AUCKLAND_LIVE?utm_source=web&utm_medium=pri_nav&utm_campaign=virtual_tour',
                    external: true
                }
            ]
        },
        {
            name: 'About us',
            maoriName: 'Mō mātou',
            url: false,
            current: /^\/about/gi,
            featured: {
                type: 'page',
                data: [
                    {
                        name: 'Who we are',
                        description: 'We bring artists and audiences together to ignite emotions and create memories that last a lifetime.',
                        thumbnail: {
                            landscape: WhoWeAreImage,
                            square: WhoWeAreImage
                        },
                        url: '/about-us/who-we-are'
                    }
                ]
            },
            children: [
                {
                    name: 'Who we are',
                    url: '/about-us/who-we-are'
                },
                {
                    name: 'Partnerships',
                    url: '/about-us/partnerships'
                },
                {
                    name: 'Resident companies & festivals',
                    url: '/about-us/our-resident-companies-and-festivals'
                },
                {
                    name: 'Family, education and youth',
                    url: '/about-us/family-education-and-youth'
                },
                {
                    name: 'Working at Auckland Live',
                    url: '/about-us/working-at-auckland-live'
                },
                {
                    name: 'Contact us',
                    url: '/about-us/contact-us'
                }
            ]
        },
        {
            name: 'News',
            maoriName: 'Kōrero',
            url: '/news'
        }
    ];
};
