import * as MOCK_DATA from 'config/mock-data';
import ContentSet from 'app/components/content-blocks/content-set';
import Faq from 'app/components/content-blocks/faq';
import FileDownload from 'app/components/content-blocks/file-download';
import Helmet from 'react-helmet';
import ImageGallery from 'app/components/content-blocks/image-gallery';
import NewsletterSignup from 'app/components/content-blocks/newsletter-signup';
import PagebreakQuote from 'app/components/content-blocks/pagebreak-quote';
import React from 'react';
import Review from 'app/components/content-blocks/review';
import TextContent from 'app/components/content-blocks/text-content';
import Video from 'app/components/content-blocks/video';
import VirtualTour from 'app/components/content-blocks/virtual-tour';

const pageMeta = {
    title: 'Style Guide',
    introduction: 'A working site style guide built to manage design and code quality control.',
    images: null
};

const StyleGuide = () => (
    <main className="page || style-guide-page" role="main">
        <Helmet>
            <title>{pageMeta.title}</title>
            <meta name="description" content={pageMeta.introduction} />
            <meta name="keywords" content="" />
            <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <div className="content-blocks">
            <section className="section || constrain-width">
                <h1 className="bordered">Style Guide</h1>
            </section>
            <section className="section || constrain-width || color-swatch">
                <h2>Colours</h2>
                <hr/>
                <ul className="colours">
                    <li className="primary"></li>
                    <li className="secondary"></li>
                    <li className="tertiary"></li>
                    <li className="quaternary"></li>
                </ul>
            </section>
            <section className="section || constrain-width || typo">
                <h2>Typography</h2>
                <hr/>
                <h1>Heading 1</h1>
                <h2>Heading 2</h2>
                <h3>Heading 3</h3>
                <h4>Heading 4</h4>
                <h5>Heading 5</h5>
                <h6>Heading 6</h6>
                <p>Ut wisi enim ad minim veniam, quis nostrud <strong>exerci tation ullamcorper suscipit</strong> Duis autem vel eum iriure dolor in hendrerit <em>in vulputate velit esse molestie</em> consequat, Nam liber tempor cum soluta nobis eleifend option congue.</p>
                <p>Nihil imperdiet doming id <a href="#" title="Link">quod mazim placerat</a> facer possim assum. Eodem modo typi, qui nunc nobis videntur parum clari, fiant sollemnes in futurum.</p>
            </section>
            <section className="section || constrain-width || buttons">
                <h2>Buttons</h2>
                <hr/>
                <button className="button primary" type="button">Button primary</button>
                <button className="button primary inverse" type="button">Button primary inverse</button>
                <button className="button secondary" type="button">Button secondary</button>
                <button className="button secondary inverse" type="button">Button secondary inverse</button>
            </section>
            <section className="section || text-content">
                <div className="constrain-width">
                    <h2>Text</h2>
                    <hr/>
                </div>
                <TextContent {...MOCK_DATA.TEXT} />
            </section>
            <section className="section || text-content-aside">
                <div className="constrain-width">
                    <h2>Text aside</h2>
                    <hr/>
                </div>
                <TextContent {...MOCK_DATA.TEXT_ASIDE} />
            </section>
            <section className="section || text-content-quote">
                <div className="constrain-width">
                    <h2>Text quote</h2>
                    <hr/>
                </div>
                <TextContent {...MOCK_DATA.TEXT_QUOTE} />
            </section>
            <section className="section || blockquote || constrain-width">
                <h2>Blockquote</h2>
                <hr/>
                <blockquote>If you see only one live performance this year… NDT is the one to see</blockquote>
                <cite>The New York Times Heralded</cite>
            </section>
            <section className="section || constrain-width">
                <h2>Callout</h2>
                <hr/>
                <div className="callout">
                    <p><strong>The Phantom of the Opera</strong></p>
                    <p><em>Photographer: Katherine Barrow</em></p>
                </div>
            </section>
            <section className="section || image-gallery">
                <div className="constrain-width">
                    <h2>Image gallery</h2>
                    <hr/>
                    <p>In the CMS, images of any dimensions and max size 100kb can be uploaded.</p>
                </div>
                <ImageGallery data={MOCK_DATA.IMAGE_GALLERY} />
            </section>
            <section className="section || content-set">
                <div className="constrain-width">
                    <h2>Content set</h2>
                    <hr/>
                </div>
                <ContentSet {...MOCK_DATA.CONTENT_SET} isLarge={false} />
            </section>
            <section className="section || pagebreak-quote">
                <div className="constrain-width">
                    <h2>Pagebreak quote</h2>
                    <hr/>
                </div>
                <PagebreakQuote {...MOCK_DATA.PAGEBREAK_QUOTE} theme="red" />
                <PagebreakQuote {...MOCK_DATA.PAGEBREAK_QUOTE} theme="blue" />
                <PagebreakQuote {...MOCK_DATA.PAGEBREAK_QUOTE} theme="green" />
                <PagebreakQuote {...MOCK_DATA.PAGEBREAK_QUOTE} theme="yellow" />
            </section>
            <section className="section || review">
                <div className="constrain-width">
                    <h2>Review</h2>
                    <hr/>
                </div>
                <Review data={MOCK_DATA.REVIEWS} />
            </section>
            <section className="section || faq">
                <div className="constrain-width">
                    <h2>FAQs</h2>
                    <hr/>
                </div>
                <Faq data={MOCK_DATA.FAQ} />
            </section>
            <section className="section || video">
                <div className="constrain-width">
                    <h2>Video</h2>
                    <hr/>
                </div>
                <Video {...MOCK_DATA.VIDEO.data} />
            </section>
            <section className="section || file-download">
                <div className="constrain-width">
                    <h2>File download</h2>
                    <hr/>
                </div>
                <FileDownload {...MOCK_DATA.FILE_DOWNLOAD} />
            </section>
            <section className="section || newsletter-signup">
                <div className="constrain-width">
                    <h2>Newsletter signup</h2>
                    <hr/>
                </div>
                <NewsletterSignup />
            </section>
            <section className="section || newsletter-signup">
                <div className="constrain-width">
                    <h2>Virtual tour</h2>
                    <hr/>
                </div>
                <VirtualTour {...MOCK_DATA.VIRTUAL_TOUR} />
            </section>
        </div>
    </main>
);

export default StyleGuide;
