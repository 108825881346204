/* eslint-disable require-jsdoc */
import { connect } from 'react-redux';
import NotificationBanner from './notification-banner';
import {
    fetchNotificationsAction,
    toggleNotificationsAction,
    updateNotificationAction
} from 'app/ducks/notifications';
import { selectIsActive, selectNotifications } from 'app/selectors/notifications';

function mapStateToProps(state) {
    return {
        notifications: selectNotifications(state),
        isActive: selectIsActive(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchNotifications: () => dispatch(fetchNotificationsAction()),
        toggleNotifications: () => dispatch(toggleNotificationsAction()),
        updateNotification: (id) => dispatch(updateNotificationAction(id))
    };
}

const VisibleNotificationBanner = connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationBanner);

export default VisibleNotificationBanner;
