import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import Loader from 'app/components/partials/loader';
import NewsletterStrip from 'app/components/partials/newsletter-strip.container.js';
import NewsModel from 'app/models/news';
import PropTypes from 'prop-types';
import Select from 'app/components/partials/select';
import Tabs from 'app/components/partials/tabs';
import Tile from 'app/components/partials/tile/index';
import React, { useEffect, useState } from 'react';

const NEWS_TABS = [];

const NEWS_CATEGORY = [];

const News = ({ data }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [articles, setArticles] = useState(null);
    const [featuredArticle, setFeaturedArticle] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(NEWS_CATEGORY[0] || null);

    const getArticles = async(includeFeatured = true, page = 1, category = null) => {
        try {
            const articlesData = await fetch(ENDPOINTS.NEWS(includeFeatured, page, category));
            if (articlesData && articlesData.featured && articlesData.featured.data && articlesData.featured.data.length !== 0) {
                setFeaturedArticle(new NewsModel({ attributes: articlesData.featured.data }));
            }

            if (articlesData && articlesData.data) {
                setArticles(articlesData.data.map((article) => new NewsModel(article)));
            }
        } catch (e) {
            if (e.status === 404) {
                setArticles([]);
            }
        }
    };

    const getCategories = async() => {
        try {
            const categoriesData = await fetch(ENDPOINTS.NEWS_CATEGORIES);
            if (categoriesData && categoriesData.data) {
                // init NEWS_TABS and NEWS_CATEGORY
                NEWS_TABS.length = 0;
                // NEWS_TABS.push({ value: 'all-news', title: 'All news' });
                NEWS_CATEGORY.length = 0;
                // NEWS_CATEGORY.push({ value: 'all-news', label: 'All news' });

                categoriesData.data.forEach((category) => {
                    NEWS_TABS.push({ value: category.id, title: category.attributes.name });
                    NEWS_CATEGORY.push({ value: category.id, label: category.attributes.name });
                });
                setSelectedCategory(NEWS_CATEGORY[0]);
            }
        } catch (e) {
            // handle error
        }
    };

    const categoryChangeHandler = (event) => {
        setSelectedCategory(NEWS_CATEGORY.filter((category) => category.value === event.target.value)[0]);
    };

    const tabChangeHandler = (tabValue) => {
        setSelectedCategory(NEWS_CATEGORY.filter((category) => category.value === tabValue)[0]);
    };

    // TODO: currently only have all news catagory
    const renderNewsPageTabPanel = () => (
        <div className="tiles-wrapper || constrain-width large">
            {articles && articles.map((article, index) => <Tile key={index} type="news" isHorizontal={true} {...article.tile} />)}
        </div>
    );

    useEffect(async() => {
        try {
            await Promise.all([getCategories(), getArticles()]);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        return selectedCategory && getArticles(false, 1, selectedCategory.value);
    }, [selectedCategory]);

    if (isLoading) return <Loader />;

    return (
        <div className="content-page || news-page">
            <div className="content-blocks">
                {featuredArticle && (
                    <div className="content-block || constrain-width large">
                        <Tile type="news" isHero={true} isHorizontal={true} {...featuredArticle.tile} />
                    </div>
                )}
                {selectedCategory && (
                    <div className="content-block || news-container">
                        <div className="category-select || form || constrain-width large">
                            <Select
                                id="news-category"
                                name="category"
                                options={NEWS_CATEGORY}
                                value={selectedCategory.value}
                                onChangeHanlder={categoryChangeHandler}
                            />
                        </div>
                        {articles && <Tabs isNew={true} tabsList={NEWS_TABS} renderTabsPanel={renderNewsPageTabPanel} tabChangeHandler={tabChangeHandler} />}
                    </div>
                )}
            </div>
            <NewsletterStrip pageTitle="News" />
        </div>
    );
};

News.propTypes = {
    data: PropTypes.object.isRequired
};

export default News;
