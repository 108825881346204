import FeaturedShows from 'app/components/partials/featured-shows';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import React from 'react';

function Error() { // eslint-disable-line require-jsdoc
    return (
        <main className="page" role="main">
            <Helmet>
                <title>Error 500</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <div className="content-page || error-page">
                <section className="content-section centered">
                    <div className="page-header || constrain-width small">
                        <h1 className="bordered">Oops!</h1>
                        <p>Sorry, we are having an issue with our server at the moment, and this page is unable to load. You could <Link to="/">go straight to the homepage</Link> or try searching the site using the search button.</p>
                    </div>
                </section>
                <FeaturedShows hasLine={true} isLarge={false} />
            </div>
        </main>
    );
}

export default Error;
