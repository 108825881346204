import Page from './page';

export default class News extends Page {
    constructor(data) {
        super(data);
    }

    get thumbnail() {
        return {
            landscape: this.props.thumbnail || '',
            square: this.props.mobile_thumbnail || ''
        };
    }

    get description() {
        return this.props.short_description || '';
    }

    get date() {
        return '';
    }

    get blocks() {
        return this.props.blocks || [];
    }

    get tile() {
        return {
            date: this.date,
            name: this.name,
            presenter: this.presenter,
            price: this.price,
            description: this.description,
            thumbnail: this.thumbnail,
            type: 'news',
            url: `/news/${this.slug}`,
        };
    }
}

Object.defineProperties(News.prototype, {
    'blocks':        { enumerable: true },
    'date':          { enumerable: true },
    'description':   { enumerable: true },
    'thumbnail':     { enumerable: true },
    'tile':          { enumerable: true }
});
