/**
 * Base Model class
 */
export default class ContentSetGridTile {
    constructor(data = {}) {
        Object.assign(this, data);

        this.data = this.data.map(function(tile) {
            return tile;
        });
    }
}
