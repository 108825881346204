import classNames from 'classnames';
import HorizontalTileInner from './horizontal-tile-inner';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import VerticleTileInner from './verticle-tile-inner';

const Tile = (props) => {
    const tileClass = classNames({
        'tile-verticle': !props.isSliderTile && !props.isHorizontal,
        'tile-slider': props.isSliderTile && !props.isHorizontal,
        'tile-horizontal': props.isHorizontal,
        'tile-page': props.type === 'page' && !props.isHorizontal,
        'has-presenter': props.company,
        'is-hero': props.isHero && props.isHorizontal
    });

    const renderTileInner = () => {
        if (props.isHorizontal) return <HorizontalTileInner {...props} />;

        return <VerticleTileInner {...props} />;
    };

    return (
        <Link to={props.url} className={tileClass}>
            {renderTileInner()}
        </Link>
    );
};

Tile.propTypes = {
    company:  PropTypes.string,
    isHero: PropTypes.bool,
    isHorizontal: PropTypes.bool,
    isSliderTile: PropTypes.bool,
    type:  PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

Tile.defaultProps = {
    isHero: false,
    isHorizontal: false,
    isSliderTile: false
};

export default Tile;
