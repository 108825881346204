export const optionGroupsConfig = [
    {
        options: [
            {
                value: '3553109',
                label: 'New On Sale',
                description: 'Events that have recently gone on sale or presale'
            },
            {
                value: '3553115',
                label: 'Weekly',
                description: 'Events coming up in the next week'
            }
        ]
    },
    {
        heading: 'Genres',
        options: [
            {
                value: '3967522',
                label: 'Cabaret'
            },
            {
                value: '3552871',
                label: 'Classical Music',
                description: 'Orchestral, Choral'
            },
            {
                value: '3552855',
                label: 'Comedy'
            },
            {
                value: '4457971',
                label: 'Cultural'
            },
            {
                value: '3552837',
                label: 'Dance',
                description: 'Ballet, Contemporary'
            },
            {
                value: '6385298',
                label: 'Dance Party'
            },
            {
                value: '6385307',
                label: 'Digital/Hybrid/Virtual '
            },
            {
                value: '3552868',
                label: 'Family & Kids'
            },
            {
                value: '6385309',
                label: 'Festival'
            },
            {
                value: '3552863',
                label: 'Film'
            },
            {
                value: '6385303',
                label: 'Food & Drink'
            },
            {
                value: '3552875',
                label: 'Free Events'
            },
            {
                value: '3552862',
                label: 'Fringe',
            },
            {
                value: '4441118',
                label: 'Kapa Haka',
            },
            {
                value: '3552859',
                label: 'Music & Gigs',
            },
            {
                value: '3552848',
                label: 'Musical'
            },
            {
                value: '3552841',
                label: 'Opera'
            },
            {
                value: '6385287',
                label: 'Special Events'
            },
            {
                value: '3552882',
                label: 'Talks & Ideas'
            },
            {
                value: '3552844',
                label: 'Theatre'
            },
            {
                value: '3552851',
                label: 'Visual Arts'
            }
        ]
    },
    {
        heading: 'Groups',
        options: [
            {
                value: '3967518',
                label: 'Education',
                description: 'Schools and Tertiary'
            },
            {
                value: '3553107',
                label: 'Groups Offers'
            },
            {
                value: '3552886',
                label: 'Student Offers',
            },
        ]
    },
    {
        heading: 'Accessibility',
        options: [
            {
                value: '3553112',
                label: 'Accessible - Sign Language Interpreted'
            },
            {
                value: '3663906',
                label: 'Accessible - Audio Described'
            },
            {
                value: '6385571',
                label: 'Accessible - Neurodiverse'
            },
        ],
    }
];
