import { APP_NAME } from 'config/app';
import AUCKLAND_LIVE_LOGO_HORIZONTAL from 'static/images/logo/horizontal-colour.svg';
import AUCKLAND_LIVE_LOGO_VERTICAL from 'static/images/logo/vertical-colour.svg';
import AUCKLAND_UNLIMITED_LOGO from 'static/images/rfa/auckland-unlimited-logo-black.svg';
import Icon from './icon';
import { Link } from 'react-router-dom';
import React from 'react';
import WomenRefuge from './women-refuge';
import { GENERAL_ENQUIRIES, POSTAL_ADDRESS, SOCIAL_LINKS, TICKETS_ENQUIRIES } from 'config/footer';
import { PARTNERS, SPONSORS } from 'config/residents';

const Footer = () => {
    const copyrightYear = new Date().getFullYear();

    const renderPartnersAndSponsors = (type = 'primary', title, items) => (
        <section className={`partners ${type}`}>
            <div className="constrain-width">
                <h6 className="heading">{title}</h6>
                <div className="logo-list">
                    {items.map((item, index) => (
                        <div className="item" key={index}>
                            <a className={`logo ${item.isExtraWide ? 'extra-wide' : ''}`} href={item.url} title={item.name}>
                                <img src={item.logo} alt={item.alt} />
                            </a>
                            {item.description && <h6 className="logo-description">{item.description}</h6>}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );

    const renderContactInformation = (item, type) => (
        <div className={`block ${type}`}>
            <div className="content">
                <h6 className="heading">{item.title}</h6>
                {item.tel && <p className="tel"><a href={`tel:${item.tel}`} title={item.telTitle || ''}>{item.tel}</a></p>}
                {item.mail && <p className="mail"><a href={`mailto:${item.mail}`} title={item.mailTitle || ''}>{item.mail}</a></p>}
                {item.address && <p className='rich-editor-content' dangerouslySetInnerHTML={{ __html: item.address }}></p>}
            </div>
        </div>
    );

    return (
        <footer className="footer-wrapper">
            <section className="contact-information">
                <div className="blocks || constrain-width">
                    {renderContactInformation(GENERAL_ENQUIRIES, 'general-enquiries')}
                    {renderContactInformation(POSTAL_ADDRESS, 'postal-address')}
                    {renderContactInformation(TICKETS_ENQUIRIES, 'tickets-enquiries')}
                </div>
            </section>
            {renderPartnersAndSponsors('primary', 'Our partner', PARTNERS)}
            {renderPartnersAndSponsors('secondary', 'Resident companies & festivals', SPONSORS)}
            <section className="page-information">
                <div className="blocks || constrain-width">
                    <div className="block logo rfa-logo">
                        <a href="https://www.aucklandunlimited.com/" title="View the Tātaki Auckland Unlimited website">
                            <img src={AUCKLAND_UNLIMITED_LOGO} alt="Tātaki Auckland Unlimited" />
                        </a>
                    </div>
                    <div className="block social-links">
                        <h6 className="heading">Be part of it</h6>
                        <div className="social-icons">
                            {SOCIAL_LINKS.map((item, index) => (
                                <a className="link" key={index} target="_blank" rel="noreferrer" href={item.url} title={item.title}>
                                    <Icon name={item.iconName} />
                                </a>
                            ))}
                        </div>
                    </div>
                    <div className="block logo auckland-live-logo">
                        <img className="desktop-logo" src={AUCKLAND_LIVE_LOGO_HORIZONTAL} alt="Auckland Live" />
                        <img className="mobile-logo" src={AUCKLAND_LIVE_LOGO_VERTICAL} alt="Auckland Live" />
                    </div>
                </div>
            </section>
            <p className="copy-rights fine-print">
                <span className="copyright">&copy; {copyrightYear} {APP_NAME}</span>
                <span className="privacy"><Link to="/privacy-policy">Privacy Policy</Link></span>
                <span><Link to="/cctv-privacy-notice">CCTV Privacy Policy</Link></span>
                <span><Link to="/conditions-of-entry">Conditions of Entry</Link></span>
            </p>
            <WomenRefuge />
        </footer>
    );
};

export default Footer;
