import DateHelper from 'app/utilities/date-helper';
import formatTitleDateRange from 'app/utilities/format-title-date-range';
import numeral from 'numeral';
import Page from './page';
import { PRICE_FORMAT } from 'config/app';

export default class Event extends Page {
    constructor(data) {
        super(data);
    }

    /**
     * Get Content Blocks
     *
     * @return {array}
     */
    get blocks() {
        return this.props.blocks || [];
    }

    /**
     * Get Date
     *
     * @return {string}
     */
    get date() {
        return formatTitleDateRange(this.props.start_date, this.props.end_date, false);
    }

    /**
     * Get Title Date
     *
     * @return {string}
     */
    get titleDate() {
        return formatTitleDateRange(this.props.start_date, this.props.end_date);
    }

    /**
     * Get Main Image
     *
     * @return {string}
     */
    get mainImage() {
        if (this.props.main_images) {
            return this.props.main_images[0] || undefined;
        }

        return undefined;
    }

    /**
     * Get Hero Image
     *
     * @return {object}
     */
    get heroImage() {
        let desktop = '',
            mobile = '';

        if (this.props.hero_image && this.props.hero_image.images) {
            if (this.props.hero_image.images.desktop) {
                desktop = this.props.hero_image.images.desktop[0];
            }

            if (this.props.hero_image.images.mobile) {
                mobile = this.props.hero_image.images.mobile[0];
            }
        }

        return {
            desktop: desktop,
            mobile: mobile
        };
    }

    /**
     * Get Hero Images
     *
     * @return {array}
     */
    get heroImages() {
        if (this.props.hero_image && this.props.hero_image.images) {
            return this.props.hero_image.images.desktop;
        }

        return [];
    }

    /**
     * Get Hero Theme
     *
     * @return {string}
     */
    get heroTheme() {
        if (this.props.hero_image && this.props.hero_image.theme) {
            return this.props.hero_image.theme;
        }

        return 'blue';
    }

    /**
     * Get Featured Order
     *
     * @return {number}
     */
    get featuredOrder() {
        return this.props.featured_order;
    }

    /**
     * Get Past (Y/N)
     *
     * @return {boolean}
     */
    get isPast() {
        const completion = this.props.end_date;
        const today = DateHelper.today;

        return today.isAfter(completion, 'day');
    }

    /**
     * Get Page Title
     *
     * @return {string}
     */
    get pageTitle() {
        const name = this.name;
        if (name) {
            const titleDate = this.titleDate;
            if (titleDate) {
                return `${name} | ${titleDate}`;
            }

            return name;
        }

        return undefined;
    }

    /**
     * Get Price (range)
     * - False if no start price is provided.
     * - 'Free' if no start price is provided.
     *
     * @return {string}
     */
    get price() {
        let end,
            start;

        start = numeral(this.props.start_price).format(PRICE_FORMAT);

        // If no start price, set to false
        if (this.props.start_price === '') {
            return false;
        }

        // If no start price, set to Free
        if (!this.props.start_price) {
            start = 'Free';
        }

        if (!this.props.end_price || this.props.start_price === this.props.end_price) {
            return `${start}`;
        }

        end = numeral(this.props.end_price).format(PRICE_FORMAT);

        return `${start} - ${end}`;
    }

    /**
     * Get Thumbnail
     *
     * @return {object}
     */
    get thumbnail() {
        return {
            landscape: this.props.landscape_thumbnail || '',
            square: this.props.square_thumbnail || ''
        };
    }

    /**
     * Get Venues
     *
     * @return {string}
     */
    get venues() {
        return this.props.venues;
    }

    /**
     * Get Hero data object
     *
     * @return {object}
     */
    get hero() {
        return {
            date: this.date,
            genre: '', // No genre by default. Events don't support genres.
            image: this.heroImage,
            images: this.heroImages,
            isFree: false, // Nothing is free by default. Events don't support the "is_free" flag.
            isPast: this.isPast,
            location: this.venues,
            name: this.name,
            price: this.price,
            theme: this.heroTheme,
            url: this.url,
            video: '' // No video by default. Events don't support videos.
        };
    }

    /**
     * Get Tile data object
     *
     * @return {object}
     */
    get tile() {
        return {
            url: `/event/${this.slug}`,
            date: this.date,
            name: this.name,
            price: this.price,
            location: this.venues,
            thumbnail: this.thumbnail,
            type: 'event'
        };
    }
}

Object.defineProperties(Event.prototype, {
    'blocks':        { enumerable: true },
    'date':          { enumerable: true },
    'featuredOrder': { enumerable: true },
    'hero':          { enumerable: true },
    'mainImage':     { enumerable: true },
    'heroImage':     { enumerable: true },
    'heroImages':    { enumerable: true },
    'heroTheme':     { enumerable: true },
    'isPast':        { enumerable: true },
    'pageTitle':     { enumerable: true },
    'price':         { enumerable: true },
    'thumbnail':     { enumerable: true },
    'tile':          { enumerable: true },
    'titleDate':     { enumerable: true },
    'venues':        { enumerable: true }
});
