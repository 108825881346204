import { clientEnv } from 'config/env';
import PropTypes from 'prop-types';
import React from 'react';
import { compose, withProps } from 'recompose';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';

const defaultZoom = 14;

const Map = compose(
    withProps({
        googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${clientEnv.GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
        loadingElement: <div style={{ height: '100%' }} />,
        containerElement: <div style={{ height: '400px' }} />,
        mapElement: <div style={{ height: '100%' }} />,
    }),
    withScriptjs,
    withGoogleMap
)((props) =>
    <GoogleMap
        defaultZoom={defaultZoom}
        defaultCenter={{ lat: props.lat, lng: props.lng }}
    >
        <Marker position={{ lat: props.lat, lng: props.lng }} />
        {props.showMarker && <Marker position={{ lat: props.lat, lng: props.lng }} />}
    </GoogleMap>
);

Map.propTypes = {
    directions: PropTypes.object,
    groundOverlay: PropTypes.any,
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    options: PropTypes.object,
    showMarker: PropTypes.bool,
    zoom: PropTypes.number
};

export default Map;
