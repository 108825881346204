import env from './env';

export const { API_BASE_URL, CLIENT_ID, CLIENT_SECRET } = env;

const APP_ID = 'live';
const LIMIT_PER_PAGE = 1000;

export const ENDPOINTS = {
    EVENT: (slug) => `${API_BASE_URL}/${APP_ID}/events?slug=${slug}`,
    EVENTS_ALL: `${API_BASE_URL}/${APP_ID}/events?limit=${LIMIT_PER_PAGE}`,
    FEATURED_EVENTS: `${API_BASE_URL}/${APP_ID}/events?is_featured=true&is_published=true&exclude_past_events=true`,
    FEATURED_SHOWS: `${API_BASE_URL}/${APP_ID}/shows?is_featured=true&is_published=true&exclude_past_events=true`,
    NEWS: (includeFeatured = true, page = 1, category = null) => {
        // Always exclude featured articles from the main list
        let requestURL = `${API_BASE_URL}/live/news?is_published=true&featured=false`;

        // Featured articles are returned in separate field
        if (includeFeatured) requestURL += '&include=featured';

        if (category) requestURL += `&category=${encodeURIComponent(category)}`;
        if (page) requestURL += `&page=${encodeURIComponent(page)}`;

        return requestURL;
    },
    NEWS_PAGE: (slug) => `${API_BASE_URL}/${APP_ID}/news?slug=${slug}`,
    NEWS_CATEGORIES: `${API_BASE_URL}/live/news-categories`,
    NOTIFICATIONS: `${API_BASE_URL}/${APP_ID}/notifications`,
    PAGES: (slug, parent = '') => `${API_BASE_URL}/${APP_ID}/pages?slug=${encodeURIComponent(slug)}&parent=${encodeURIComponent(parent)}`,
    PAGES_ALL: `${API_BASE_URL}/${APP_ID}/pages?limit=${LIMIT_PER_PAGE}`,
    PARKING_AVAILABILITY: `${API_BASE_URL}/${APP_ID}/parking-availability`,
    PERFORMANCES: `${API_BASE_URL}/${APP_ID}/performances`,
    PERFORMANCES_COUNT: `${API_BASE_URL}/${APP_ID}/performances/count`,
    SEARCH: `${API_BASE_URL}/${APP_ID}/search`,
    SHOW_SEARCH: `${API_BASE_URL}/${APP_ID}/event-search`,
    SHOWS: (slug) => `${API_BASE_URL}/${APP_ID}/shows?slug=${slug}&include=venues`,
    SHOWS_ALL: `${API_BASE_URL}/${APP_ID}/shows`,
    TOKEN: `${API_BASE_URL}/oauth/token`,
    VENUES: (slug) => `${API_BASE_URL}/${APP_ID}/venues?slug=${slug}&include=shows`,
    VENUES_ALL: `${API_BASE_URL}/${APP_ID}/venues?limit=${LIMIT_PER_PAGE}`,
    VENUES_FILTERED: `${API_BASE_URL}/${APP_ID}/venues?auckland_live_venue=true`,
    WHATS_ON: `${API_BASE_URL}/${APP_ID}/shows/whats-on?is_published=true`,
    FEATURED_SHOW_ON_NAVIGATION: `${API_BASE_URL}/${APP_ID}/shows/search?is_featured_on_navigation=true&is_published=true`,
    SITEMAP: `${API_BASE_URL}/${APP_ID}/sitemap`,
    CUSTOM_URLS: `${API_BASE_URL}/${APP_ID}/custom-urls`,
};

export const RESPONSE = {
    OK: 200,
    BAD_REQUEST: 400,
    NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500
};

export const TIMEOUT = 0;

export const STATUS_CODE = {
    'OK': {
        status: 200,
        content: 'Ok'
    },
    'MOVED_PERMANENTLY': {
        status: 301,
        content: 'Moved Permanently'
    },
    'FOUND': {
        status: 302,
        content: 'Found'
    },
    'BAD_REQUEST': {
        status: 400,
        content: 'Bad Request'
    },
    'UNAUTHORIZED': {
        status: 401,
        content: 'Unauthorized'
    },
    'FORBIDDEN': {
        status: 403,
        content: 'Forbidden'
    },
    'NOT_FOUND': {
        status: 404,
        content: 'Not Found'
    },
    'INTERNAL_SERVER_ERROR': {
        status: 500,
        content: 'Internal Server Error'
    }
};
