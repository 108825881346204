import PropTypes from 'prop-types';
import { DIALOGSCROLLNAME, WINDOWSCROLLNAME } from '../../utilities/scrolling-helper';
import React, { useEffect, useState } from 'react';

/* Listens on scrolling position change.
 Scrolling position stored in session storage.
 Start recording positon using flag. We need to record position when the Grid is finished loading.
 IsDialog flag set to true to use native js to find parent element
 scrollRef is used when we need to use full screen dialog
 For clear: show search will clean location when FIND button clicked and menu item clicked
            dialog search will clean location when X button clicked
*/
const RestoreScrollLocation = ({ flag = false, isDialog = false, scrollRef = null }) => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [element, setElement] = useState(null);
    const [storeName, setStoreName] = useState(WINDOWSCROLLNAME);
    useEffect(() => {
        setElement(isDialog ? scrollRef.current : window);
        setStoreName(isDialog ? DIALOGSCROLLNAME : WINDOWSCROLLNAME);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(isDialog ? element.scrollTop : element.scrollY);
        };
        if (element) {
            element.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (element) {
                element.removeEventListener('scroll', handleScroll);
            }
        };
    }, [element]);

    useEffect(() => {
        if (flag) {
            const savedPosition = sessionStorage.getItem(storeName);
            if (savedPosition) {
                element.scrollTo({ top: parseInt(savedPosition), behavior: 'smooth' });
            }
        }
    }, [flag]);

    useEffect(() => {
        if (flag && scrollPosition > 0) {
            sessionStorage.setItem(storeName, scrollPosition);
        }
    }, [scrollPosition]);

    return <></>;
};

RestoreScrollLocation.propTypes = {
    flag: PropTypes.bool,
    isDialog: PropTypes.bool,
    scrollRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.object })
    ]),
};

export default RestoreScrollLocation;
