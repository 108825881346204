/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import React from 'react';

const BYTE_BASE = 1024;
const DECIMAL_PLACES = 1;
const KILO_BYTE = 1024;
const MEGA_BYTE = KILO_BYTE * BYTE_BASE;
const GIGA_BYTE = MEGA_BYTE * BYTE_BASE;

const FileDownload = ({ display_name, extension, file, filename, filesize }) => {
    const getFilesSize = () => {
        const bytes = filesize;
        const extensionText = `${extension.toUpperCase()} file`;

        if ((bytes >= KILO_BYTE) && (bytes < MEGA_BYTE)) {
            return `${(bytes / KILO_BYTE).toFixed(DECIMAL_PLACES)} KB ${extensionText}`;
        } else if ((bytes >= MEGA_BYTE) && (bytes < GIGA_BYTE)) {
            return `${(bytes / MEGA_BYTE).toFixed(DECIMAL_PLACES)} MB ${extensionText}`;
        }

        return `${bytes} B ${extensionText}`;
    };

    return (
        <section className="content-block || file-download-block || constrain-width">
            <div className="file-attachment">
                <div className="information">
                    <p className="filename">{display_name || filename}</p>
                    <p className="filesize">{getFilesSize()}</p>
                </div>
                <a className="primary button inverse" href={file} download={filename} target="_blank" rel="noreferrer">Download</a>
            </div>
        </section>
    );
};

FileDownload.propTypes = {
    display_name: PropTypes.string,
    extension: PropTypes.string.isRequired,
    file: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
    filesize: PropTypes.string.isRequired
};

export default FileDownload;
