import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import { mergeMap  } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';

export const INITIAL_STATE = {
    venues: []
};

// Actions
export const FETCH_VENUES = 'rfa-aucklandlive-website/venues/FETCH_VENUES';
export const FETCH_VENUES_SUCCESS = 'rfa-aucklandlive-website/venues/FETCH_VENUES_SUCCESS';
export const FETCH_VENUES_FAIL = 'rfa-aucklandlive-website/venues/FETCH_VENUES_FAIL';

// Actions Creator
export const fetchVenuesAction = () => ({
    type: FETCH_VENUES
});

export const fetchVenuesFailAction = (venues) => ({
    type: FETCH_VENUES_FAIL,
    payload: {
        venues
    }
});

export const fetchVenuesSuccessAction = (venues) => ({
    type: FETCH_VENUES_SUCCESS,
    payload: {
        venues
    }
});

// Reducers
export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_VENUES:
            return state;
        case FETCH_VENUES_FAIL:
        case FETCH_VENUES_SUCCESS:
            return {
                ...state,
                venues: payload.venues
            };
        default:
            return state;
    }
};

// Epic creator
/**
 * Fetch Venues Epic
 * @param  {func} endpoint                        - Venues endpoint
 * @param  {func} fetchVenuesSuccessAction - Success action creator
 * @param  {func} fetchVenuesFailAction    - Fail action creator
 * @return {func}
 */
export const createVenuesEpic = (
    endpoint,
    fetchVenuesSuccessAction,
    fetchVenuesFailAction
) => {
    return (action$) => action$.pipe(
        ofType(FETCH_VENUES),
        mergeMap(() => {
            return (
                fetch(endpoint)
                    .then((response) => {
                        if (response.data && response.data.length !== 0) {
                            return fetchVenuesSuccessAction(response.data);
                        }
                    })
                    .catch(() => fetchVenuesFailAction([]))
            );
        })
    );
};

// Epic
const venuesEpic = createVenuesEpic(
    ENDPOINTS.VENUES_FILTERED,
    fetchVenuesSuccessAction,
    fetchVenuesFailAction
);

export const epics = combineEpics(
    venuesEpic,
);
