import classNames from 'classnames';
import { generateNavigation } from 'app/utilities/generate-navigation';
import Icon from './icon';
import PropTypes from 'prop-types';
import Tile from 'app/components/partials/tile/index';
import { NavLink, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

const Navigation = ({ activeNavItem, featuredShow, isMobileNavActive, mobileNavToggleHandler, navItemClickHandler }) => {
    const [generatedNav, setGeneratedNav] = useState(
        generateNavigation(null)
    );

    const location = useLocation();

    const isSubNavActive = (subItem) => {
        if (location.search || subItem.search) {
            return subItem.url === location.pathname && subItem.search === location.search;
        }

        return subItem.url === location.pathname;
    };

    const mobileSubItemClickHandler = () => {
        mobileNavToggleHandler();
    };

    const renderNavItemName = (name, maoriName) => (
        <div className="item-name-wrapper">
            <p className="item-name-maori">{maoriName}</p>
            <p className="item-name">{name}</p>
        </div>
    );

    const renderFeaturedSection = (title, type, data) => (
        <div className="featured-container">
            {title && <h5 className="title">{title}</h5>}
            <div className="tiles">
                {data.map((item, index) => <Tile key={index} type={type} isHorizontal={true} isHero={data.length === 1} {...item} />)}
            </div>
        </div>
    );

    const renderSubNavChildren = (item, index) => {
        if (item.name === 'divider') {
            return <hr key={index} />;
        }

        if (item.external) {
            return (
                <button
                    className="sub-item"
                    key={index}
                    onClick={() => {
                        window.open(item.url);
                        navItemClickHandler(null);
                    }}
                >
                    <h6 className="name">{item.name}</h6>
                    {item.description && <p className="description">{item.description}</p>}
                    {item.icon && <Icon name={item.icon} />}
                </button>
            );
        }

        return (
            <NavLink
                className={classNames('sub-item', { 'is-active': isSubNavActive(item) })}
                key={index}
                to={`${item.url}${item.search || ''}`}
            >
                <h6 className="name">{item.name}</h6>
                {item.description && <p className="description">{item.description}</p>}
                {item.icon && <Icon name={item.icon} />}
            </NavLink>
        );
    };

    const renderDesktopSubNav = () => {
        const { name, featured, children, subName } = activeNavItem;
        const subWrapperClass = classNames('sub-nav-container', {
            'has-featured': featured
        });

        return (
            <div className={subWrapperClass}>
                <div className="inner">
                    {featured && featured.data && renderFeaturedSection(featured.title, featured.type, featured.data)}
                    <div className="sub-item-container">
                        {featured && <h5 className="title">{subName || name}</h5>}
                        {children.map((item, index) => renderSubNavChildren(item, index))}
                    </div>
                </div>
            </div>
        );
    };

    const renderDesktopNav = () => (
        <div className="navigation-desktop">
            {generatedNav.map((navItem, index) => {
                const navItemClass = classNames('nav-item', {
                    'is-active': activeNavItem && activeNavItem.name === navItem.name
                });

                if (navItem.url) {
                    return (
                        <NavLink
                            className={({ isActive }) => classNames('nav-item', { 'is-active': isActive })}
                            key={index}
                            to={navItem.url}
                        >
                            {renderNavItemName(navItem.name, navItem.maoriName)}
                        </NavLink>
                    );
                }

                return (
                    <button className={navItemClass} key={index} onClick={() => navItemClickHandler(navItem)}>
                        {renderNavItemName(navItem.name, navItem.maoriName)}
                    </button>
                );
            })}
            { activeNavItem && activeNavItem.children && renderDesktopSubNav()}
        </div>
    );

    // Mobile view
    const renderMobileSubNav = () => {
        const { name, maoriName, children, featured } = activeNavItem;

        return (
            <div className="navigation-mobile has-active">
                <button className="button no-border action-button" onClick={() => navItemClickHandler(null)}>
                    <Icon className="icon || left-arrow" name="chevron" /> Back
                </button>
                {renderNavItemName(name, maoriName)}
                {featured && featured.data && (
                    <div className="tiles">
                        {featured.data.map((item, index) => <Tile key={index} type={activeNavItem.featured.type} isHorizontal={true} onClick={mobileSubItemClickHandler} {...item} />)}
                    </div>
                )}
                <div className="sub-nav-container">
                    {children && children.map((item, index) => {
                        if (item.name === 'divider') {
                            return <hr key={index} />;
                        }

                        if (item.external) {
                            return (
                                <button className="sub-item" key={index} onClick={() => {
                                    window.open(item.url);
                                    mobileSubItemClickHandler();
                                }}>
                                    <p className="name">{item.name}</p>
                                    {item.description && <p className="description">{item.description}</p>}
                                    {item.icon && <Icon name={item.icon} />}
                                </button>
                            );
                        }

                        return (
                            <NavLink className="sub-item" key={index} to={item.url}>
                                <p className="name">{item.name}</p>
                                {item.description && <p className="description">{item.description}</p>}
                                {item.icon && <Icon name={item.icon} />}
                            </NavLink>
                        );
                    })}
                </div>
            </div>
        );
    };

    const renderMobileNavItem = () => (
        <React.Fragment>
            {generatedNav.map((navItem, index) => {
                if (navItem.url) {
                    return (
                        <NavLink className="nav-item" key={index} to={navItem.url}>
                            {renderNavItemName(navItem.name, navItem.maoriName)}
                            <Icon name="chevron" />
                        </NavLink>
                    );
                }

                return (
                    <button className="nav-item" key={index} onClick={() => navItemClickHandler(navItem)}>
                        {renderNavItemName(navItem.name, navItem.maoriName)}
                        <Icon name="chevron" />
                    </button>
                );
            })}
        </React.Fragment>
    );

    const renderMobileNav = () => (
        <div className="navigation-mobile">
            <button className="button no-border action-button" onClick={mobileNavToggleHandler}>
                <Icon className="icon" name="close" />
            </button>
            {!activeNavItem && renderMobileNavItem()}
            {activeNavItem && renderMobileSubNav()}
        </div>
    );

    useEffect(() => {
        setGeneratedNav(generateNavigation(featuredShow));
    }, [featuredShow]);

    return (
        <React.Fragment>
            {generatedNav && renderDesktopNav()}
            {generatedNav && isMobileNavActive && renderMobileNav()}
        </React.Fragment>
    );
};

Navigation.propTypes = {
    activeNavItem: PropTypes.object,
    featuredShow: PropTypes.object,
    isMobileNavActive: PropTypes.bool.isRequired,
    mobileNavToggleHandler: PropTypes.func.isRequired,
    navItemClickHandler: PropTypes.func.isRequired
};

export default Navigation;
