import Base from './base';
import DateHelper from 'app/utilities/date-helper';

export default class Performance extends Base {
    constructor(data, showVenues) {
        super();

        this.props = data;
        this.decimalPoints = 2;
        this.showVenues = showVenues;
    }

    /**
     * Get Booking Link
     *
     * @return {string}
     */
    get bookingLink() {
        if (this.props.buy_now_url) {
            return this.props.buy_now_url;
        }

        /**
         * If the url below gets returned, it means we don't have an id, therefore can't mount the url
         * it's not ideal but it's the only way around for now
         */
        if (this.props.ticketmaster_url === '' || this.props.ticketmaster_url === undefined || this.props.ticketmaster_url === null) {
            return false;
        }

        return `${this.props.ticketmaster_url}?brand=aucklandlive&camefrom=CFC_NZ_TET`;
    }

    /**
     * Get text inside booking link button
     *
     * @return {string}
     */
    get bookingText() {
        return this.props.buy_now_text || 'Buy now';
    }

    /**
     * Get text inside booking link button
     *
     * @return {string}
     */
    get bookingDisabledText() {
        return this.props.buy_now_disabled_text || 'Sold out';
    }

    /**
     * Whether the performance is not available (e.g. sold out)
     */
    get disabled() {
        return this.props.disabled;
    }

    /**
     * Get an object of the various date formats
     *
     * @return {object}
     */
    get date() {
        const beginning = DateHelper.getDateWithNZTimezone(this.props.starts_at);

        return {
            day: beginning.format('DD'),
            dayMonth: beginning.format('D MMM'),
            full: beginning.format('YYYY-MM-DD HH:mm:ss'),
            month: beginning.format('MMM'),
            time: beginning.format('h:mma')
        };
    }

    /**
     * Get Accessibility Audio Described
     *
     * @return {int}
     */
    get accessibilityAudioDescribed() {
        return this.props.accessibility_audio_described;
    }

    /**
     * Get Accessibility Relaxed
     *
     * @return {int}
     */
    get accessibilityRelaxed() {
        return this.props.accessibility_relaxed;
    }

    /**
     * Get Accessibility Sign Language
     *
     * @return {int}
     */
    get accessibilitySignLanguage() {
        return this.props.accessibility_sign_language;
    }

    /**
     * Get ID
     *
     * @return {int}
     */
    get id() {
        return this.props.id;
    }

    /**
     * Get is the performace in the past
     *
     * @return {boolean}
     */
    get isPast() {
        const beginning = this.props.starts_at;
        const today = DateHelper.today;

        return today.isAfter(beginning, 'day');
    }

    /**
     * Get is the performace in the past months
     *
     * @return {boolean}
     */
    get isBeforeCurrentMonth() {
        const beginning = this.props.starts_at;
        const today = DateHelper.today;

        return today.isAfter(beginning, 'month');
    }

    /**
     * Check if performance is sold out
     *
     * @return {boolean}
     */
    get isSoldOut() {
        if (this.props.ticketmaster_availability === 'none') {
            return true;
        }

        return this.props.disabled;
    }

    /**
     * Get the index of the performance
     *
     * @return {number}
     */
    get order() {
        return this.props.order || 0;
    }

    /**
     * Check if performance is solling out
     *
     * @return {boolean}
     */
    get isSellingFast() {
        if (this.props.ticketmaster_availability === 'limited') {
            return true;
        }

        return false;
    }

    getVenueObject() {
        const venueCandidates = this.showVenues.filter((venue) => venue.id === this.props.venue_id.toString());
        if (venueCandidates.length) {
            return venueCandidates[0];
        }

        return null;
    }

    /**
     * Get Location in the JSON-LD Format
     *
     * @return {object}
     */
    get locationStructuredData() {
        const venueObject = this.getVenueObject();
        if (venueObject) {
            return {
                '@type': 'Place',
                name: this.venue,
                geo: venueObject.geoStructuredData,
                address: venueObject.addressStructuredData,
                sameAs: `https://www.aucklandlive.co.nz${venueObject.url}`
            };
        }

        return {
            '@type': 'Place',
            name: this.venue
        };
    }

    /**
     * Get Highest Price
     *
     * @return {string}
     */
    get priceHighest() {
        return `$${Number(this.props.price_highest).toFixed(this.decimalPoints)}`;
    }

    /**
     * Get Lowest Price
     *
     * @return {string}
     */
    get priceLowest() {
        return `$${Number(this.props.price_lowest).toFixed(this.decimalPoints)}`;
    }

    /**
     * Get the range of prices
     *
     * @return {string}
     */
    get priceRange() {
        // Separate field to indicate the price is to be confirmed
        if (this.props.price_tbc) {
            return this.props.price_tbc_text;
        }

        if (this.priceLowest === this.priceHighest) {
            if (this.priceHighest === '$0.00') {
                return 'Free';
            }

            return `${this.priceLowest}`;
        }

        return `${this.priceLowest} - ${this.priceHighest}`;
    }

    /**
     * Get Starts At Date/Time
     *
     * @return {string}
     */
    get startsAt() {
        return this.props.starts_at;
    }

    /**
     * Get Ends At Date/Time
     *
     * @return {string}
     */
    get endsAt() {
        return this.props.ends_at;
    }

    /**
     * Get name of venue
     *
     * @return {string}
     */
    get venue() {
        return this.props.venue_name || 'Unknown Venue';
    }

    /**
     * Get the ID of the venue
     *
     * @return {string}
     */
    get venueId() {
        return String(this.props.venue_id);
    }

    /**
     * Get transport blocks
     *
     * @return {string}
     */
    get transportBlocks() {
        return this.props.venue ? this.props.venue.attributes.transport_blocks : [];
    }

    /**
     * Get blocks
     *
     * @return {string}
     */
    get blocks() {
        return this.props.blocks || [];
    }

    /**
     * Get show name
     *
     * @return {string}
     */
    get showName() {
        return this.props.show ? this.props.show.attributes.name : '';
    }

    /**
     * Get seo description
     *
     * @return {string}
     */
    get seoDescription() {
        return this.props.show ? this.props.show.attributes.seo_description : '';
    }

    /**
     * Get seo keywords
     *
     * @return {string}
     */
    get seoKeywords() {
        return this.props.show ? this.props.show.attributes.seo_keywords : '';
    }
}

Object.defineProperties(Performance.prototype, {
    'accessibilityAudioDescribed': { enumerable: true },
    'accessibilityRelaxed':        { enumerable: true },
    'accessibilitySignLanguage':   { enumerable: true },
    'id':                          { enumerable: true },
    'date':                        { enumerable: true },
    'endsAt':                      { enumerable: true },
    'isBeforeCurrentMonth':        { enumerable: true },
    'isPast':                      { enumerable: true },
    'isSellingFast':               { enumerable: true },
    'isSoldOut':                   { enumerable: true },
    'order':                       { enumerable: true },
    'priceHighest':                { enumerable: true },
    'priceLowest':                 { enumerable: true },
    'priceRange':                  { enumerable: true },
    'startsAt':                    { enumerable: true },
    'bookingLink':                 { enumerable: true },
    'bookingText':                 { enumerable: true },
    'bookingDisabledText':         { enumerable: true },
    'disabled':                    { enumerable: true },
    'venue':                       { enumerable: true },
    'venueId':                     { enumerable: true },
    'blocks':                      { enumerable: true },
    'transportBlocks':             { enumerable: true },
    'showName':                    { enumerable: true },
    'seoDescription':              { enumerable: true },
    'seoKeywords':                 { enumerable: true },
    'locationStructuredData':      { enumerable: true }
});
