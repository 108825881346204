/* eslint-disable jsx-a11y/anchor-is-valid */
import { buildWeeks } from 'app/utilities/performances-calendar';
import classnames from 'classnames';
import Icon from 'app/components/partials/icon';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

class PerformancesCalendarMonth extends React.Component {
    render() {
        const performances = this.props.performanceGroup.performances;

        const today = moment();
        const month = moment(performances[0].date.full);
        const weeks = buildWeeks(performances);

        return (
            <div className="performances-calendar-month">
                <div className="performances-calendar-month-key">
                    <span className="performances-calendar-month-key-label">
                        Key
                    </span>
                    <div className="performances-calendar-month-key-faux-buttons">
                        <div className="performances-calendar-month-key-faux-button-wrapper">
                            <a className="button available || performances-calendar-month-performance-book-button is-available">
                                Available
                            </a>
                        </div>
                        <div className="performances-calendar-month-key-faux-button-wrapper">
                            <a className="button available inverse || performances-calendar-month-performance-book-button is-available">
                                <Icon name="selling-fast" />
                                Limited
                            </a>
                        </div>
                        <div className="performances-calendar-month-key-faux-button-wrapper">
                            <a className="button primary inverse || performances-calendar-month-performance-book-button is-available">
                                Sold out
                            </a>
                        </div>
                    </div>
                    <div className="performances-calendar-month-key-accessibility">
                        <div className="performances-calendar-month-key-accessibility-option">
                            <Icon className="icon larger" name="relaxed" />
                            <span className="performances-calendar-month-key-accessibility-option-label">
                                Relaxed
                            </span>
                        </div>
                        <div className="performances-calendar-month-key-accessibility-option">
                            <Icon name="sign-language" />
                            <span className="performances-calendar-month-key-accessibility-option-label">
                                Sign language
                            </span>
                        </div>
                        <div className="performances-calendar-month-key-accessibility-option">
                            <Icon className="icon larger" name="audio-described-alt" />
                            <span className="performances-calendar-month-key-accessibility-option-label">
                                Audio described
                            </span>
                        </div>
                    </div>
                </div>
                <div className="performances-calendar-month-head">
                    {weeks[0].days.map((day, dayIndex) => (
                        <div key={dayIndex} className="performances-calendar-month-head-day">
                            {day.date.format('dddd')}
                        </div>
                    ))}
                </div>
                <div className="performances-calendar-month-body">
                    {weeks.map((week, weekIndex) => {
                        return week.days.map((day, dayIndex) => (
                            <div
                                key={`${weekIndex}-${dayIndex}`}
                                className={classnames('performances-calendar-month-body-day', {
                                    'is-current-month': day.date.isSame(month, 'month'),
                                    'is-past': day.date.isBefore(today, 'day'),
                                    'has-performances': day.performances.length
                                })}
                            >
                                <div className="performances-calendar-month-body-day-date">
                                    {day.date.format('D')}
                                </div>

                                <div className="performances-calendar-month-body-day-accessibility">
                                    {day.performances.some((performance) => performance.accessibilityRelaxed) && (
                                        <Icon className="icon larger" name="relaxed" />
                                    )}
                                    {day.performances.some((performance) => performance.accessibilitySignLanguage) && (
                                        <Icon className="icon larger" name="sign-language" />
                                    )}
                                    {day.performances.some((performance) => performance.accessibilityAudioDescribed) && (
                                        <Icon className="icon larger" name="audio-described-alt" />
                                    )}
                                </div>

                                {day.performances.map((performance, performanceIndex) => {
                                    const isTodayOrInTheFuture = day.date.isSameOrAfter(today, 'day');

                                    return (
                                        <div key={performanceIndex} className="performances-calendar-month-performance">
                                            <a
                                                className={classnames('button', {
                                                    'primary inverse': isTodayOrInTheFuture && performance.isSoldOut,
                                                    'available': !isTodayOrInTheFuture || !performance.isSoldOut,
                                                    'inverse': isTodayOrInTheFuture && !performance.isSoldOut && performance.isSellingFast
                                                }, 'performances-calendar-month-performance-book-button', {
                                                    'is-available': isTodayOrInTheFuture
                                                })}
                                                href={isTodayOrInTheFuture && !performance.isSoldOut && performance.bookingLink || undefined}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {isTodayOrInTheFuture && !performance.isSoldOut && performance.isSellingFast && (
                                                    <Icon name="selling-fast" />
                                                )}
                                                {isTodayOrInTheFuture ? (performance.isSoldOut ? 'Sold out' : performance.date.time) : 'Unavailable'}
                                            </a>
                                            <div className="performances-calendar-month-performance-price">
                                                {performance.priceRange}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        ));
                    })}
                </div>
            </div>
        );
    }
}

PerformancesCalendarMonth.propTypes = {
    performanceGroup: PropTypes.shape({
        performances: PropTypes.array.isRequired
    })
};

export default PerformancesCalendarMonth;
