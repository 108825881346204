import PropTypes from 'prop-types';
import React from 'react';

const TileInner = ({ company, date, description, genre, isPast, location, name, price, subVenueNames, thumbnail, type, venue }) => {
    const renderPageThumbnail = () => {
        const thumbnailImage = thumbnail.landscape || thumbnail.square;

        return (
            <div className="tile-thumbnail__page">
                <img className="image" src={thumbnailImage} alt={name} />
            </div>
        );
    };

    const getLocation = () => {
        if (venue) {
            return (<p className="location">{venue}</p>);
        } else if (location) {
            return (<p className="location">{location}</p>);
        }
    };

    const renderThumbnail = () => {
        const desktopImage = thumbnail.landscape;
        const mobileImage = thumbnail.square || thumbnail.landscape;

        return (
            <div className="tile-thumbnail">
                <img className="image desktop" src={desktopImage} alt={name} />
                <img className="image mobile" src={mobileImage} alt={name} />
            </div>
        );
    };

    const renderDate = () => {
        if (isPast) {
            return <p className="date past">Past Event</p>;
        }

        if (date) {
            return <time className="date">{date}</time>;
        }
    };

    const renderPrice = () => {
        if (isPast) {
            return <p className="price">No longer available</p>;
        }

        return price && <p className="price">{price}</p>;
    };

    const renderVenueInformation = () => (
        <div className="information">
            <p className="type">Venue</p>
            {name && <h4 className="title">{name}</h4>}
            <div className="sub-information">
                {subVenueNames && <p className="sub-venues">{subVenueNames}</p>}
            </div>
            <div className="further">
                {getLocation()}
            </div>
        </div>
    );

    const renderEventInformation = () => (
        <div className="information">
            {company && <h5 className="presenter">{company}</h5>}
            {name && <h4 className="title">{name}</h4>}
            <div className="sub-information">
                {renderDate()}
            </div>
            <div className="further">
                {renderPrice()}
                {getLocation()}
            </div>
        </div>
    );

    const renderNewsInformation = () => (
        <div className="information information-news">
            <p className="type type-news">News</p>
            {name && <h4 className="title">{name}</h4>}
            {/* TODO: Implement date with publish time feature */}
            {/* <div className="sub-information">
                {renderDate()}
            </div> */}
            <div className="further">
                {description && <p className="description">{description}</p>}
            </div>
        </div>
    );

    const renderInformation = () => {
        if (type === 'venue') {
            return renderVenueInformation();
        }

        if (type === 'event') {
            return renderEventInformation();
        }

        if (type === 'news') {
            return renderNewsInformation();
        }

        // Show tile
        return (
            <div className="information">
                {company && <h5 className="presenter">{company}</h5>}
                {name && <h4 className="title">{name}</h4>}
                <div className="sub-information">
                    {renderDate()}
                    {getLocation()}
                </div>
                <div className="further">
                    {renderPrice()}
                    {genre && <p className="genre">{genre}</p>}
                </div>
            </div>
        );
    };

    if (type === 'page') {
        return (
            <React.Fragment>
                {renderPageThumbnail()}
                <div className="title-page">
                    <h3>{name}</h3>
                </div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {renderThumbnail()}
            {renderInformation()}
        </React.Fragment>
    );
};

TileInner.propTypes = {
    company:  PropTypes.string,
    date:  PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string
    ]),
    description: PropTypes.string,
    genre:  PropTypes.string,
    isPast: PropTypes.bool,
    location:  PropTypes.string,
    name:  PropTypes.string.isRequired,
    price:  PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
    ]),
    shortDescription: PropTypes.string,
    subVenueNames: PropTypes.string,
    thumbnail:  PropTypes.object.isRequired,
    type:  PropTypes.string.isRequired,
    venue:  PropTypes.string,
};

export default TileInner;
