import fetch from 'app/utilities/fetch';
import { formatSearchResults } from 'app/utilities/format-data';
import Grid from 'app/components/partials/grid';
import Helmet from 'react-helmet';
import Loader from 'app/components/partials/loader';
import NewsletterStrip from 'app/components/partials/newsletter-strip.container.js';
import Paginator from 'app/components/partials/paginator';
import { ENDPOINTS, RESPONSE } from 'config/api';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const ShowSearchResults = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [searchResponse, setSearchResponse] = useState({
        results: [],
        links: {},
        count: null
    });
    const [currentSearch, setCurrentSearch] = useState({
        genre: searchParams.get('genre') || '-',
        date: searchParams.get('date') || '-',
        price: searchParams.get('price') || '-'
    });
    const [currentPage, setCurrentPage] = useState(searchParams.get('page') || 1);

    const setResults = (count, links, results, page = 1) => {
        setSearchResponse({
            count,
            links,
            results
        });

        setCurrentPage(parseInt(page));
    };

    const paginatorClickHandler = async() => {
        const { date, genre, price } = currentSearch;
        const nextPage = parseInt(currentPage) + 1;

        const response = await fetch(`${ENDPOINTS.SHOW_SEARCH}?date=${date}&genre=${genre}&price=${price}&page=${nextPage}&is_published=true`);
        const { links, results } = formatSearchResults([response]);
        setResults(searchResponse.count, links, [...searchResponse.results, ...results], nextPage);
    };

    const getSearchValues = () => {
        const genre = searchParams.get('genre') || '-';
        const date = searchParams.get('date') || '-';
        const price = searchParams.get('price') || '-';
        const page = searchParams.get('page') || 1;

        return { genre, date, price, page };
    };

    const fetchSearchResults = async(genre, date, price, page) => {
        const fetchArray = [...Array(parseInt(page)).keys()];

        setIsLoading(true);
        try {
            const response = await Promise.all(fetchArray.map((item) => fetch(`${ENDPOINTS.SHOW_SEARCH}?date=${date}&genre=${genre}&price=${price}&page=${item + 1}&is_published=true`)));
            const { count, links, results } = formatSearchResults(response);
            setResults(count, links, results, page);
        } catch (err) {
            if (err.status === RESPONSE.NOT_FOUND) {
                setResults(0, null, []);
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const { genre: currentGenre, date: currentDate, price: currentPrice } = currentSearch;
        const { genre: nextGenre, date: nextDate, price: nextPrice } = getSearchValues();

        if (
            currentGenre !== nextGenre ||
            currentDate !== nextDate ||
            currentPrice !== nextPrice
        ) {
            setCurrentSearch({ genre: nextGenre, date: nextDate, price: nextPrice });
        }
    }, [searchParams]);

    useEffect(() => {
        const { genre, date, price, page } = getSearchValues();

        fetchSearchResults(genre, date, price, page);

        window.scrollTo(0, 0);
    }, [currentSearch]);

    useEffect(() => {
        if (currentPage) {
            searchParams.set('page', currentPage);

            // Set page query without creating new history record
            navigate({ search: searchParams.toString() }, { replace: true });
        }
    }, [currentPage]);


    const renderMessage = () => {
        if (searchResponse.count === 0) {
            return <p className="message">Your search has no results – but we still have lots to show you! Try removing one of the filters or adjusting the date range.</p>;
        }

        return <p className="message">We found {searchResponse.count} results</p>;
    };

    return (
        <main className="page" role="main">
            <Helmet>
                <title>Event Results | Auckland Live</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <div className="content-page || show-search-results-page">
                <header className="page-header || constrain-width">
                    <h1 className="bordered">Event Results</h1>
                </header>
                {isLoading && <Loader />}
                {!isLoading && (
                    <React.Fragment>
                        {renderMessage()}
                        <Paginator currentItemsLength={searchResponse.results.length} total={searchResponse.count} btnClickHandler={paginatorClickHandler}>
                            <Grid data={searchResponse.results} isAutoScroll={true}/>
                        </Paginator>
                    </React.Fragment>
                )}
            </div>
            <NewsletterStrip pageTitle="Search results" />
        </main>
    );
};

export default ShowSearchResults;
