// Data formatting
export const TIMESTAMP = 'YYYYMMDDHHMMSS';
export const DATE_FORMAT_MIN = 'D MMM';
export const DATE_FORMAT_MIN_WITH_YEAR = 'D MMM YYYY';
export const DATE_FORMAT_FULL = 'YYYY-MM-DD';
export const DATE_FORMAT_DATE_PICKER = 'DD/MM/YYYY';
export const PRICE_FORMAT = '$0,0.00';
export const NZ_UTC_OFFSET = 12;
export const NZ_TIMEZONE = 'Pacific/Auckland';

// Meta
export const APP_NAME = 'Auckland Live';
export const APP_NAME_IOS = 'Auckland Live';
export const APP_AUTHOR = 'Auckland Live';
export const APP_HOSTNAME = 'https://www.aucklandlive.co.nz';
export const APP_DOMAIN_NAME = 'aucklandlive.co.nz';
export const APP_UAT_DOMAIN_NAME = 'aucklandlive.uat.tauweb.co.nz';

// Social
export const INSTAGRAM_USER = 'aucklandlive';
export const TUMBLR_USER = 'aucklandlive';
export const TWITTER_USER = '@AucklandLive';
export const YOUTUBE_USER = 'aucklandlive';
export const FACEBOOK_APP_ID = '472831239508227';

export const FACEBOOK_URL = 'http://facebook.com/AklLive';
export const INSTAGRAM_URL = 'http://instagram.com/aucklandlive';
export const TWITTER_URL = 'http://twitter.com/aucklandlive';
export const YOUTUBE_URL = 'http://youtube.com/user/aucklandlive';


