import { clientEnv } from 'config/env';
import Page from './page';
import Show from './show';

export default class Venue extends Page {
    constructor(data, included) {
        super(data, included);
    }

    /**
     * Get Mailing Address in the JSON-LD Format
     *
     * @return {object}
     */
    get addressStructuredData() {
        return {
            '@type': 'PostalAddress',
            streetAddress: this.location.raw || '',
            addressLocality: 'Auckland',
            addressRegion: 'Auckland',
            addressCountry: 'New Zealand'
        };
    }

    /**
     * Get 'About the Venue' Content Blocks
     *
     * @return {array}
     */
    get blocksAbout() {
        return this.props.blocks || [];
    }

    /**
     * Get 'Hire Venue' Content Blocks
     *
     * @return {array}
     */
    get blocksHire() {
        return this.props.hire_venue_blocks || [];
    }

    /**
     * Get 'Venue Information' Content Blocks
     *
     * @return {array}
     */
    get blocksInfo() {
        return this.props.visitor_info_blocks || [];
    }

    /**
     * Get Directions URL
     *
     * @return {string}
     */
    get directionsUrl() {
        return this.props.directions_link;
    }

    /**
     * Get Geo Coordinates in the JSON-LD Format
     *
     * @return {object}
     */
    get geoStructuredData() {
        return {
            '@type': 'GeoCoordinates',
            longitude: Number.parseFloat(this.props.geo_long),
            latitude: Number.parseFloat(this.props.geo_lat)
        };
    }

    /**
     * Get Hero Images
     *
     * @return {object}
     */
    get heroImage() {
        let desktop = '',
            mobile = '';

        if (this.props.hero_image) {
            if (this.props.hero_image.images.desktop) {
                desktop = this.props.hero_image.images.desktop[0];
            }

            if (this.props.hero_image.images.mobile) {
                mobile = this.props.hero_image.images.mobile[0];
            }
        }

        return {
            desktop: desktop,
            mobile: mobile
        };
    }

    /**
     * Get Hero Video
     *
     * @return {string}
     */
    get heroVideo() {
        if (this.props.hero_image && this.props.hero_image.video) {
            return this.props.hero_image.video;
        }

        return '';
    }

    /**
     * Get Location
     *
     * @return {object}
     */
    get location() {
        return {
            formatted: this.props.address || '',
            geo: {
                lat: Number.parseFloat(this.props.geo_lat),
                lng: Number.parseFloat(this.props.geo_long)
            },
            raw: (this.props.address) ? this.props.address.replace(/<[^>]+>/gi, '') : ''
        };
    }

    /**
     * Get Google Map URL
     *
     * @return {string}
     */
    get map() {
        let location = this.location.formatted.replace(/<[^>]+>/gi, '').replace(/[^\d\w,]/gi, '+'),
            size = 400;

        return `https://maps.googleapis.com/maps/api/staticmap?key=${clientEnv.GOOGLE_API_KEY}&center=${location}&zoom=15&scale=2&maptype=roadmap&size=${size}x${size}`;
    }

    /**
     * Get Opening Hours
     *
     * @return {string}
     */
    get openHours() {
        return this.props.open_hours;
    }

    /**
     * Get Phone Number
     *
     * @return {string}
     */
    get phone() {
        return this.props.phone_number;
    }

    /**
     * Get Shows playing at this Venue
     *
     * @return {array}
     */
    get shows() {
        return this.getRelationship('shows', Show); // TODO - does the result of this call need to be cached per model instance?
    }

    /**
     * Get Sub Venue names
     *
     * @return {string}
     */
    get subVenueNames() {
        if (this.props.subvenues_names) {
            return this.props.subvenues_names;
        }

        return '';
    }

    /**
     * Get Theme (color)
     *
     * @return {string}
     */
    get theme() {
        if (this.props.hero_image) {
            return this.props.hero_image.theme;
        }

        return '';
    }

    /**
     * Get Thumbnail
     *
     * @return {object}
     */
    get thumbnail() {
        return {
            landscape: this.props.landscape_thumbnail || '',
            square: this.props.square_thumbnail || ''
        };
    }

    /**
     * Get the virtual tour link for this Venue. If not set, return the venue URL
     *
     * @return {string}
     */
    get tourLink() {
        return this.props.virtual_tour_link;
    }

    /**
     * Get Hero data object
     *
     * @return {object}
     */
    get hero() {
        return {
            directionsUrl: this.directionsUrl,
            image: this.heroImage,
            location: this.location.raw,
            name: this.name,
            phone: this.phone,
            theme: this.theme,
            video: this.heroVideo
        };
    }

    /**
     * Get Tile data object
     *
     * @return {object}
     */
    get tile() {
        return {
            location: this.location.raw,
            name: this.name,
            phone: this.phone,
            subVenueNames: this.subVenueNames,
            thumbnail: this.thumbnail,
            type: 'venue',
            url: `/venue/${this.slug}`
        };
    }

    /**
     * Get Performances in the JSON-LD Format
     *
     * @return {array}
     */
    get performancesStructuredData() {
        const structuredData = [];
        this.shows.forEach((show) => {
            show.performances
                .filter((performance) => performance.venueId === this.id) // Only include the performances showing at the venue.
                .forEach((performance) => {
                    structuredData.push({
                        '@context': 'http://schema.org',
                        '@type': 'Event',
                        name: show.name,
                        image: show.heroImage.desktop,
                        startDate: performance.startsAt,
                        url: `https://www.aucklandlive.co.nz${show.url}`,
                        description: show.seo.description,
                        location: {
                            '@type': 'Place',
                            name: this.name,
                            geo: this.getGeoStructuredData(),
                            address: this.getAddressStructuredData(),
                            sameAs: `https://www.aucklandlive.co.nz${this.url}`
                        }
                    });
                });
        });

        return structuredData;
    }
}

Object.defineProperties(Venue.prototype, {
    'blocksAbout':                 { enumerable: true },
    'blocksHire':                  { enumerable: true },
    'blocksInfo':                  { enumerable: true },
    'directionsUrl':               { enumerable: true },
    'hero':                        { enumerable: true },
    'heroImage':                   { enumerable: true },
    'heroVideo':                   { enumerable: true },
    'location':                    { enumerable: true },
    'map':                         { enumerable: true },
    'openHours':                   { enumerable: true },
    'performancesStructuredData':  { enumerable: true },
    'phone':                       { enumerable: true },
    'shows':                       { enumerable: true },
    'subVenueNames':               { enumerable: true },
    'theme':                       { enumerable: true },
    'thumbnail':                   { enumerable: true },
    'tile':                        { enumerable: true },
    'tourLink':                    { enumerable: true },
    'geoStructuredData':           { enumerable: true },
    'addressStructuredData':       { enumerable: true }
});
