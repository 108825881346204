/* eslint-disable camelcase */
import classNames from 'classnames';
import { clearWindowsScrollPosition } from '../../utilities/scrolling-helper';
import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import Icon from './icon';
import Logo from './logo';
import Navigation from './navigation';
import NotificationBanner from './notification-banner.container';
import PropTypes from 'prop-types';
import SearchDialog from './search-dialog';
import ShowFinder from './show-finder';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import React, { useCallback, useEffect, useRef, useState } from 'react';

const Header = ({ fetchVenues, notificationBannerIsActive, notifications, toggleNotifications, status }) => {
    const [isMobileNavActive, setIsMobileNavActive] = useState(false);
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [isShowFinderDialogActive, setIsShowFinderDialogActive] = useState(false);
    const [isScrolling, setIsScrolling] = useState(false);
    const [featuredShow, setFeaturedShow] = useState(null);
    const [activeNavItem, setActiveNavItem] = useState(null);

    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    const headerRef = useRef(null);

    const mobileNavToggleHandler = useCallback(() => {
        setIsMobileNavActive(!isMobileNavActive);
    }, [isMobileNavActive]);

    const navItemClickHandler = (navItem) => {
        if (!navItem || activeNavItem && activeNavItem.name === navItem.name) {
            return setActiveNavItem(null);
        }

        clearWindowsScrollPosition();
        setActiveNavItem(navItem);
    };


    const toggleSearchDialog = useCallback(() => {
        if (isSearchActive) {
            searchParams.delete('search');
            searchParams.delete('keyword');
            searchParams.delete('modalPage');
        } else {
            searchParams.set('search', true);
        }
        setSearchParams(searchParams);
        setIsSearchActive(!isSearchActive);
    }, [location]);

    const toggleShowFinderDialog = () => {
        setIsShowFinderDialogActive(!isShowFinderDialogActive);
    };

    const onScrollHandler = () => {
        const headerHeight = headerRef.current.clientHeight;

        const scrollTop = window.pageYOffset === undefined
            ? (document.documentElement ||
                    document.body.parentNode ||
                    document.body).scrollTop
            : window.pageYOffset;

        if (scrollTop > headerHeight) {
            setIsScrolling(true);
        } else {
            setIsScrolling(false);
        }
    };

    const fetchFeaturedShow = async() => {
        try {
            const { data } = await fetch(ENDPOINTS.FEATURED_SHOW_ON_NAVIGATION);

            setFeaturedShow(data[0] || null);
        } catch (err) {
            setFeaturedShow(null);
        }
    };
    // Fix scroll bar when modal is open
    useEffect(() => {
        if (isSearchActive) {
            document.body.classList.add('dialog-open');
        } else {
            document.body.classList.remove('dialog-open');
        }

        return () => {
            document.body.classList.remove('dialog-open');
        };
    }, [isSearchActive]);

    useEffect(() => {
        fetchFeaturedShow();
        fetchVenues();
        onScrollHandler();
        window.addEventListener('scroll', onScrollHandler);

        return () => {
            window.removeEventListener('scroll', onScrollHandler);
        };
    }, []);

    useEffect(() => {
        setIsSearchActive(searchParams.get('search') === 'true');
    }, [location]);

    useEffect(() => {
        if (!status.isRouting) {
            // Reset Nav State
            setActiveNavItem(null);
            setIsMobileNavActive(false);
        }
    }, [status, location]);

    let notificationToggleButtonIsActive = false;

    if (notificationBannerIsActive) {
        notificationToggleButtonIsActive = notifications.some((item) => item.isVisible === false && item.pagesVisibleOn.length === 0);
    } else {
        notificationToggleButtonIsActive = notifications.some((item) => item.pagesVisibleOn.length === 0);
    }

    const notificationToggleButtonIsHidden = !notifications || !notifications.some((item) => item.pagesVisibleOn.length === 0);

    const isThemeDark = location.pathname === '/' && !isScrolling;

    const notificationToggleButtonClass = classNames('button secondary small || notification-button', {
        'is-hidden': notificationToggleButtonIsHidden,
        'is-active': notificationToggleButtonIsActive,
        'theme-dark': isThemeDark
    });

    const headerWrapperClass = classNames('header-wrapper', {
        'theme-dark': isThemeDark,
        'is-scrolling': isScrolling
    });

    const buttonClass = classNames('button', {
        'theme-dark': isThemeDark
    });

    return (
        <header className={headerWrapperClass} ref={headerRef}>
            <NotificationBanner />
            <div className="header-inner || constrain-width large">
                <div className="actions || hide-on-desktop">
                    <button className={`${buttonClass} secondary || mobile-menu`} type="button" onClick={mobileNavToggleHandler}>
                        <Icon className="icon large" name="menu" />
                    </button>
                    <button className={`${notificationToggleButtonClass} || hide-on-mobile`} type="button" onClick={toggleNotifications}>
                        <Icon className="icon large" name="bell" />
                    </button>
                </div>
                <Link className="logo" to="/">
                    <Logo />
                </Link>
                <Navigation
                    mobileNavToggleHandler={mobileNavToggleHandler}
                    featuredShow={featuredShow}
                    isMobileNavActive={isMobileNavActive}
                    activeNavItem={activeNavItem}
                    navItemClickHandler={navItemClickHandler}
                />
                <div className="actions">
                    <button className={`${buttonClass} secondary || find-event`} type="button" onClick={toggleShowFinderDialog}>
                        Find an event
                    </button>
                    <button className={`${notificationToggleButtonClass} || hide-on-tablet`} type="button" onClick={toggleNotifications}>
                        <Icon className="icon large" name="bell" />
                    </button>
                    <button className={`${buttonClass} secondary || search`} type="button" onClick={toggleSearchDialog}>
                        <Icon name="search" /><span className="text">Search</span>
                    </button>
                </div>
            </div>
            <hr />
            <ShowFinder isShowFinderDialogActive={isShowFinderDialogActive} toggleShowFinderDialog={toggleShowFinderDialog} />
            {isSearchActive && <SearchDialog toggleSearchDialog={toggleSearchDialog} />}
        </header>
    );
};


Header.propTypes = {
    fetchVenues: PropTypes.func.isRequired,
    notifications: PropTypes.array.isRequired,
    notificationBannerIsActive: PropTypes.bool.isRequired,
    status: PropTypes.object.isRequired,
    toggleNotifications: PropTypes.func.isRequired,
    updateNotification: PropTypes.func.isRequired
};

export default Header;
