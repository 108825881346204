import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import React, { useRef, useState } from 'react';

const ArrowButton = ({ className, onClick }) => {
    const arrowClickHandler = (event) => {
        event.stopPropagation();
        onClick();
    };

    return (
        <button className={className} onClick={arrowClickHandler}>
            <Icon name="chevron" />
        </button>
    );
};

ArrowButton.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func
};

const ImageGallery = ({ data, pageTitle }) => {
    const galleryRef = useRef();
    const [isLightboxActive, setIsLightboxActive] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [isDragging, setIsDragging] = useState(false);

    const toggleLightbox = () => {
        if (isDragging) {
            return;
        }
        setIsLightboxActive(!isLightboxActive);
    };

    const beforeSlideChangeHandler = (current, nextIndex) => {
        setActiveIndex(nextIndex);
        setIsDragging(true);
    };

    const renderImageWrapper = (item, index) => (
        <div className="image-wrapper" key={index} onClick={toggleLightbox} aria-hidden="true">
            <img src={item.file} alt={item.file_alt || pageTitle} />
            {isLightboxActive && <figcaption className="creditline || rich-editor-content" dangerouslySetInnerHTML={{ __html: item.creditline }}></figcaption>}
        </div>
    );

    const renderLightbox = () => {
        const lightboxSetting = {
            ...settings,
            variableWidth: false,
            initialSlide: activeIndex,
            adaptiveHeight: true,
            draggable: false,
            beforeChange: (current, next) => galleryRef.current.slickGoTo(next)
        };

        return (
            <div className="lightbox" onClick={toggleLightbox} aria-hidden="true">
                <Slider {...lightboxSetting}>
                    {data.map((item, index) => renderImageWrapper(item, index))}
                </Slider>
            </div>
        );
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        adaptiveHeight: true,
        nextArrow: <ArrowButton type="next" />,
        prevArrow: <ArrowButton type="prev" />,
        beforeChange: beforeSlideChangeHandler,
        afterChange: () => setIsDragging(false),
    };

    return (
        <section className="content-block image-gallery-block || constrain-width large">
            <Slider {...settings} ref={galleryRef}>
                {data.map((item, index) => renderImageWrapper(item, index))}
            </Slider>
            {isLightboxActive && renderLightbox()}
        </section>
    );
};

ImageGallery.propTypes = {
    data: PropTypes.array.isRequired,
    pageTitle: PropTypes.string.isRequired
};

export default ImageGallery;
